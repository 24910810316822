import * as React from "react";
import {useEffect} from "react";
import {Button} from '@material-ui/core';
import {PagesEnums} from '../../utils/CustomEnums';
import {NavLink} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import 'react-phone-input-2/lib/style.css'
import MenuTile from '../../templates/MenuTile';
import FetchService from './FetchServiceMessages';
import {createNotificationForResponse, errorNotification} from '../../utils/Notifications';
import logLevel from '../../utils/LoggerHelper';
import {getIdWithoutPrefix} from '../../services/UserDataService';
import Desktop from "./Desktop";
import Mobile from "./Mobile";
import './style.css';


export default function Messages() {
    const {t} = useTranslation();
    const [messagesData, setMessagesData] = React.useState([]);
    const [mobile, setMobile] = React.useState(false);
    const [messagesCount, setMessagesCount] = React.useState(0);

    useEffect(() => {
        setMobile(mobileView);
    }, [])

    const mql = window.matchMedia('(max-width: 1100px)');
    let mobileView = mql.matches;

    mql.addEventListener('change', (e) => {
        const mobileView = e.matches;
        if (mobileView) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    });

    let setMessageAsRead = (id) => {
        let tmpMsgData = messagesData
        let tmpMsgCount = messagesCount
        const foundIndex = tmpMsgData.findIndex(obj => obj.id === id);
        if (tmpMsgData[foundIndex]){
            tmpMsgData[foundIndex].isRead = true;
            fetchSetRead(id)
            setMessagesData(tmpMsgData);
            setMessagesCount(tmpMsgCount - 1);
        }
    }

    let setMessageAsUnread = (id) => {
        let tmpMsgData = messagesData
        let tmpMsgCount = messagesCount
        const foundIndex = tmpMsgData.findIndex(obj => obj.id === id);
        if (tmpMsgData[foundIndex]){
            tmpMsgData[foundIndex].isRead = false;
            fetchSetUnread(id)
            setMessagesData(tmpMsgData);
            setMessagesCount(tmpMsgCount + 1);
        }
    }

    const messageDateFormatter = (date) => {

        return new Intl.DateTimeFormat("pl-PL", {
            weekday: 'long',
            year: "numeric",
            month: "long",
            day: "2-digit",
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false
        }).format(new Date(date));

    }

    async function fetchMessagesData() {
        return await FetchService.getMessages(getIdWithoutPrefix())
    }

    async function fetchSetUnread(id) {
        return await FetchService.setUnread(id)
    }

    async function fetchSetRead(id) {
        return await FetchService.setRead(id)
    }

    const parseText = (text) => {
        const urlPattern = /(http[s]?:\/\/[^\s]+)/g;
        const segments = text.split(/(\n)/); // Split by new lines and keep them as separate segments

        return segments.map((segment, index) => {
            if (segment === '\n') {
                // Retain new lines as <br /> tags
                return <br key={index}/>;
            }

            return segment.split(/(\s+)/).map((word, idx) => {
                if (urlPattern.test(word)) {
                    // Wrap URLs in <a> tags
                    return (
                        <a
                            key={`${index}-${idx}`}
                            href={word}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {word}
                        </a>
                    );
                }
                // Preserve other words and spaces
                return word;
            });
        });
    };
    
    useEffect(() => {
        fetchMessagesData().then(response => {
            if (response && response.zawartosc) {
                let messages = [];
                response.zawartosc.forEach(element => {
                    element.sendDate = messageDateFormatter(element.sendDate);
                    messages.push(element);
                })

                messages.sort((a, b) => new Date(a.sendDate) - new Date(b.sendDate));

                messages.reverse()

                messages.forEach(element => element.description = parseText(element.description))

                setMessagesData(messages)
                setMessagesCount(messages.filter(obj => !obj.isRead).length);
            } else {
                createNotificationForResponse(response);
            }
        }).catch(error => {
            logLevel.debug("Błąd podczas odczytu komunikatów, Error " + error);
            errorNotification('default.errorOnGet', ' ');
        })
    }, [])


    return (
        <div className="messages-content-wrapper">
            <Grid sx={{padding: '2.5vh'}} container spacing={2}>
                <Grid sx={{padding: '2.5vh'}} item xs={12} lg={2} className="menuTitle">
                    <MenuTile title={t('main.announcementsTile')} messagesCount={messagesCount} locked={false}/>
                </Grid>
                {mobile ? <Mobile setMessageAsRead={setMessageAsRead} setMessageAsUnread={setMessageAsUnread}
                                  messagesData={messagesData}/> :
                    <Desktop setMessageAsRead={setMessageAsRead} setMessageAsUnread={setMessageAsUnread}
                             messagesData={messagesData}/>}
            </Grid>
            <Grid container className="button-wrapper" justifyContent="flex-end">
                <Button
                    component={NavLink}
                    to={PagesEnums.MAIN.link}
                    variant='contained'>
                    {t('backButton')}
                </Button>
            </Grid>
        </div>
    );
}