import {useStyles} from "../../css/Styles";
import {useTranslation} from "react-i18next";
import React from "react";
import {PersonalDataAdressesAdministrativeTypesEnums, PersonalDataAdressesEnums} from "../../utils/CustomEnums";
import {Grid, Typography} from "@material-ui/core";
import CustomSelectField from "../../templates/CustomSelectField";
import CustomRadioGroup from "../../templates/CustomRadioGroup";
import {CustomGridField} from "./CustomGridField";
import PhoneInput from "react-phone-input-2";
import CustomCheckboxField from "../../templates/CustomCheckboxField";

export function AdresComponent({
                                   value,
                                   disabledState = true,
                                   changeHandler,
                                   telefonHandler,
                                   adresKorespondencyjnyHandler,
                                   czyAdresKorespondencyjny,
                                   isMobile,
                                   type,
                                   wojewodztwaDict,
                                   krajeDict,
                                   miastaDict,
                                   formErrors,
                                   krajDlaKtoregoWyswietlamyWojewodztwo,
                                   selectOptionSufix
                               }) {
    const classes = useStyles();
    const {t} = useTranslation();
    const [wojewodztwoKey] = React.useState(8)
    const [miejscowoscKey] = React.useState(9)
    const [krajKey] = React.useState(10)

    const typyAdministracyjne = [{
        label: 'personal.miasto',
        value: PersonalDataAdressesAdministrativeTypesEnums.MIASTO
    },
        {label: 'personal.wies', value: PersonalDataAdressesAdministrativeTypesEnums.WIES}]


    return (
        <Grid container direction={isMobile ? 'column' : 'row'} style={{width: '100%'}} justifyContent="flex-start">
            {type === PersonalDataAdressesEnums.STALY ?
                <Grid container direction={isMobile ? 'column' : 'row'} style={{width: '100%'}}
                      justifyContent="flex-start">
                    <Grid style={{width: '100%', textAlign: 'left', marginBottom: 25, paddingLeft: '1.5vw'}}>
                        <Typography fontWeight='bold'
                                    variant='h2'>{value.typ === 'staly' ? t('personal.adresStaly') : t('personal.adresKorespondencyjny')}</Typography>
                    </Grid>
                    <Grid style={{width: isMobile ? '100%' : '35%'}}>
                        <CustomSelectField name={`${value.typ}-kraj`} id={`${value.typ}-kraj`}
                                           title={t('personal.kraj')} options={krajeDict} value={value.kraj}
                                           key={-krajKey} editState={disabledState} changeHandler={changeHandler}
                                           required={true} selectOptionSufix={selectOptionSufix} errors={formErrors}/>
                        {(value && value.kraj && value.kraj.id === krajDlaKtoregoWyswietlamyWojewodztwo) ?
                            <CustomSelectField name={`${value.typ}-wojewodztwo`} id={`${value.typ}-wojewodztwo`}
                                               title={t('personal.wojewodztwo')} options={wojewodztwaDict}
                                               value={value.wojewodztwo} key={-wojewodztwoKey}
                                               editState={disabledState} changeHandler={changeHandler}
                                               required={true} selectOptionSufix={selectOptionSufix}
                                               errors={formErrors}/> :
                            null
                        }
                        <CustomRadioGroup name={`${value.typ}-typAdministracyjny`}
                                          title={t('personal.typAdministracyjny')}
                                          value={(value.typAdministracyjny ? (value.typAdministracyjny == 'wieś' ? 'wies' : value.typAdministracyjny.toLowerCase()) : null)}
                                          radioData={typyAdministracyjne} disabledState={disabledState}
                                          changeHandler={changeHandler} errors={formErrors}/>
                        <CustomGridField name={`${value.typ}-email`} title={t('personal.email')}
                                         label={t('personal.email')} value={value.email} disabledState={true}
                                         changeHandler={changeHandler} required={true} errors={formErrors}/>
                        <Grid container direction='row' className={classes.daneOsoboweGridField}>
                            <PhoneInput containerStyle={{width: '100%'}} inputStyle={{width: '100%'}}
                                        name={`${value.typ}-telefon`} id={`${value.typ}-telefon`}
                                        countryCodeEditable={false} disabled={disabledState}
                                        data-testid={'phoneInput'}
                                        className={(formErrors && formErrors.find(errors => errors.id === `${value.typ}-telefon`)) ? classes.phoneInputFieldError : classes.phoneInputField}
                                        country={'pl'} value={value.telefon} onChange={telefonHandler}/>
                            <Typography variant='h5'>{t('personal.phoneRequired')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid style={{width: isMobile ? '100%' : '65%'}}>
                        <Grid style={{width: isMobile ? '100%' : '50%'}}>
                            <CustomSelectField name={`${value.typ}-miejscowosc`} id={`${value.typ}-miejscowosc`}
                                               title={t('personal.miejscowosc')} options={miastaDict}
                                               value={value.miejscowosc} key={-miejscowoscKey}
                                               editState={disabledState} changeHandler={changeHandler}
                                               required={true} selectOptionSufix={selectOptionSufix} freeSolo={true}
                                               errors={formErrors}/>
                            <CustomGridField name={`${value.typ}-kodPocztowy`} title={t('personal.kodPocztowy')}
                                             label={t('personal.kodPocztowy')} value={value.kodPocztowy}
                                             disabledState={disabledState} changeHandler={changeHandler} required={true}
                                             errors={formErrors}/>
                            <CustomGridField name={`${value.typ}-ulica`} title={t('personal.ulica')}
                                             label={t('personal.ulica')} value={value.ulica}
                                             disabledState={disabledState} changeHandler={changeHandler} required={true}
                                             capital={true} errors={formErrors}/>
                            <CustomGridField name={`${value.typ}-numerDomu`} title={t('personal.numerDomu')}
                                             label={t('personal.numerDomu')} value={value.numerDomu}
                                             disabledState={disabledState} changeHandler={changeHandler} required={true}
                                             errors={formErrors}/>
                            <CustomGridField name={`${value.typ}-numerMieszkania`} title={t('personal.numerMieszkania')}
                                             label={t('personal.numerMieszkania')} value={value.numerMieszkania}
                                             disabledState={disabledState} changeHandler={changeHandler}
                                             errors={formErrors}
                                             required={false}/>
                            {(value.typ === PersonalDataAdressesEnums.STALY) ?
                                <CustomCheckboxField name={`${value.typ}-czyAdresKorespondencyjny`}
                                                     title={t('personal.czyAdresKorespondencyjny')}
                                                     label={t('personal.czyAdresKorespondencyjny')}
                                                     value={czyAdresKorespondencyjny} disabledState={disabledState}
                                                     changeHandler={adresKorespondencyjnyHandler}
                                                     className={classes.daneOsoboweGridField}/> :
                                null
                            }
                        </Grid>
                    </Grid>
                </Grid>
                :
                <Grid container direction={isMobile ? 'column' : 'row'} style={{width: '100%'}}
                      justifyContent="flex-start">
                    <Grid style={{width: '100%', textAlign: 'left', marginBottom: 25, paddingLeft: '1.5vw'}}>
                        <Typography fontWeight='bold'
                                    variant='h2'>{value.typ === 'staly' ? t('personal.adresStaly') : t('personal.adresKorespondencyjny')}</Typography>
                    </Grid>
                    <Grid style={{width: isMobile ? '100%' : '35%'}}>
                        <CustomSelectField name={`${value.typ}-kraj`} id={`${value.typ}-kraj`}
                                           title={t('personal.kraj')} options={krajeDict} value={value.kraj}
                                           key={-krajKey} editState={disabledState} changeHandler={changeHandler}
                                           required={true} selectOptionSufix={selectOptionSufix} errors={formErrors}/>
                        {(value && value.kraj && value.kraj.id === krajDlaKtoregoWyswietlamyWojewodztwo) ?
                            <CustomSelectField name={`${value.typ}-wojewodztwo`} id={`${value.typ}-wojewodztwo`}
                                               title={t('personal.wojewodztwo')} options={wojewodztwaDict}
                                               value={value.wojewodztwo} key={-wojewodztwoKey} editState={disabledState}
                                               editState={disabledState} changeHandler={changeHandler}
                                               required={true} selectOptionSufix={selectOptionSufix}
                                               errors={formErrors}/> :
                            null
                        }
                    </Grid>
                    <Grid style={{width: isMobile ? '100%' : '65%'}}>
                        <Grid style={{width: isMobile ? '100%' : '50%'}}>
                            <CustomSelectField name={`${value.typ}-miejscowosc`} id={`${value.typ}-miejscowosc`}
                                               title={t('personal.miejscowosc')} options={miastaDict}
                                               value={value.miejscowosc} key={-miejscowoscKey}
                                               editState={disabledState} changeHandler={changeHandler}
                                               required={true} selectOptionSufix={selectOptionSufix} freeSolo={true}
                                               errors={formErrors}/>
                            <CustomGridField name={`${value.typ}-kodPocztowy`} title={t('personal.kodPocztowy')}
                                             label={t('personal.kodPocztowy')} value={value.kodPocztowy}
                                             disabledState={disabledState} changeHandler={changeHandler} required={true}
                                             errors={formErrors}/>
                            <CustomGridField name={`${value.typ}-ulica`} title={t('personal.ulica')}
                                             label={t('personal.ulica')} value={value.ulica}
                                             disabledState={disabledState} changeHandler={changeHandler} required={true}
                                             capital={true} errors={formErrors}/>
                            <CustomGridField name={`${value.typ}-numerDomu`} title={t('personal.numerDomu')}
                                             label={t('personal.numerDomu')} value={value.numerDomu}
                                             disabledState={disabledState} changeHandler={changeHandler} required={true}
                                             errors={formErrors}/>
                            <CustomGridField name={`${value.typ}-numerMieszkania`} title={t('personal.numerMieszkania')}
                                             label={t('personal.numerMieszkania')} value={value.numerMieszkania}
                                             disabledState={disabledState} changeHandler={changeHandler}
                                             required={false} dataType={'number'}/>
                            {(value.typ === PersonalDataAdressesEnums.STALY) ?
                                <CustomCheckboxField name={`${value.typ}-czyAdresKorespondencyjny`}
                                                     title={t('personal.czyAdresKorespondencyjny')}
                                                     label={t('personal.czyAdresKorespondencyjny')}
                                                     value={czyAdresKorespondencyjny} disabledState={disabledState}
                                                     changeHandler={adresKorespondencyjnyHandler}
                                                     className={classes.daneOsoboweGridField}/> :
                                null
                            }
                        </Grid>
                    </Grid>
                </Grid>
            }

        </Grid>
    )
}