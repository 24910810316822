import React, {useContext, useEffect, useRef, useState} from 'react'
import {
    Box,
    Button,
    Grid,
    IconButton,
    TextField,
    ThemeProvider,
    Tooltip,
    Typography,
    useMediaQuery
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import 'react-phone-input-2/lib/style.css'
import MenuTile from '../../templates/MenuTile';
import {useStyles, useTheme} from '../../css/Styles';
import InputMask from 'react-input-mask';
import CustomSelectField from '../../templates/CustomSelectField';
import CustomDatePicker from '../../templates/CustomDatePicker';
import CustomRadioGroup from '../../templates/CustomRadioGroup';
import CustomToggleButtonTrueFalseField from '../../templates/CustomToggleButtonTrueFalseField';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import {getCredentials} from '../../services/UserDataService';
import Slide from '@mui/material/Slide';

import i18next from 'i18next';
import {DictionaryTypeEnums} from '../../utils/CustomEnums';
import {getDictionaries, modifyAccessStorage, stringToBoolean} from '../../utils/AppUtils';
import CustomCheckboxField from '../../templates/CustomCheckboxField';
import FetchService from '../../services/FetchService';
import DialogComponent from '../../templates/DialogComponent';
import {warningNotification} from '../../utils/Notifications';
import FileUploadComponent from '../../templates/FileUploadComponent';
import {ACTIONS} from '../../utils/Consts';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import BackAndSaveButtons from '../../templates/BackAndSaveButtons';
import Popup, {popupReducer} from '../popup/Popup';
import {DatesContext} from '../GlobalDatesContext';
import {saveFilesToDatabase} from "../../utils/Files/SaveFilesToDatabase";
import {
    checkChineseInObject,
    checkCompatibilityInObject,
    checkCyrillicInObject,
    checkJapaneseInObject
} from "../../utils/Validation/CheckForCyrillic";
import ErrorInfoGrid from "../../templates/ErrorInfoGrid";
import {Link} from "@mui/material";
import OccupationTiles from "./partials/OccupationTiles/OccupationTiles";
import {useDispatch, useSelector} from "react-redux";
import {setCachedOccupationData} from "./partials/OccupationTiles/OccupationTilesReducer";
import {MapToSaveArray} from "./partials/OccupationTiles/OccupationTileMapper";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function generateArrayOfYears() {
    var max = new Date().getFullYear()
    var min = max - 60
    var years = []

    for (var i = max; i >= min; i--) {
        years.push(i.toString())
    }
    return years
}

function filesReducer(state, action) {
    switch (action.type) {
        case ACTIONS.SET_INITIAL_FILES: {
            let tempState = action.payload.map(payload => {
                let data = { ...payload }
                data.inputRefs = action.inputRefs;
                return data;
            })

            return tempState;
        }
        case ACTIONS.SET_FILE_DATA: {
            let changedFile = state
                .filter(file => file.attachmentInfo.typeId === action.payload.typeId)
                .map(file => {
                    file.attachmentMetadataList = [].concat({ fileName: action.payload.name, size: action.payload.size });
                    file.data = action.payload.data
                    file.dataType = action.payload.dataType
                    return file;
                })

            let notChangedFile = state.filter(file => file.attachmentInfo.typeId !== action.payload.typeId)


            // return state = notChangedFile.concat(changedFile).sort((a, b) => a.attachmentInfo.typeId - b.attachmentInfo.typeId);
            return state = notChangedFile.concat(changedFile)

        }
        default:
            throw new Error();
    }
}


const initialSchoolData = {
    country: null,
    city: null,
    schoolName: null,
    graduationDate: null,
    schoolType: null,
    certificate: null,
    certificateOwnership: false,
    examYear: null,
    certificationOrgan: null,
    certificationDate: null,
    certificateNumber: null,
    certificationCountry: null,
    annexes: null,
    annexesNumbers: null,
    laureate: false,
    olimpicSelect: null,
    gradeScale: { id: '1-6', label: '1-6' },
    diplomaIB: false,
    diplomaEB: false,
    languageExam: false,
    languageExamPl: false,
    languageExamEn: false,
    languageExamEmail: getCredentials(),
    languageCertificate: false,
    skype: null,
    contest: false,
    mathPod: false,
    mathRoz: false,
    fizPod: false,
    fizRoz: false,
    chemPod: false,
    chemRoz: false,
    angPod: false,
    angRoz: false
}

const requiredFieldsOwned = [
    'country',
    'city',
    'schoolName',
    'graduationDate',
    'schoolType',
    'certificate',
    'certificateOwnership',
    'examYear',
    'certificationOrgan',
    'certificationDate',
    'certificateNumber',
    'certificationCountry',
    'annexes',
    'annexesNumbers',
    'laureate',
    'olimpicSelect',
    'gradeScale',
    'diplomaIB',
    'diplomaEB',
    'languageExam',
    'languageExamEmail',
    //'skype', Staying n case is needed
    'contest',

]


const requiredFieldsUnOwned = [
    'country',
    'city',
    'schoolName',
    'graduationDate',
    'schoolType',
    'certificate',
    'certificateOwnership',
    //'skype', Staying n case is needed
]

const requiredLengthForSchoolFields = [
    { name: "country", maxLength: 50 },
    { name: "city", maxLength: 50 },
    { name: "schoolName", maxLength: 100 },
    { name: "schoolType.id", maxLength: 20 },
    { name: "examYear", maxLength: 4 },
    { name: "certificationOrgan.id", maxLength: 100 },
    { name: "certificateNumber", maxLength: 50 },
    { name: "certificationCountry", maxLength: 50 },
    { name: "annexesNumbers", maxLength: 500 },
    { name: "olimpicSelect.id", maxLength: 100 },
    { name: "gradeScale.id", maxLength: 10 },
    { name: "skype", maxLength: 100 },
    { name: "languageExamEmail", maxLength: 100 },
]


// const textInputs = ['city', 'highSchool', 'examYear']
const selectInputs = [
    { id: "country", name: "country" },
    { id: "schoolType", name: "schoolType" },
    { id: "examYear", name: "examYear" },
    { id: "certificationOrgan", name: "certificationOrgan" },
    { id: "certificationCountry", name: "certificationCountry" },
    { id: "olimpicSelect", name: "olimpicSelect" },
    { id: "gradeScale", name: "gradeScale" }
]

const toggleButtonInputs = ["annexes", "laureate"]

const gradesNewInitialData = [

    {
        id: 0, subject: {
            "id": "matematyka",
            "label": "matematyka"
        }, level: {
            "id": "P",
            "label": "Podstawowy"
        }, grade: null, unDeletable: true, constant: true
    },
    //  {id: 1, subject: null, level: null, grade: "P",unDeletable: true, constant: true,language:true}, //TODO add polish here
    {
        id: 1, subject: null, level: {
            "id": "P",
            "label": "Podstawowy"
        }, grade: null, unDeletable: true, constant: true, language: true
    },
]

const occupationTitlesNewInitialData = [
    { id: 0, title: null, level: null, grade: null },
]

const gradesOldInitialData = [
    { id: 0, subject: null, level: null, grade: null },
]

const gradesIBInitialData = [
    { id: 0, subject: null, level: null, grade: null }
]

const gradesEBInitialData = [
    { id: 0, subject: null, level: null, grade: null }
]

const defaultDictValue = [{ id: "-", label: "-" }]

const gradeScalesOptions = [{ id: '1-6', label: '1-6' }, { id: '2-5', label: '2-5' }]
const gradesList = [{ id: '1', label: '1' }, { id: '2', label: '2' }, { id: '3', label: '3' }, {
    id: '4',
    label: '4'
}, { id: '5', label: '5' }, { id: '6', label: '6' }]
const gradesOldList = [{ id: '2', label: '2' }, { id: '3', label: '3' }, { id: '4', label: '4' }, { id: '5', label: '5' }]
const gradesListIB = [{ id: '1', label: '1' }, { id: '2', label: '2' }, { id: '3', label: '3' }, {
    id: '4',
    label: '4'
}, { id: '5', label: '5' }, { id: '6', label: '6' }, { id: '7', label: '7' }]


const levelsIB = [{ id: 'SL', label: 'SL' }, { id: 'HL', label: 'HL' }]
const levelsEB = [{ id: '2P', label: '2P' }, { id: '3P', label: '3P' }, { id: '4P', label: '4P' }, { id: '5P', label: '5P' },]


const certificateTypesRadiosEqual2002 = [
    { label: 'school.new', value: 'NEW' },
    { label: 'school.old', value: 'OLD' },
    { label: 'school.other', value: 'OTHER' }
]

const certificateTypesRadiosBefore2005 = [
    { label: 'school.old', value: 'OLD' },
    { label: 'school.ib', value: 'IB' },
    { label: 'school.other', value: 'OTHER' }
]


const certificateTypesRadiosAfter2005 = [
    { label: 'school.new', value: 'NEW' },
    { label: 'school.ib', value: 'IB' },
    { label: 'school.eb', value: 'EB' },
    { label: 'school.other', value: 'OTHER' }
]


const maxAamountOfGrades = 10;
const maxAamountOfTitles = 10;


const certificateOwnedRadios = [
    { label: 'school.owned', value: true },
    { label: 'school.notOwned', value: false }
]

const diplomaIBRadios = [
    { label: 'school.diplomaIB', value: true },
    { label: 'school.noDiplomaIB', value: false }
]

const diplomaEBRadios = [
    { label: 'school.diplomaEB', value: true },
    { label: 'school.noDiplomaEB', value: false }
]

export default function SchoolFirstDegree({candidateType, degree, abroadSelection}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme;
    const isMobile = useMediaQuery(theme.breakpoints.down('tablet'))
    const graduationYears = generateArrayOfYears()

    const levels = [{ id: 'P', label: t('school.basicLevel') }, {
        id: 'R',
        label: t('school.advancedLevel')
    }, {
        id: 'D',
        label: t('school.duo')
    }]

    const levelsNoLanguages = [{ id: 'P', label: t('school.basicLevel') }, {
        id: 'R',
        label: t('school.advancedLevel')
    }]

    const levelsOccupational = [
        { id: 'written1', label: t('school.written1') },
        { id: 'practice1', label: t('school.practice1') },
        { id: 'written2', label: t('school.written2') },
        { id: 'practice2', label: t('school.practice2') },
        { id: 'diploma', label: t('school.diploma') },
    ]

    const levelsOld = [{ id: 'graduation', label: t('school.graduationCert') }, {
        id: 'mature',
        label: t('school.maturityCert')
    }]

    //state for disabling edition
    const [readOnly, setReadOnly] = React.useState(true)

    const [countires, setCountires] = React.useState(defaultDictValue)
    const [cities, setCities] = React.useState(defaultDictValue)
    const [schoolTypes, setSchoolTypes] = React.useState(defaultDictValue)
    const [subjects, setSubjects] = React.useState(defaultDictValue)
    const [languageSubjects, setLanguageSubjects] = React.useState(defaultDictValue)
    const [titles, setTitles] = React.useState(defaultDictValue)
    const [institutions, setInstitutions] = React.useState(defaultDictValue)
    const [institutionsOKE, setInstitutionsOKE] = React.useState(defaultDictValue)
    const [olympics, setOlympics] = React.useState(defaultDictValue)
    const [schoolNamesKrem, setSchoolNamesKrem] = React.useState(defaultDictValue)

    //state for controlling dialogs
    const [isDialogSaveOpen, setIsDialogSaveOpen] = React.useState(false)

    //accessContext
    const datesContext = useContext(DatesContext)

    // const dates = useContext(DatesContext)

    const [dates, setDates] = useState()
    //data
    const [schoolData, setSchoolData] = useState(initialSchoolData)
    const [errorsState, setErrorsState] = useState([])
    const [langError, setLangError] = useState(false)

    //grades
    const [gradesNew, setGradesNew] = useState(JSON.parse(JSON.stringify(gradesNewInitialData)))
    const [occupationTitles, setOccupationTitles] = useState(JSON.parse(JSON.stringify(occupationTitlesNewInitialData)))
    const [gradesOld, setGradesOld] = useState(JSON.parse(JSON.stringify(gradesOldInitialData)))
    const [gradesIB, setGradesIB] = useState(JSON.parse(JSON.stringify(gradesIBInitialData)))
    const [gradesEB, setGradesEB] = useState(JSON.parse(JSON.stringify(gradesEBInitialData)))

    //loading awaiting save
    const [loading, setLoading] = React.useState(false)

    //files info reducer
    const [filesInfo, dispatchFilesInfo] = React.useReducer(filesReducer, [])

    //popup reducer
    const [popup, dispatchPopup] = React.useReducer(popupReducer, null);

    //input references for better file upload styling
    const inputRefs = useRef([])

    function manageAccess(dates) {
        setReadOnly(true)
        if (new Date(dates[degree]?.recruitmentStart) <= new Date(dates?.currentDateTime) && new Date(dates?.currentDateTime) <= new Date(dates[degree]?.recruitmentClosure)) {
            setReadOnly(false)
        }
    }

    function getFileWithCertificateCaption(element) {
        let tempName;
        if (schoolData.certificate === 'NEW') {
            tempName = t('school.certificateCaptionNew')
        } else if (schoolData.certificate === 'OLD') {
            tempName = t('school.certificateCaptionOld')
        } else if (schoolData.certificate === 'IB' || schoolData.certificate === 'EB') {
            tempName = t('school.certificateCaptionIbEb')
        } else {
            tempName = t('school.certificateCaptionOther')
        }

        let data = { ...element }
        data.attachmentInfo.namePL = tempName;
        return data;
    }

    function prepareDictionaries(schoolDict) {
        if (schoolDict) {
            let schoolDictValues = schoolDict.value
            let countiresDict = schoolDictValues.kraj;
            if (countiresDict) {
                let countiresTemp = []
                countiresDict.forEach(country => {
                    {
                        i18next.language == 'pl' ?
                            countiresTemp.push({ id: country.valuePL, label: country.valuePL })
                            :
                            countiresTemp.push({
                                id: country.valuePL,
                                label: (country.valueEN ? country.valueEN : country.valuePL)
                            })
                    }
                });
                countiresTemp.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
                setCountires(countiresTemp)
            }

            let citiesDict = schoolDictValues.miasto;
            if (citiesDict) {
                let citiesTemp = []
                citiesDict.forEach(city => {
                    {
                        i18next.language == 'pl' ?
                            citiesTemp.push({ id: city.valuePL, label: city.valuePL })

                            :
                            citiesTemp.push({ id: city.valuePL, label: (city.valueEN ? city.valueEN : city.valuePL) })

                    }
                });
                setCities(citiesTemp)
            }

            let schoolsDict = schoolDictValues.typ_szkoly
            if (schoolsDict) {
                let schoolsTemp = []
                schoolsDict.forEach(school => {
                    {
                        i18next.language == 'pl' ?
                            schoolsTemp.push({ id: school.key, label: school.valuePL })
                            :
                            schoolsTemp.push({
                                id: school.key,
                                label: (school.valueEN ? school.valueEN : school.valuePL)
                            })
                    }
                });
                setSchoolTypes(schoolsTemp)

            }

            let subjectsDict = schoolDictValues.przedmioty
            if (subjectsDict) {
                let subjectsTemp = []
                let subjectsTempLanguage = []
                subjectsDict.forEach(subject => {
                    {
                        if (subject.valuePL.includes('język')) {
                            (i18next.language == 'pl' ?
                                subjectsTempLanguage.push({ id: subject.valuePL, label: subject.valuePL })
                                :
                                subjectsTempLanguage.push({
                                    id: subject.valuePL,
                                    label: (subject.valueEN ? subject.valueEN : subject.valuePL)
                                })
                            )
                        }
                        if (!subject.valuePL.includes('matura') && !subject.valuePL.includes('technik')) {
                            (i18next.language == 'pl' ?
                                subjectsTemp.push({ id: subject.valuePL, label: subject.valuePL })
                                :
                                subjectsTemp.push({
                                    id: subject.valuePL,
                                    label: (subject.valueEN ? subject.valueEN : subject.valuePL)
                                })
                            )
                        }
                    }

                });

                setSubjects(subjectsTemp);
                setLanguageSubjects(subjectsTempLanguage);
            }


            let institutionsDict = schoolDictValues.organ_wydajacy
            if (institutionsDict) {
                let institutionsTemp = []
                let institutionsOKETemp = []
                institutionsDict.forEach(institution => {
                    {
                        (i18next.language == 'pl' ?
                            institutionsTemp.push({ id: institution.valuePL, label: institution.valuePL })
                            :
                            institutionsTemp.push({
                                id: institution.valuePL,
                                label: (institution.valueEN ? institution.valueEN : institution.valuePL)
                            })
                        )
                        if (institution.valuePL.includes('OKE')) {
                            (i18next.language == 'pl' ?
                                institutionsOKETemp.push({ id: institution.valuePL, label: institution.valuePL })
                                :
                                institutionsOKETemp.push({
                                    id: institution.valuePL,
                                    label: (institution.valueEN ? institution.valueEN : institution.valuePL)
                                })
                            )
                        }
                    }
                });
                setInstitutions(institutionsTemp)
                setInstitutionsOKE(institutionsOKETemp)
            }

            let olympicsDict = schoolDictValues.olimpiada
            if (olympicsDict) {
                let olympicsTemp = []
                olympicsDict.forEach(olympic => {
                    {
                        i18next.language == 'pl' ?
                            olympicsTemp.push({ id: olympic.valuePL, label: olympic.valuePL })
                            :
                            olympicsTemp.push({
                                id: olympic.valuePL,
                                label: (olympic.valueEN ? olympic.valueEN : olympic.valuePL)
                            })
                    }
                });
                setOlympics(olympicsTemp)
            }
        }
    }

    function clearGrades() {
        setGradesNew(JSON.parse(JSON.stringify(gradesNewInitialData)))
        setGradesOld(JSON.parse(JSON.stringify(gradesOldInitialData)))
        setGradesEB(JSON.parse(JSON.stringify(gradesEBInitialData)))
        setGradesIB(JSON.parse(JSON.stringify(gradesIBInitialData)))

    }

    function enableSchoolName(newValue) {
        getDictionaries(DictionaryTypeEnums.SCHOOL_NAME).then(dict => {
            if (dict) {
                let allSchools = dict.value

                let highSchools = allSchools.srednia;
                if (highSchools) {
                    let tempSchools = []
                    highSchools.forEach(school => {
                        tempSchools.push({
                            id: school.key,
                            label: school.valuePL,
                            level: school.level,
                            town: school.town,
                            type: school.typeShort
                        })
                    });
                    tempSchools.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
                    let schoolsInCity = tempSchools.filter(element => element.town === newValue.label)

                    if (schoolsInCity.length > 0) {
                        setSchoolNamesKrem(schoolsInCity)
                    } else {
                        setSchoolNamesKrem([])
                    }
                }

            }
        })
    }

    function handleValidation() {
        let data = { ...schoolData }
        let errors = []
        let gradesValidation = true

        if (data['examYear'] === null || data['examYear'] === undefined || data['examYear'].toString().trim().length === 0) {
            errors.push({id: 'examYear'})
        }
        
        if (data.certificateOwnership === true) {
            requiredFieldsOwned.forEach(element => {
                if (data[element] === null || data[element] === undefined || data[element].toString().trim().length === 0) {
                    switch (schoolData.certificate) {
                        case 'NEW':
                            if ((schoolData.annexes !== true && element === 'annexesNumbers') || (schoolData.laureate === false && element === 'olimpicSelect')) {
                                break;
                            } else {
                                if (schoolData.languageExam === false && element === 'skype') {
                                    break;
                                }
                                errors.push({ id: element })
                            }
                            break;
                        default:
                            if ((element === 'annexes' || element === 'annexesNumbers' || schoolData.laureate === false || element === 'olimpicSelect')) {
                                break;
                            } else {
                                if (schoolData.languageExam === false && element === 'skype') {
                                    break;
                                }
                                errors.push({ id: element })
                            }
                    }

                }
            })
            //
            // {name: "grade.subject", maxLength: 50},
            // {name: "grade.level", maxLength: 50},
            // {name: "grade.grade", maxLength: 5}

            switch (schoolData.certificate) {
                case 'NEW':
                    gradesNew.forEach(element => {
                        if (!element.grade || !element.level || !element.subject) {
                            gradesValidation = false;
                        }
                        // if (element.grade.length > 5 || element.level.length > 50 || element.subject.length > 50) {
                        //     errors = errors.concat({
                        //         id: element,
                        //         value: "school.tooLong"
                        //     });
                        // }
                    })
                    break
                case 'OLD':
                    gradesOld.forEach(element => {
                        if (!element.grade || !element.level || !element.subject) {
                            gradesValidation = false;
                        }
                    })
                    break
                case 'IB':
                    gradesIB.forEach(element => {
                        if (!element.grade || !element.level || !element.subject) {
                            gradesValidation = false;
                        }
                    })
                    break
                case 'EB':
                    gradesEB.forEach(element => {
                        if (!element.grade || !element.level || !element.subject) {
                            gradesValidation = false;
                        }
                    })
                    break
                default:
                    break
            }

        } else {
            requiredFieldsUnOwned.forEach(element => {
                if (data[element] === null || data[element] === undefined || data[element].toString().trim().length === 0) {

                    if (schoolData.languageExam !== false || element !== 'skype') {
                        errors.push({ id: element })
                    }

                }
            })
        }


        requiredLengthForSchoolFields.forEach(field => {
            if (data[field.name]) {
                if (data[field.name].length > field.maxLength) {
                    errors = errors.concat({
                        id: field.name,
                        value: "school.tooLong"
                    });
                }
            }
        })

        if (schoolData.languageExam === true && schoolData.languageExamPl === false && schoolData.languageExamEn === false) {
            errors.push({ id: 'langSelection' });
            setLangError(true);
        } else {
            setLangError(false);
        }

        if (checkCyrillicInObject(data))
            errors = errors.concat({
                id: "cyrillicError",
                value: "cyrillicError"
            })
        if (checkChineseInObject(data))
            errors = errors.concat({
                id: "chineseError",
                value: "chineseError"
            })
        if (checkJapaneseInObject(data))
            errors = errors.concat({
                id: "japaneseError",
                value: "japaneseError"
            })

        let incompatibleChars = checkCompatibilityInObject(data);
        if (incompatibleChars)
            errors = errors.concat({
                id: "compatibilityError",
                value: "personal.notCompatiable",
                extraValue: incompatibleChars
            })

        setErrorsState(errors)
        return {
            errors: errors,
            gradeErrors: gradesValidation,
        }
    }

    let occupationTitlesData = useSelector(state => state.occupationTitles.data);
    const dispatch = useDispatch();


    useEffect(() => {


        FetchService.getFilesInfo(degree, 'GRADUATEDSCHOOL').then(response => {
            let tempInputRefs = response.zawartosc?.map((element, index) => {
                return inputRefs.current[index] = React.createRef();
            })
            dispatchFilesInfo({ type: ACTIONS.SET_INITIAL_FILES, payload: response.zawartosc, inputRefs: tempInputRefs })
        })

        Promise.all([
            getDictionaries(DictionaryTypeEnums.SCHOOL),
            getDictionaries(DictionaryTypeEnums.SCHOOL_NAME)
        ]).then(([responseDicts, responseSchoolNames]) => {
            prepareDictionaries(responseDicts)

            //I just want to know for anyone reading this and trying to understand what the fuck is happening here that it is not my fault I was in the same situation as u and i did the best i can.

            if (responseDicts) {

                FetchService.getSchoolData(degree).then(response => {
                    let loadedData = { ...initialSchoolData }
                    if (response && response.zawartosc) {
                        loadedData.schoolDictionaryId = response.zawartosc?.schoolDictionaryId

                        if (response.zawartosc.schoolName) {
                            loadedData.schoolName = response.zawartosc.schoolName
                        } else {
                            let schoolObject = responseSchoolNames.value.srednia.filter(element => Number(element.key) === response.zawartosc.schoolDictionaryId)[0]
                            if (schoolObject) {
                                loadedData.schoolName = {
                                    id: schoolObject.key,
                                    label: schoolObject.valuePL,
                                    level: schoolObject.level,
                                    town: schoolObject.town,
                                    type: schoolObject.typeShort
                                }
                            }
                        }

                        enableSchoolName({ label: response.zawartosc.city })

                        loadedData.country = response.zawartosc?.country || initialSchoolData.country
                        loadedData.city = response.zawartosc?.city || initialSchoolData.city
                        loadedData.graduationDate = response.zawartosc?.graduationDate || initialSchoolData.graduationDate

                        let schoolTypeObject = responseDicts.value.typ_szkoly.filter(element => element.key === response.zawartosc.schoolType)[0]
                        if (schoolTypeObject) {
                            loadedData.schoolType = { id: schoolTypeObject?.key, label: schoolTypeObject?.valuePL }
                        } else {
                            loadedData.schoolType = null
                        }

                        loadedData.certificate = response.zawartosc?.certificate || initialSchoolData.certificate
                        loadedData.certificateOwnership = stringToBoolean(response.zawartosc?.certificateOwnership)
                        loadedData.examYear = response.zawartosc?.examYear
                        loadedData.certificationOrgan = response.zawartosc?.certificationOrgan
                        loadedData.certificationDate = response.zawartosc?.certificationDate || initialSchoolData.certificationDate
                        loadedData.certificateNumber = response.zawartosc?.certificateNumber
                        loadedData.certificationCountry = response.zawartosc?.certificationCountry
                        loadedData.annexes = stringToBoolean(response.zawartosc?.annexes)
                        loadedData.annexesNumbers = response.zawartosc?.annexesNumbers || initialSchoolData.annexesNumbers
                        loadedData.laureate = response.zawartosc?.laureate || initialSchoolData.laureate
                        loadedData.olimpicSelect = response.zawartosc?.olimpicSelect || initialSchoolData.olimpicSelect
                        if (response.zawartosc.gradeScale) {
                            loadedData.gradeScale = {
                                id: response.zawartosc?.gradeScale,
                                label: response.zawartosc?.gradeScale
                            }
                        } else {
                            loadedData.gradeScale = initialSchoolData.gradeScale;
                        }
                        loadedData.diplomaIB = response.zawartosc?.diplomaIB || initialSchoolData.diplomaIB
                        loadedData.diplomaEB = response.zawartosc?.diplomaEB || initialSchoolData.diplomaEB
                        loadedData.languageExam = stringToBoolean(response.zawartosc?.languageExam)
                        loadedData.languageExamPl = stringToBoolean(response.zawartosc?.languageExamPl)
                        loadedData.languageExamEn = stringToBoolean(response.zawartosc?.languageExamEn)

                        loadedData.skype = response.zawartosc?.skype || initialSchoolData.skype
                        loadedData.languageExamEmail = response.zawartosc?.languageExamEmail || initialSchoolData.languageExamEmail
                        loadedData.contest = stringToBoolean(response.zawartosc?.contest)
                        loadedData.languageCertificate = stringToBoolean(response.zawartosc?.languageCertificate)

                        loadedData.mathPod = response.zawartosc?.mathPod
                        loadedData.mathRoz = response.zawartosc?.mathRoz
                        loadedData.fizPod = response.zawartosc?.fizPod
                        loadedData.fizRoz = response.zawartosc?.fizRoz
                        loadedData.chemPod = response.zawartosc?.chemPod
                        loadedData.chemRoz = response.zawartosc?.chemRoz
                        loadedData.angPod = response.zawartosc?.angPod
                        loadedData.angRoz = response.zawartosc?.angRoz

                        setSchoolData(loadedData)

                        //grades
                        let results = [];

                        dispatch(setCachedOccupationData(response.zawartosc.qualification)) // I am sorry this was he easiest way

                        switch (response.zawartosc?.certificate) {
                            case 'NEW':
                                if (response.zawartosc.examResults.length > 0) {
                                    response.zawartosc.examResults.forEach(grade => {
                                        let level = levels.filter(e => e.id === grade.level)

                                        let translatedValue = (i18next.language == 'pl' ? grade.subject : responseDicts.value.przedmioty.find(e => e.id == grade.key)?.valueEN);
                                        results.push({
                                            id: 0,
                                            subject: {
                                                id: grade.subject,
                                                label: (translatedValue ? translatedValue : grade.subject)
                                            },
                                            level: level[0],
                                            grade: grade.grade
                                        })
                                    })
                                } else {
                                    results = gradesNewInitialData
                                }
                                setGradesNew(results)


                                break
                            case 'OLD':
                                if (response.zawartosc.examResults.length > 0) {
                                    response.zawartosc.examResults.forEach(grade => {
                                        let level = levelsOld.filter(e => e.id === grade.level)
                                        let translatedValue = (i18next.language == 'pl' ? grade.subject : responseDicts.value.przedmioty.find(e => e.id == grade.key)?.valueEN);

                                        results.push({
                                            id: 0,

                                            subject: {
                                                id: grade.subject,
                                                label: (translatedValue ? translatedValue : grade.subject)
                                            },
                                            level: level[0],
                                            grade: grade.grade
                                        })
                                    })
                                } else {
                                    results = gradesOldInitialData
                                }
                                setGradesOld(results)


                                break
                            case 'IB':
                                if (response.zawartosc.examResults.length > 0) {
                                    response.zawartosc.examResults.forEach(grade => {
                                        let level = levelsIB.filter(e => e.id === grade.level)
                                        let translatedValue = (i18next.language == 'pl' ? grade.subject : responseDicts.value.przedmioty.find(e => e.id == grade.key)?.valueEN);

                                        results.push({
                                            id: 0,
                                            subject: {
                                                id: grade.subject,
                                                label: (translatedValue ? translatedValue : grade.subject)
                                            },
                                            level: level[0],
                                            grade: grade.grade
                                        })
                                    })
                                } else {
                                    results = gradesIBInitialData
                                }
                                setGradesIB(results)


                                break
                            case 'EB':
                                if (response.zawartosc.examResults.length > 0) {
                                    response.zawartosc.examResults.forEach(grade => {
                                        let level = levelsEB.filter(e => e.id === grade.level)
                                        let translatedValue = (i18next.language == 'pl' ? grade.subject : responseDicts.value.przedmioty.find(e => e.id == grade.key)?.valueEN);

                                        results.push({
                                            id: 0,
                                            subject: {
                                                id: grade.subject,
                                                label: (translatedValue ? translatedValue : grade.subject)
                                            },
                                            level: level[0],
                                            grade: grade.grade
                                        })
                                    })
                                } else {
                                    results = gradesEBInitialData
                                }
                                setGradesEB(results)


                                break
                            case 'OTHER':
                                results = null
                                break
                            default:
                                results = null
                        }
                    }
                })
            }
        })
        FetchService.getAccessToRecruitmentData(degree).then(response => {
            if (response && response.zawartosc !== undefined) {
                let disabled = response.zawartosc
                if (!disabled) {
                    datesContext.fetchGetMoreDates(degree, datesContext.currentSemester).then(result => {
                        setDates(result)
                        manageAccess(result)
                    })
                }
            }
        })



    }, [])

    const changeDataHandler = (event, newValue, ref) => {
        let fields = { ...schoolData };

        if (ref && (ref === 'selectOption' || ref === 'blur')) {
            let inputSelectField = selectInputs.find(selectInput => event.target.id.startsWith(selectInput.id))
            fields[inputSelectField.name] = newValue
        } else if (event.target.name === 'laureate' || event.target.name === 'languageExam' || event.target.name === 'languageExamPl' || event.target.name === 'languageExamEn' || event.target.name === 'contest' || event.target.name === 'languageCertificate') {
            fields[event.target.name] = event.target.checked;
        } else if (toggleButtonInputs.includes(event.target.name)) {
            if (fields[event.target.name] !== null && fields[event.target.name] === stringToBoolean(event.target.value)) {
                fields[event.target.name] = null
            } else {
                fields[event.target.name] = stringToBoolean(event.target.value);
            }
        } else if (event.target.name === 'certificate') {
            fields[event.target.name] = event.target.value;

            switch (event.target.value) {
                case 'IB':
                    fields['certificationOrgan'] = 'International Baccalureate Organization';
                    break
                case 'EB':
                    fields['certificationOrgan'] = 'Szkoła Europejska';
                    break
                case 'OLD':
                    fields['certificationOrgan'] = 'Szkoła średnia';
                    break
                case 'OTHER':
                    fields['certificationOrgan'] = 'Zagraniczna szkoła średnia';
                    break
                default:
                    fields['certificationOrgan'] = '';
                    break
            }


            clearGrades()
        } else if (event.target.name === 'certificateOwnership' || event.target.name === 'diplomaIB' || event.target.name === 'diplomaEB') {
            fields[event.target.name] = stringToBoolean(event.target.value);
            clearGrades()
        } else if ("mathPod" === event.target.name ||
            "mathRoz" === event.target.name ||
            "fizPod" === event.target.name ||
            "fizRoz" === event.target.name ||
            "chemPod" === event.target.name ||
            "chemRoz" === event.target.name ||
            "angPod" === event.target.name ||
            "angRoz" === event.target.name) {
            fields[event.target.name] = event.target.checked;
        } else if (event.target.value !== '') {

            fields[event.target.name] = event.target?.value[0].toUpperCase() + event.target?.value.slice(1)
        } else {
            fields[event.target.name] = null
        }
        setSchoolData(fields)
    }

    const handleAddGrade = (grades, setGrades) => {
        let tempGrades = [...grades]
        if (tempGrades.length < maxAamountOfGrades) {
            tempGrades.push({ subject: null, level: null, grade: null })
            setGrades(tempGrades)
        }
    }


    const handleDeleteGrade = (i, grades, setGrades) => {
        let tempGrades = [...grades]
        if (tempGrades.length > 1) {
            tempGrades.splice(i, 1)
            setGrades(tempGrades)
        }
    }

    const changeNewGradesHandler = (event, newValue, ref) => {
        let tempGrades = [...gradesNew]

        if (ref && (ref === 'selectOption' || ref === 'blur')) {
            if (event.target.id.startsWith('subject')) {
                tempGrades[event.target.id.substr(7, 1)].subject = newValue
            } else {
                tempGrades[event.target.id.substr(5, 1)].level = newValue
            }
        } else {
            if (event.target.value === '') {
                tempGrades[event.target.name.substr(5, 1)].grade = ''
            } else if (event.target.value <= 0) {
                tempGrades[event.target.name.substr(5, 1)].grade = 0
            } else if (event.target.value >= 100) {
                tempGrades[event.target.name.substr(5, 1)].grade = 100
            } else {
                // tempGrades[event.target.name.substr(6,1)].grade = event.target.value
                var integer = event.target.value.match(/[1-9][0-9]*/)[0]
                tempGrades[event.target.name.substr(5, 1)].grade = integer
            }
        }
        setGradesNew(tempGrades)
    }


    const changeOldGradesHandler = (event, newValue, ref) => {
        let tempGrades = [...gradesOld]
        if (ref && (ref === 'selectOption' || ref === 'blur')) {
            if (event.target.id.startsWith('subject')) {
                tempGrades[event.target.id.substr(7, 1)].subject = newValue
            } else if (event.target.id.startsWith('level')) {
                tempGrades[event.target.id.substr(5, 1)].level = newValue
            } else {
                tempGrades[event.target.id.substr(5, 1)].grade = newValue?.id
            }
        }
        setGradesOld(tempGrades)
    }

    const changeIBGradesHandler = (event, newValue, ref) => {
        let tempGrades = [...gradesIB]
        if (ref && (ref === 'selectOption' || ref === 'blur')) {
            if (event.target.id.startsWith('subject')) {
                tempGrades[event.target.id.substr(7, 1)].subject = newValue
            } else if (event.target.id.startsWith('level')) {
                tempGrades[event.target.id.substr(5, 1)].level = newValue
            } else {
                tempGrades[event.target.id.substr(5, 1)].grade = newValue?.id
            }
        }
        setGradesIB(tempGrades)
    }

    const changeEBGradesHandler = (event, newValue, ref) => {
        let tempGrades = [...gradesEB]
        if (ref && (ref === 'selectOption' || ref === 'blur')) {
            if (event.target.id.startsWith('subject')) {
                tempGrades[event.target.id.substr(7, 1)].subject = newValue
            } else
                tempGrades[event.target.id.substr(5, 1)].level = newValue
        } else {
            if (event.target.value === '') {
                tempGrades[event.target.name.substr(5, 1)].grade = null
            } else if (event.target.value < 1) {
                tempGrades[event.target.name.substr(5, 1)].grade = 1
            } else if (event.target.value > 10) {
                tempGrades[event.target.name.substr(5, 1)].grade = 10
            } else {
                let with2Decimals = event.target.value.match(/^-?\d+(?:\.\d{0,2})?/)[0]
                tempGrades[event.target.name.substr(5, 1)].grade = with2Decimals
            }
        }
        setGradesEB(tempGrades)
    }

    const handleOpenSaveConfirmDialog = () => {
        let validation = handleValidation();
        
        if (validation.errors.length > 0) {

            warningNotification("personal.formValidationError", t(validation.errors[0].value) + validation.errors[0].extraValue.join(', '));
        } else if (validation.errors.length === 0 && validation.gradeErrors === true) {
            setIsDialogSaveOpen(true)
        } else {
            warningNotification('addGrades', " ");
        }
    }

    const handleCloseSaveConfirmDialog = () => {
        setIsDialogSaveOpen(false)
    }

    const handleSave = () => {
        setLoading(true)
        saveFilesToDatabase(filesInfo)

        handleCloseSaveConfirmDialog()
        let grades = null;
        switch (schoolData.certificate) {
            case 'NEW':
                grades = gradesNew
                break
            case 'OLD':
                grades = gradesOld
                break
            case 'IB':
                grades = gradesIB
                break
            case 'EB':
                grades = gradesEB
                break
            case 'OTHER':
                grades = null
                break
            default:
                grades = null
        }


        let gradesArray = []
        grades?.forEach(grade => {
            gradesArray.push(
                {
                    "subject": grade.subject?.id,
                    "grade": grade?.grade,
                    "level": grade.level?.id
                }
            )
            if (gradesArray[0].grade === null) {
                gradesArray = [];
            }
        })

        let titlesArray = []
        if ((schoolData.schoolType.id === 'T' || schoolData.schoolType.id === 'TU' || schoolData.schoolType.id === 'SB2')) { // && schoolData.certificate === 'NEW'
            titlesArray = MapToSaveArray({ occupationTitlesData: occupationTitlesData });
        }

        let gradDate = null;
        if (schoolData.graduationDate) {
            let dateString = schoolData.graduationDate
            let [year, month, day] = dateString.split("-");
            gradDate = new Date(Date.UTC(year, month - 1, day));
        } else {
            gradDate = new Date();
        }

        let certDate = null;
        if (schoolData.certificationDate) {
            let dateString = schoolData.certificationDate
            let [year, month, day] = dateString.split("-");
            certDate = new Date(Date.UTC(year, month - 1, day));
        } else {
            certDate = new Date()
        }

        let schoolName = '';
        let schoolNameID = 0;
        if (schoolNamesKrem.filter(element => element.id === schoolData?.schoolName?.id).length > 0) {
            schoolNameID = schoolNamesKrem.filter(element => element.id === schoolData.schoolName.id)[0]?.id
        } else {
            schoolName = schoolData?.schoolName
        }

        let certificationOrganId = ''
        if (schoolData.certificationOrgan?.id) {
            certificationOrganId = schoolData.certificationOrgan.id
        } else {
            certificationOrganId = institutions.filter(element => element.label === schoolData.certificationOrgan)[0]?.id
        }


        let data = {
            "schoolDictionaryId": schoolNameID,
            "schoolName": schoolName,
            "country": schoolData?.country,
            "city": schoolData?.city,
            "graduationDate": gradDate,
            "schoolType": schoolData?.schoolType?.id,
            "certificate": schoolData?.certificate,
            "certificateOwnership": stringToBoolean(schoolData?.certificateOwnership),
            "examYear": schoolData?.examYear,
            "certificationOrgan": certificationOrganId,
            "certificationDate": certDate,
            "certificateNumber": schoolData?.certificateNumber,
            "certificationCountry": schoolData?.certificationCountry,
            "annexes": schoolData?.annexes,
            "annexesNumbers": schoolData?.annexesNumbers,
            "laureate": schoolData?.laureate,
            "olimpicSelect": schoolData?.olimpicSelect?.id,
            "gradeScale": schoolData?.gradeScale?.id,
            "diplomaIB": stringToBoolean(schoolData?.diplomaIB),
            "diplomaEB": stringToBoolean(schoolData?.diplomaEB),
            "examResults": gradesArray,
            "qualification": titlesArray,
            "languageExam": stringToBoolean(schoolData?.languageExam),
            "languageExamPl": stringToBoolean(schoolData?.languageExamPl),
            "languageExamEn": stringToBoolean(schoolData?.languageExamEn),
            "skype": schoolData?.skype,
            "languageExamEmail": schoolData?.languageExamEmail,
            "languageCertificate": stringToBoolean(schoolData?.languageCertificate),
            "contest": schoolData?.contest,
            "mathPod": schoolData.mathPod,
            "mathRoz": schoolData.mathRoz,
            "fizPod": schoolData.fizPod,
            "fizRoz": schoolData.fizRoz,
            "chemPod": schoolData.chemPod,
            "chemRoz": schoolData.chemRoz,
            "angPod": schoolData.angPod,
            "angRoz": schoolData.angRoz,
        }


        FetchService.saveSchoolData(data, degree).then(() => {
            modifyAccessStorage(degree)
            setLoading(false)
        })
    }


    return (
        <>
            <ThemeProvider theme={theme}>
                <Box sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: isMobile ? 'center' : 'flex-start',
                    flexDirection: isMobile ? 'column' : 'row'
                }}>
                    <Grid sx={{ padding: '2.5vh' }} display="flex" justifyItems="end">
                        <MenuTile title={t('school.schoolFirstDegree')} locked={false} />
                    </Grid>
                    {/* Padding grida poniżej jest zawsze o 1vh większy, bo padding komponentu MenuTile to 1vh */}
                    <Grid sx={{ padding: isMobile ? '1vh' : '3vh' }} container direction={'row'}
                        justifyContent="flex-start" alignItems="flex-start">
                        <Grid style={{ width: '100%' }} container direction={isMobile ? 'column' : 'row'}
                            justifyContent="space-between">
                            <Grid style={{ width: isMobile ? '100%' : '45%' }}>
                                <CustomSelectField editState={readOnly} name="country" id="country"
                                    title={'personal.kraj'} options={countires}
                                    value={schoolData.country} freeSolo={true} required={true}
                                    changeHandler={(event, newValue) => {
                                        let fields = { ...schoolData };
                                        fields.country = newValue.id
                                        setSchoolData(fields)
                                    }} errors={errorsState} />
                                <CustomSelectField editState={readOnly} name="city" id="city" title={t('school.city')}
                                    options={cities} value={schoolData.city} freeSolo={true}
                                    required={true} changeHandler={(event, newValue) => {
                                        let fields = { ...schoolData };
                                        if (newValue.id) {
                                            fields.city = newValue.id
                                        } else {
                                            fields.city = newValue
                                        }
                                        fields.schoolName = null
                                        fields.schoolType = null
                                        enableSchoolName(newValue)
                                        setSchoolData(fields)

                                    }} errors={errorsState} />
                            </Grid>
                            <Grid style={{ width: isMobile ? '100%' : '45%' }}>

                                {/* <CustomGridField name="schoolName" title={t('school.highSchool')} label={t('school.highSchool')} value={schoolData.schoolName} changeHandler={changeDataHandler} capital={true} required={true}/> */}
                                <Tooltip title={schoolData.city === null ? t('school.chooseCity') : ''}>
                                    <span>
                                        <CustomSelectField editState={!readOnly && schoolData.city !== null ? false : true}
                                            name="schoolName" id="schoolName" title={'school.highSchool'}
                                            options={schoolNamesKrem} value={schoolData.schoolName} freeSolo={true}
                                            required={true} changeHandler={(event, newValue) => {
                                                let fields = { ...schoolData };
                                                let schoolType = null;
                                                if (newValue.type === 'TECH') {
                                                    schoolType = schoolTypes.filter(element => element?.id === 'T')
                                                } else {
                                                    schoolType = schoolTypes.filter(element => element?.id === newValue.type)
                                                }
                                                if (schoolType.length !== 0) {
                                                    fields.schoolType = schoolType[0]
                                                }
                                                fields.schoolName = newValue
                                                setSchoolData(fields)
                                            }} errors={errorsState} />
                                    </span>
                                </Tooltip>


                                <Box style={{
                                    display: 'flex',
                                    width: isMobile ? '97%' : '92%',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center'
                                }}>
                                    <CustomDatePicker editState={readOnly} name="graduationDate"
                                        title={t('school.graduationDate')}
                                        label={t('school.graduationDate')}
                                                      value={schoolData.graduationDate} disabledState={readOnly}
                                        changeHandler={(date) => {
                                            let fields = { ...schoolData };
                                            fields.graduationDate = date
                                            setSchoolData(fields)
                                        }} required={true} disableFuture={false} errors={errorsState} />
                                    <Tooltip title={t('popup.graduationDate')} arrow>
                                        <IconButton color='secondary' variant="contained"
                                            children={<HelpOutlineIcon sx={{ fontSize: 30 }} />} />
                                    </Tooltip>
                                </Box>

                                <CustomSelectField
                                    editState={readOnly || (schoolData.city && schoolNamesKrem.filter(element => element.id === schoolData.schoolName?.id).length > 0) ? true : false}
                                    name={`schoolType`} id={`schoolType`} title={'school.schoolType'}
                                    options={schoolTypes} value={schoolData.schoolType}
                                    changeHandler={changeDataHandler} required={true} errors={errorsState} />
                            </Grid>
                        </Grid>

                        <Grid style={{
                            width: '100%',
                            textAlign: 'left',
                            marginBottom: '2vh',
                            marginTop: '2vh',
                            paddingLeft: '1.5vw'
                        }}>
                            <Typography fontWeight='bold' variant='h2'>{t('school.certificateData')}</Typography>
                        </Grid>


                        <Box style={{
                            display: 'flex',
                            width: isMobile ? '97%' : '96%',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}>
                            <CustomSelectField
                                editState={readOnly || !(schoolData.country && schoolData.city && schoolData.schoolName && schoolData.graduationDate && schoolData.schoolType)}
                                name={`examYear`} id={`examYear`}
                                title={'school.examYear'} options={graduationYears}
                                value={schoolData.examYear} changeHandler={(event, newValue) => {


                                    let currentSchoolOptions = (schoolData.examYear == 2002 ? certificateTypesRadiosEqual2002 : (schoolData.examYear < 2005 ? certificateTypesRadiosBefore2005 : certificateTypesRadiosAfter2005));
                                    let newSchoolOptions = (newValue == 2002 ? certificateTypesRadiosEqual2002 : (newValue < 2005 ? certificateTypesRadiosBefore2005 : certificateTypesRadiosAfter2005));


                                    let tmpSchoolData = {}

                                    if (schoolData.certificate && !newSchoolOptions.some(obj => obj.value === schoolData.certificate)) {
                                        tmpSchoolData = { ...initialSchoolData };
                                        tmpSchoolData.city = schoolData.country
                                        tmpSchoolData.country = schoolData.country
                                        tmpSchoolData.schoolName = schoolData.schoolName
                                        tmpSchoolData.graduationDate = schoolData.graduationDate
                                        tmpSchoolData.schoolType = schoolData.schoolType
                                    } else {
                                        tmpSchoolData = { ...schoolData };
                                    }

                                    tmpSchoolData.examYear = newValue;
                                    if (newValue < 1991) {
                                        tmpSchoolData.gradeScale = { id: '2-5', label: '2-5' };
                                    } else {
                                        tmpSchoolData.gradeScale = { id: '1-6', label: '1-6' };
                                    }


                                    setSchoolData(tmpSchoolData)

                                }}
                                required={true} errors={errorsState} />
                            <Tooltip title={t('popup.maturaDate')} arrow>
                                <IconButton color='secondary' variant="contained"
                                    children={<HelpOutlineIcon sx={{ fontSize: 30 }} />} />
                            </Tooltip>
                        </Box>


                        <CustomRadioGroup
                            title={(schoolData.examYear ? t('school.certificateType') : t('school.certificateType') + (" (uzupełnij rok zdanej matury żeby odblokować)"))}
                            name={`certificate`}
                            disabledState={readOnly || !schoolData.examYear}
                            value={schoolData.certificate}
                            radioData={(schoolData.examYear == 2002 ? certificateTypesRadiosEqual2002 : (schoolData.examYear < 2005 ? certificateTypesRadiosBefore2005 : certificateTypesRadiosAfter2005))}
                            changeHandler={changeDataHandler} errors={errorsState} />

                        {(schoolData.examYear == datesContext?.dates?.currentDateTime.substring(0, 4) ?
                            <Grid style={{ width: '100%' }}>
                                <Grid style={{
                                    width: '100%',
                                    textAlign: 'left',
                                    marginBottom: '2vh',
                                    marginTop: '2vh',
                                    paddingLeft: '1.5vw'
                                }}>
                                    <Typography fontWeight='bold' variant='h2'>{t('school.olimpics')}</Typography>
                                </Grid>
                                <Grid style={{ width: isMobile ? '100%' : '80%' }}>
                                    <Box style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center'
                                    }}>
                                        {/* <CustomToggleButtonTrueFalseField name="laureate" title={t('school.laureate')} label={t('school.laureate')} value={schoolData.laureate}  changeHandler={changeDataHandler} />  */}
                                        <CustomCheckboxField disabledState={readOnly} name="laureate"
                                            title={t('school.laureate')} label={t('school.laureate')}
                                            value={schoolData.laureate}
                                            changeHandler={changeDataHandler}
                                            className={classes.daneOsoboweGridField} />
                                        <CustomSelectField editState={!schoolData.laureate || readOnly}
                                            name={`olimpicSelect`} id={`olimpicSelect`}
                                            title={'school.olimpicSelect'} options={olympics}
                                            value={schoolData.olimpicSelect}
                                            changeHandler={changeDataHandler} required={true}
                                            errors={errorsState} />

                                    </Box>
                                    {schoolData.laureate ?
                                        (filesInfo?.map((element, index) => {
                                            return (
                                                <React.Fragment key={element.attachmentInfo.typeId}>
                                                    {element.attachmentInfo.typeId === 2 && schoolData.laureate ?
                                                        FileUploadComponent(index, element, dispatchFilesInfo, dispatchPopup, "laureate", readOnly) : null}
                                                </React.Fragment>
                                            )
                                        })) : null}
                                </Grid>
                            </Grid> : null)}


                        {/* TYTUŁY ZAWODOWE */}
                        {schoolData.schoolType?.id === 'T' || schoolData.schoolType?.id === 'TU' || schoolData.schoolType?.id === 'SB2' ?
                            <>
                                <Grid style={{
                                    width: '98.2%',
                                    textAlign: 'left',
                                    marginBottom: '2vh',
                                    marginTop: '2vh',
                                    paddingLeft: '1.5vw'
                                }}>
                                    <Typography sx={{ marginBottom: '2vh' }} fontWeight='bold'
                                        variant='h2'>{t("school.technikTitle")}</Typography>
                                    <OccupationTiles editState={readOnly} />
                                </Grid>

                                {filesInfo?.map((element, index) => {
                                    return (
                                        <React.Fragment key={element.attachmentInfo.typeId}>
                                            {element.attachmentInfo.typeId === 3 ?
                                                FileUploadComponent(index, element, dispatchFilesInfo, dispatchPopup, "dyplomTechnik", readOnly) : null}
                                        </React.Fragment>
                                    )
                                })}

                            </> : null}

                        <Grid style={{
                            width: '100%',
                            textAlign: 'left',
                            marginBottom: '2vh',
                            marginTop: '2vh',
                            paddingLeft: '1.5vw'
                        }}>
                            <Typography fontWeight='bold' variant='h2'>{t('school.certificateInfo')}</Typography>
                        </Grid>
                        <CustomRadioGroup disabledState={readOnly || !schoolData.certificate}
                            name={`certificateOwnership`}
                            title={t("pickOne")}
                            value={schoolData.certificateOwnership} radioData={certificateOwnedRadios}
                            changeHandler={changeDataHandler} errors={errorsState} />

                        {schoolData.certificate && schoolData.certificateOwnership === true ?
                            <Grid style={{ width: '100%' }} container direction={isMobile ? 'column' : 'row'}
                                justifyContent="space-between">
                                <Grid style={{ width: isMobile ? '100%' : '100%' }}>
                                    <CustomSelectField editState={readOnly || (schoolData.certificate != 'NEW')}
                                        name={`certificationOrgan`}
                                        id={`certificationOrgan`} title={'school.certificationOrgan'}
                                        options={(schoolData.certificate == 'NEW' ? institutionsOKE : institutions)}
                                        value={schoolData.certificationOrgan}
                                        changeHandler={changeDataHandler} required={true}
                                        errors={errorsState} />

                                    <Box style={{
                                        display: 'flex',
                                        width: isMobile ? '97%' : '96%',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center'
                                    }}>
                                        <CustomGridField disabledState={readOnly} name="certificateNumber"
                                            title={t('school.certificateNumber')}
                                            label={t('school.certificateNumber')}
                                            value={schoolData.certificateNumber}
                                            changeHandler={changeDataHandler} capital={true}
                                            required={true} errors={errorsState} />
                                        {schoolData.certificateOwnership === true && schoolData.certificate !== 'OTHER' ?
                                            <Tooltip title={t('popup.photoToolTip')} arrow>
                                                <IconButton color='secondary' variant="contained" onClick={() => {
                                                    dispatchPopup({
                                                        type: 'OPEN',
                                                        payload: schoolData.certificate + "certificateNumber"
                                                    })
                                                }} children={<HelpOutlineIcon sx={{ fontSize: 30 }} />} />
                                            </Tooltip> :
                                            <Tooltip title={t('popup.certificateTypeNumber')} arrow>
                                                <IconButton color='secondary' variant="contained"
                                                    children={<HelpOutlineIcon sx={{ fontSize: 30 }} />} />
                                            </Tooltip>}
                                    </Box>
                                    <Box style={{
                                        display: 'flex',
                                        width: isMobile ? '97%' : '96%',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center'
                                    }}>
                                        <CustomDatePicker editState={readOnly} name="certificationDate"
                                            title={t('school.certificationDate')}
                                            label={t('school.certificationDate')}
                                            value={schoolData.certificationDate} disabledState={false}
                                            changeHandler={(date) => {
                                                let fields = { ...schoolData };
                                                fields.certificationDate = date
                                                setSchoolData(fields)
                                            }} required={true} disableFuture={false}
                                            errors={errorsState} />
                                        <Tooltip title={t('popup.certificateDate')} arrow>
                                            <IconButton color='secondary' variant="contained"
                                                children={<HelpOutlineIcon sx={{ fontSize: 30 }} />} />
                                        </Tooltip>
                                    </Box>

                                    <CustomSelectField editState={readOnly} name="certificationCountry"
                                        id="certificationCountry"
                                        title={'school.certificationCountry'} options={countires}
                                        value={schoolData.certificationCountry} freeSolo={true}
                                        required={true} changeHandler={(event, newValue) => {
                                            let fields = { ...schoolData };
                                            fields.certificationCountry = newValue.id
                                            setSchoolData(fields)
                                        }} errors={errorsState} />
                                    {schoolData.certificateOwnership === true && schoolData.certificate === 'NEW' ?
                                        <>
                                            <Box style={{
                                                display: 'flex',
                                                width: isMobile ? '97%' : '96%',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center'
                                            }}>
                                                <CustomToggleButtonTrueFalseField disabledState={readOnly}
                                                    name="annexes"
                                                    title={t('school.annexes')}
                                                    label={t('school.annexes')}
                                                    value={schoolData.annexes}
                                                    changeHandler={changeDataHandler}
                                                    errors={errorsState} />
                                                <CustomGridField
                                                    disabledState={!readOnly && schoolData.annexes === true ? false : true}
                                                    name="annexesNumbers" title={t('school.annexesNumbers')}
                                                    label={t('school.annexesNumbers')} value={schoolData.annexesNumbers}
                                                    changeHandler={changeDataHandler} required={true} capital={true}
                                                    mask={false} year={false} errors={errorsState} />
                                                <Tooltip title={t('popup.photoToolTip')} arrow>
                                                    <IconButton color='secondary' variant="contained" onClick={() => {
                                                        dispatchPopup({ type: 'OPEN', payload: "anex" })
                                                    }} children={<HelpOutlineIcon sx={{ fontSize: 30 }} />} />
                                                </Tooltip>


                                            </Box>
                                            {schoolData.annexes ?
                                                (filesInfo?.map((element, index) => {
                                                    return (
                                                        <React.Fragment key={element.attachmentInfo.typeId}>
                                                            {element.attachmentInfo.typeId === 49 && schoolData.annexes === true && schoolData.certificate === 'NEW' ?
                                                                FileUploadComponent(index, element, dispatchFilesInfo, dispatchPopup, "anexFile", readOnly) : null}
                                                        </React.Fragment>
                                                    )
                                                }))
                                                : null}
                                        </>
                                        : null}
                                </Grid>
                            </Grid> : null}

                        {schoolData.certificateOwnership === true && schoolData.certificate !== 'OTHER' && schoolData.certificate ?
                            <Grid style={{
                                width: '100%',
                                textAlign: 'left',
                                marginBottom: '2vh',
                                marginTop: '2vh',
                                paddingLeft: '1.5vw'
                            }}>
                                <Typography fontWeight='bold' variant='h2'>{t('school.maturaResults')}</Typography>

                            </Grid> : null}
                        {/* POLA DLA NOWEJ MATURY */}
                        {schoolData.certificateOwnership === true && schoolData.certificate === 'NEW' ?
                            <Grid style={{ width: '100%' }}>
                                <Grid style={{
                                    width: '100%',
                                    textAlign: 'left',
                                    marginBottom: '2vh',
                                    paddingLeft: '1.5vw'
                                }}>
                                    <Typography variant='h6'>{t('school.gradesNew')}</Typography>
                                    <Typography variant='h6'>{t('school.gradesCaption')}</Typography>
                                </Grid>
                                {gradesNew.map((element, i) => {
                                    return (
                                        <Box key={i} sx={{
                                            display: 'flex',
                                            width: '100%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: isMobile ? 'column' : 'row'
                                        }}>
                                            <CustomSelectField
                                                editState={readOnly || (element.constant && !element.language)}
                                                id={'subject' + i} name="subject"
                                                title={'school.subject'}
                                                options={(element.language ? languageSubjects : subjects)} //TODO make seperate languade and seperate normal subjects
                                                value={element.subject}
                                                changeHandler={changeNewGradesHandler} required={true} />
                                            <CustomSelectField editState={readOnly || element.constant} id={'level' + i}
                                                name="level"
                                                title={'school.level'}
                                                options={(languageSubjects.some(obj => JSON.stringify(obj) === JSON.stringify(element.subject)) ? levels : levelsNoLanguages)}
                                                value={element.level}
                                                changeHandler={changeNewGradesHandler} required={true} />
                                            <CustomGridField disabledState={readOnly} name={'grade' + i}
                                                title={'school.result'} label={t('school.result')}
                                                value={element.grade}
                                                changeHandler={changeNewGradesHandler} capital={true}
                                                dataType='number' required={true} />
                                            {gradesNew.length > 1 && !element.unDeletable ?
                                                <IconButton disabled={readOnly} id={'button' + i} color="secondary"
                                                    onClick={() => handleDeleteGrade(i, gradesNew, setGradesNew)}>
                                                    <DeleteIcon />
                                                </IconButton> :
                                                <DeleteIcon sx={{ padding: 2.5 }} color='secondary' />
                                            }
                                        </Box>
                                    )
                                })}
                                {gradesNew.length < maxAamountOfGrades ?
                                    <Grid style={{
                                        width: '100%',
                                        textAlign: 'left',
                                        marginBottom: '2vh',
                                        paddingLeft: '1.5vw'
                                    }}>
                                        <Button
                                            sx={{
                                                width: '5%',
                                                minWidth: 200,
                                                color: theme.palette.primary.main,
                                                backgroundColor: theme.palette.secondary.main,
                                                '&:hover':
                                                {
                                                    backgroundColor: theme.palette.secondary.hovered,
                                                    borderColor: 'none', boxShadow: 'none',
                                                },
                                                '&.Mui-disabled': {
                                                    backgroundColor: theme.palette.additional.disabled,
                                                }
                                            }
                                            }
                                            disabled={readOnly}
                                            onClick={() => handleAddGrade(gradesNew, setGradesNew)}
                                            variant='standard'>
                                            {t('school.addGrade')}
                                        </Button>
                                    </Grid>
                                    : null}
                                {filesInfo?.map((element, index) => {
                                    return (
                                        <React.Fragment key={element.attachmentInfo.typeId}>
                                            {element.attachmentInfo.typeId === 1 ?
                                                FileUploadComponent(index, getFileWithCertificateCaption(element), dispatchFilesInfo, dispatchPopup, schoolData.certificate + "certificateFile", readOnly) : null}
                                        </React.Fragment>
                                    )
                                })}

                            </Grid> : null}
                        {/* POLA DLA STAREJ MATURY */}
                        {schoolData.certificateOwnership === true && schoolData.certificate === 'OLD' ?
                            <Grid style={{ width: '100%' }}>
                                <Grid style={{
                                    width: '100%',
                                    textAlign: 'left',
                                    marginBottom: '2vh',
                                    paddingLeft: '1.5vw'
                                }}>
                                    <Typography variant='h6'>{t('school.gradesOld')}</Typography>
                                    <Typography variant='h6'>{t('school.gradesCaption')}</Typography>
                                </Grid>
                                <Grid sx={{ width: isMobile ? '100%' : '50%' }}>
                                    <Box style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center'
                                    }}>
                                        <CustomSelectField editState={readOnly} id={'gradeScale'} name="gradeScale"
                                            title={'school.gradeScale'} editState={true}
                                            options={gradeScalesOptions}
                                            value={schoolData.gradeScale}
                                            changeHandler={changeDataHandler} required={true} />
                                        <Tooltip title={t('popup.photoToolTip')} arrow>
                                            <IconButton disabled={readOnly} color='secondary' variant="contained"
                                                onClick={() => {
                                                    dispatchPopup({ type: 'OPEN', payload: "gradeScale" })
                                                }} children={<HelpOutlineIcon sx={{ fontSize: 30 }} />} />
                                        </Tooltip>
                                    </Box>
                                </Grid>
                                {schoolData.gradeScale ? gradesOld.map((element, i) => {
                                    return (
                                        <Box key={i} sx={{
                                            display: 'flex',
                                            width: '100%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: isMobile ? 'column' : 'row'
                                        }}>
                                            <CustomSelectField editState={readOnly} id={'subject' + i} name="subject"
                                                title={'school.subject'} options={subjects}
                                                value={element.subject}
                                                changeHandler={changeOldGradesHandler} required={true} />
                                            <CustomSelectField editState={readOnly} id={'level' + i} name="level"
                                                title={'school.examType'} options={levelsOld}
                                                value={element.level}
                                                changeHandler={changeOldGradesHandler} required={true} />
                                            <CustomSelectField editState={readOnly} id={'grade' + i} name="grade"
                                                title={'school.grade'}
                                                options={schoolData.gradeScale?.label === '1-6' ? gradesList : gradesOldList}
                                                value={element.grade}
                                                changeHandler={changeOldGradesHandler} required={true} />
                                            {gradesOld.length > 1 ?
                                                <IconButton disabled={readOnly} id={'button' + i} color="secondary"
                                                    onClick={() => handleDeleteGrade(i, gradesOld, setGradesOld)}>
                                                    <DeleteIcon />
                                                </IconButton> :
                                                <DeleteIcon sx={{ padding: 2.5 }} color='secondary' />
                                            }
                                        </Box>
                                    )
                                }) : <div />}
                                {schoolData.gradeScale && gradesOld.length < maxAamountOfGrades ?
                                    <Grid style={{
                                        width: '100%',
                                        textAlign: 'left',
                                        marginBottom: 25,
                                        paddingLeft: '1.5vw'
                                    }}>
                                        <Button
                                            sx={{
                                                width: '5%',
                                                minWidth: 200,
                                                color: theme.palette.primary.main,
                                                backgroundColor: theme.palette.secondary.main,
                                                '&:hover':
                                                {
                                                    backgroundColor: theme.palette.secondary.hovered,
                                                    borderColor: 'none', boxShadow: 'none',
                                                },
                                                '&.Mui-disabled': {
                                                    backgroundColor: theme.palette.additional.disabled,
                                                }
                                            }
                                            }
                                            disabled={readOnly}
                                            onClick={() => handleAddGrade(gradesOld, setGradesOld)}
                                            variant='standard'>
                                            {t('school.addGrade')}
                                        </Button>
                                    </Grid> : <div />}
                                {filesInfo?.map((element, index) => {
                                    return (
                                        <React.Fragment key={element.attachmentInfo.typeId}>
                                            {element.attachmentInfo.typeId === 1 ?
                                                FileUploadComponent(index, getFileWithCertificateCaption(element), dispatchFilesInfo, dispatchPopup, schoolData.certificate + "certificateFile", readOnly) : null}
                                        </React.Fragment>
                                    )
                                })}
                            </Grid> : null}
                        {/* POLA DLA IB */}
                        {schoolData.certificateOwnership === true && schoolData.certificate === 'IB' ?
                            <Grid style={{ width: '100%' }}>
                                <Grid
                                    style={{ width: '100%', textAlign: 'left', marginBottom: 25, paddingLeft: '1.5vw' }}>
                                    <Typography variant='h6'>{t('school.gradesIB')}</Typography>
                                    <Typography variant='h6'>{t('school.gradesCaption')}</Typography>
                                </Grid>
                                <CustomRadioGroup disabledState={readOnly} name={`diplomaIB`}
                                    value={schoolData.diplomaIB} radioData={diplomaIBRadios}
                                    changeHandler={changeDataHandler} row={true} />
                                {gradesIB.map((element, i) => {
                                    return (
                                        <Box key={i} sx={{
                                            display: 'flex',
                                            width: '100%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: isMobile ? 'column' : 'row'
                                        }}>
                                            <CustomSelectField editState={readOnly} id={'subject' + i} name="subject"
                                                title={'school.subject'} options={subjects}
                                                value={element.subject}
                                                changeHandler={changeIBGradesHandler} required={true} />
                                            <CustomSelectField editState={readOnly} id={'level' + i} name="level"
                                                title={'school.level'} options={levelsIB}
                                                value={element.level}
                                                changeHandler={changeIBGradesHandler} required={true} />
                                            <CustomSelectField editState={readOnly} id={'grade' + i} name="grade"
                                                title={'school.grade'} options={gradesListIB}
                                                value={element.grade}
                                                changeHandler={changeIBGradesHandler} required={true} />
                                            {gradesIB.length > 1 ?
                                                <IconButton disabled={readOnly} id={'button' + i} color="secondary"
                                                    onClick={() => handleDeleteGrade(i, gradesIB, setGradesIB)}>
                                                    <DeleteIcon />
                                                </IconButton> :
                                                <DeleteIcon sx={{ padding: 2.5 }} color='secondary' />
                                            }
                                        </Box>
                                    )
                                })}
                                {gradesIB.length < maxAamountOfGrades ?
                                    <Grid style={{
                                        width: '100%',
                                        textAlign: 'left',
                                        marginBottom: 25,
                                        paddingLeft: '1.5vw'
                                    }}>
                                        <Button
                                            sx={{
                                                width: '5%',
                                                minWidth: 200,
                                                color: theme.palette.primary.main,
                                                backgroundColor: theme.palette.secondary.main,
                                                '&:hover':
                                                {
                                                    backgroundColor: theme.palette.secondary.hovered,
                                                    borderColor: 'none', boxShadow: 'none',
                                                },
                                                '&.Mui-disabled': {
                                                    backgroundColor: theme.palette.additional.disabled,
                                                }
                                            }
                                            }
                                            disabled={readOnly}
                                            onClick={() => handleAddGrade(gradesIB, setGradesIB)}
                                            variant='standard'>
                                            {t('school.addGrade')}
                                        </Button>
                                    </Grid>
                                    : null}
                                {filesInfo?.map((element, index) => {
                                    return (
                                        <React.Fragment key={element.attachmentInfo.typeId}>
                                            {element.attachmentInfo.typeId === 1 ?
                                                FileUploadComponent(index, getFileWithCertificateCaption(element), dispatchFilesInfo, dispatchPopup, schoolData.certificate + "certificateFile", readOnly) : null}
                                        </React.Fragment>
                                    )
                                })}
                            </Grid> : null}
                        {/* POLA DLA EB */}
                        {schoolData.certificateOwnership === true && schoolData.certificate === 'EB' ?
                            <Grid style={{ width: '100%' }}>
                                <Grid
                                    style={{ width: '100%', textAlign: 'left', marginBottom: 25, paddingLeft: '1.5vw' }}>
                                    <Typography variant='h6'>{t('school.gradesEB')}</Typography>
                                    <Typography variant='h6'>{t('school.gradesCaption')}</Typography>
                                </Grid>
                                <CustomRadioGroup disabledState={readOnly} name={`diplomaEB`}
                                    value={schoolData.diplomaEB} radioData={diplomaEBRadios}
                                    changeHandler={changeDataHandler} row={true} />
                                {gradesEB.map((element, i) => {
                                    return (
                                        <Box key={i} sx={{
                                            display: 'flex',
                                            width: '100%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: isMobile ? 'column' : 'row'
                                        }}>
                                            <CustomSelectField editState={readOnly} id={'subject' + i} name="subject"
                                                title={'school.subject'} options={subjects}
                                                value={element.subject}
                                                changeHandler={changeEBGradesHandler} required={true} />
                                            <CustomSelectField editState={readOnly} id={'level' + i} name="level"
                                                title={'school.level'} options={levelsEB}
                                                value={element.level}
                                                changeHandler={changeEBGradesHandler} required={true} />
                                            {/* <CustomSelectField id={'grade'+i} name="grade" title={t('school.grade')} options={gradesList} value={element.grade} changeHandler={changeEBGradesHandler} required={true}/>    */}
                                            <CustomGridField disabledState={readOnly} name={'grade' + i}
                                                title={t('school.grade')} label={t('school.grade')}
                                                value={element.grade} changeHandler={changeEBGradesHandler}
                                                capital={true} required={true} dataType='number'
                                                step='0.01' />

                                            {gradesEB.length > 1 ?
                                                <IconButton disabled={readOnly} id={'button' + i} color="secondary"
                                                    onClick={() => handleDeleteGrade(i, gradesEB, setGradesEB)}>
                                                    <DeleteIcon />
                                                </IconButton> :
                                                <DeleteIcon sx={{ padding: 2.5 }} color='secondary' />
                                            }
                                        </Box>
                                    )
                                })}
                                {gradesEB.length < maxAamountOfGrades ?
                                    <Grid style={{
                                        width: '100%',
                                        textAlign: 'left',
                                        marginBottom: '2vh',
                                        paddingLeft: '1.5vw'
                                    }}>
                                        <Button
                                            sx={{
                                                width: '5%',
                                                minWidth: 200,
                                                color: theme.palette.primary.main,
                                                backgroundColor: theme.palette.secondary.main,
                                                '&:hover':
                                                {
                                                    backgroundColor: theme.palette.secondary.hovered,
                                                    borderColor: 'none', boxShadow: 'none',
                                                },
                                                '&.Mui-disabled': {
                                                    backgroundColor: theme.palette.additional.disabled,
                                                }
                                            }
                                            }
                                            disabled={readOnly}
                                            onClick={() => handleAddGrade(gradesEB, setGradesEB)}
                                            variant='standard'>
                                            {t('school.addGrade')}
                                        </Button>
                                    </Grid> : null}
                                {filesInfo?.map((element, index) => {
                                    return (
                                        <React.Fragment key={element.attachmentInfo.typeId}>
                                            {element.attachmentInfo.typeId === 1 ?
                                                FileUploadComponent(index, getFileWithCertificateCaption(element), dispatchFilesInfo, dispatchPopup, schoolData.certificate + "certificateFile", readOnly) : null}

                                        </React.Fragment>
                                    )
                                })}
                            </Grid> : null}

                        {schoolData.certificateOwnership === true && schoolData.certificate === 'OTHER' ?

                            (filesInfo?.map((element, index) => {
                                return (
                                    <React.Fragment key={element.attachmentInfo.typeId}>
                                        {element.attachmentInfo.typeId === 1 ?
                                            FileUploadComponent(index, element, dispatchFilesInfo, dispatchPopup, schoolData.certificate + "certificateFile", readOnly) : null}
                                        {element.attachmentInfo.typeId === 10 ?
                                            FileUploadComponent(index, element, dispatchFilesInfo, dispatchPopup, "otherExtraFile1", readOnly) : null}
                                        {element.attachmentInfo.typeId === 11 ?
                                            FileUploadComponent(index, element, dispatchFilesInfo, dispatchPopup, "otherExtraFile2", readOnly) : null}
                                        {element.attachmentInfo.typeId === 12 ?
                                            FileUploadComponent(index, element, dispatchFilesInfo, dispatchPopup, "otherExtraFile3", readOnly) : null}
                                        {element.attachmentInfo.typeId === 13 ?
                                            FileUploadComponent(index, element, dispatchFilesInfo, dispatchPopup, "otherExtraFile4", readOnly) : null}
                                    </React.Fragment>
                                )
                            }))
                            : null}


                        {/*Egzamin językowy */}
                        {candidateType === 'ABROAD' ?
                            <>
                                <Grid style={{
                                    width: '100%',
                                    textAlign: 'left',
                                    marginBottom: '2vh',
                                    marginTop: '2vh',
                                    paddingLeft: '1.5vw'
                                }}>
                                    <Typography fontWeight='bold'
                                        variant='h2'>{t('school.languageCertificate')}</Typography>
                                </Grid>
                                <CustomCheckboxField disabledState={readOnly} name="languageCertificate"
                                    title={t('school.languageCertificateCheckbox')}
                                    label={t('school.languageCertificate')}
                                    value={schoolData.languageCertificate}
                                    changeHandler={changeDataHandler}
                                    className={classes.daneOsoboweGridField} />
                                {schoolData.languageCertificate ?
                                    <>
                                        {filesInfo?.map((element, index) => {
                                            return (
                                                <React.Fragment key={element.attachmentInfo.typeId}>
                                                    {(element.attachmentInfo.typeId === 54 || element.attachmentInfo.typeId === 55) ?
                                                        FileUploadComponent(index, element, dispatchFilesInfo, dispatchPopup, element.attachmentInfo.typeId, readOnly) : null}
                                                </React.Fragment>
                                            )
                                        })}
                                    </> : null}
                                <Grid style={{
                                    width: '100%',
                                    textAlign: 'left',
                                    marginBottom: '2vh',
                                    marginTop: '2vh',
                                    paddingLeft: '1.5vw'
                                }}>
                                    <Typography fontWeight='bold' variant='h2'>{t('school.languageExam')}</Typography>
                                </Grid>
                                <CustomCheckboxField disabledState={readOnly} name="languageExam"
                                    title={t('school.languageExamCheckbox')}
                                    label={t('school.languageExamCheckbox')}
                                    value={schoolData.languageExam} changeHandler={changeDataHandler}
                                    className={classes.daneOsoboweGridField} />
                                {schoolData.languageExam ?
                                    <>

                                        <Grid container direction='row' justifyContent='space-between'>
                                            <Box style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                textAlign: 'left',
                                                alignItems: isMobile ? 'flex-start' : 'center',
                                                paddingLeft: '1.5vw',
                                                flexDirection: isMobile ? 'column' : 'row'
                                            }}>
                                                <Typography style={{ marginBottom: '0.5vh' }}
                                                    variant='h6'>{t('school.languageExamLanguageChoice')}</Typography>
                                            </Box>
                                            <Box style={{ width: isMobile ? '100%' : '50%', alignItems: 'center' }}>
                                                <FormControl
                                                    required
                                                    errors={errorsState}
                                                    name="lang"
                                                    component="fieldset"
                                                    sx={{ m: 3, width: '100%' }}
                                                    variant="standard"
                                                >
                                                    {/* <FormLabel component="legend" sx={{ textAlign: 'left' }}>Zaznacz minimum 1 opcję</FormLabel> */}
                                                    <FormGroup sx={{ flexDirection: 'row' }}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox disabled={readOnly}
                                                                    checked={schoolData.languageExamPl}
                                                                    onChange={changeDataHandler}
                                                                    name="languageExamPl" sx={{
                                                                        color: '#30376c',
                                                                        '&.Mui-checked': { color: '#30376c' },
                                                                    }} />
                                                            }
                                                            label={t('school.languagePL')}
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox disabled={readOnly}
                                                                    checked={schoolData.languageExamEn}
                                                                    onChange={changeDataHandler}
                                                                    name="languageExamEn" sx={{
                                                                        color: '#30376c',
                                                                        '&.Mui-checked': { color: '#30376c' },
                                                                    }} />
                                                            }
                                                            label={t('school.languageEN')}
                                                        />
                                                    </FormGroup>
                                                    <FormHelperText
                                                        className={"btn-group pull-right " + (langError ? 'errorShow' : 'errorHidden')}>{t('school.langSelectionError')}</FormHelperText>
                                                </FormControl>
                                            </Box>
                                        </Grid>

                                        <Grid container direction='row' justifyContent='space-between'>
                                            <Box style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                textAlign: 'left',
                                                alignItems: isMobile ? 'flex-start' : 'center',
                                                paddingLeft: '1.5vw',
                                                flexDirection: isMobile ? 'column' : 'row'
                                            }}>
                                                <Typography style={{ marginBottom: '0.5vh' }}
                                                    variant='h6'>{t('school.languageExamEmailCaption')}</Typography>
                                            </Box>
                                            <Box style={{ width: isMobile ? '100%' : '50%', alignItems: 'center' }}>
                                                <CustomGridField disabledState={readOnly} name="languageExamEmail"
                                                    title={t('school.languageExamEmail')}
                                                    label={t('school.languageExamEmail')}
                                                    value={schoolData.languageExamEmail}
                                                    changeHandler={changeDataHandler} disabledState={true}
                                                    capital={false} required={true} />
                                            </Box>
                                        </Grid>

                                        {/*TODO uncomment for next recrutation  
                                        <Grid container direction='row' justifyContent='space-between'>
                                            <Box style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                textAlign: 'left',
                                                alignItems: isMobile ? 'flex-start' : 'center',
                                                paddingLeft: '1.5vw',
                                                flexDirection: isMobile ? 'column' : 'row'
                                            }}>
                                                <Typography style={{marginBottom: '0.5vh'}}
                                                            variant='h6'>{t('school.skypeCaption')}</Typography>
                                            </Box>
                                            <Box style={{width: isMobile ? '100%' : '50%', alignItems: 'center'}}>
                                                <CustomGridField disabledState={readOnly} name="skype"
                                                                 title={t('school.skype')} label={t('school.skype')}
                                                                 value={schoolData.skype}
                                                                 changeHandler={changeDataHandler} capital={false}
                                                                 required={true} errors={errorsState}/>
                                            </Box>
                                        </Grid>
                                           */}
                                        {/*{filesInfo?.map((element, index) => {
                                            return (
                                                <React.Fragment key={element.attachmentInfo.typeId}>
                                                    {element.attachmentInfo.typeId === 4 ?
                                                        FileUploadComponent(index, element, dispatchFilesInfo, dispatchPopup, "payment", readOnly) : null}
                                                </React.Fragment>
                                            )
                                        })} */}
                                    </> : null}
                                {abroadSelection === 9 ?
                                    <>
                                        <Grid style={{
                                            width: '100%',
                                            textAlign: 'left',
                                            marginBottom: '2vh',
                                            marginTop: '2vh',
                                            paddingLeft: '1.5vw'
                                        }}>
                                            <Typography fontWeight='bold'
                                                variant='h2'>{t('school.contestTitle')}</Typography>
                                        </Grid>
                                        <CustomCheckboxField disabledState={readOnly} name="contest"
                                            title={t('school.contest')} label={t('school.contest')}
                                            value={schoolData.contest}
                                            changeHandler={changeDataHandler}
                                            className={classes.daneOsoboweGridField} />
                                        {schoolData.contest ?
                                            <>
                                                {filesInfo?.map((element, index) => {
                                                    return (
                                                        <React.Fragment key={element.attachmentInfo.typeId}>
                                                            {element.attachmentInfo.typeId === 5 ?
                                                                FileUploadComponent(index, element, dispatchFilesInfo, dispatchPopup, "podanie", readOnly) : null}
                                                            {element.attachmentInfo.typeId === 9 ?
                                                                FileUploadComponent(index, element, dispatchFilesInfo, dispatchPopup, "dokumentacja", readOnly) : null}
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </> : null}
                                    </> : null}
                            </> : null}

                        {/*egzaminy prowadzone przez Politechnikę Łódzką*/}
                        {schoolData.certificate == 'OTHER' ?
                            <Grid style={{ width: '100%' }}>
                                <Grid
                                    container
                                    direction="column"
                                    style={{ width: '100%', textAlign: 'left', marginBottom: 5, paddingLeft: '1.5vw' }}>
                                    <Typography style={{ marginBottom: '0.5vh' }} fontWeight='bold'
                                        variant='h2'>{t('school.examsPL')}</Typography>
                                    <Typography style={{ marginBottom: '0.5vh' }}
                                        variant='h3'> {t('school.examsPLExplanation')}</Typography>
                                    <Grid direction={"row"}>
                                        <Typography style={{ marginBottom: '0.5vh', display: 'inline' }}
                                            variant='h3'> {t('school.examsPLSelection')}</Typography>
                                        <Typography style={{ marginBottom: '0.5vh', display: 'inline' }} variant='h3'>
                                            <Link onClick={(e) => {
                                                e.preventDefault();
                                                window.open("https://rekrutacja.p.lodz.pl/rekrutacja/rekrutuj/dodatkowe-informacje", '_blank', 'noopener,noreferrer');
                                            }} sx={{
                                                textDecoration: 'none',
                                                color: theme.palette.secondary.clicked
                                            }}>{t('school.examsPLLink')}</Link> </Typography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    direction="column"
                                    style={{ width: '100%', textAlign: 'left', marginBottom: 5, paddingLeft: '1.5vw' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox disabled={readOnly}
                                                checked={schoolData.mathPod}
                                                onChange={changeDataHandler}
                                                name="mathPod" sx={{
                                                    color: '#30376c',
                                                    '&.Mui-checked': { color: '#30376c' },
                                                }} />
                                        }
                                        label={t('school.examsPLTypes.mathPod')}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox disabled={readOnly}
                                                checked={schoolData.mathRoz}
                                                onChange={changeDataHandler}
                                                name="mathRoz" sx={{
                                                    color: '#30376c',
                                                    '&.Mui-checked': { color: '#30376c' },
                                                }} />
                                        }
                                        label={t('school.examsPLTypes.mathRoz')}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox disabled={readOnly}
                                                checked={schoolData.fizPod}
                                                onChange={changeDataHandler}
                                                name="fizPod" sx={{
                                                    color: '#30376c',
                                                    '&.Mui-checked': { color: '#30376c' },
                                                }} />
                                        }
                                        label={t('school.examsPLTypes.fizPod')}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox disabled={readOnly}
                                                checked={schoolData.fizRoz}
                                                onChange={changeDataHandler}
                                                name="fizRoz" sx={{
                                                    color: '#30376c',
                                                    '&.Mui-checked': { color: '#30376c' },
                                                }} />
                                        }
                                        label={t('school.examsPLTypes.fizRoz')}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox disabled={readOnly}
                                                checked={schoolData.chemPod}
                                                onChange={changeDataHandler}
                                                name="chemPod" sx={{
                                                    color: '#30376c',
                                                    '&.Mui-checked': { color: '#30376c' },
                                                }} />
                                        }
                                        label={t('school.examsPLTypes.chemPod')}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox disabled={readOnly}
                                                checked={schoolData.chemRoz}
                                                onChange={changeDataHandler}
                                                name="chemRoz" sx={{
                                                    color: '#30376c',
                                                    '&.Mui-checked': { color: '#30376c' },
                                                }} />
                                        }
                                        label={t('school.examsPLTypes.chemRoz')}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox disabled={readOnly}
                                                checked={schoolData.angPod}
                                                onChange={changeDataHandler}
                                                name="angPod" sx={{
                                                    color: '#30376c',
                                                    '&.Mui-checked': { color: '#30376c' },
                                                }} />
                                        }
                                        label={t('school.examsPLTypes.angPod')}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox disabled={readOnly}
                                                checked={schoolData.angRoz}
                                                onChange={changeDataHandler}
                                                name="angRoz" sx={{
                                                    color: '#30376c',
                                                    '&.Mui-checked': { color: '#30376c' },
                                                }} />
                                        }
                                        label={t('school.examsPLTypes.angRoz')}
                                    />
                                </Grid>
                            </Grid> : null}


                        {/* FILE UPLOAD 
                        <Grid style={{
                            width: '100%',
                            textAlign: 'left',
                            marginBottom: '2vh',
                            marginTop: '2vh',
                            paddingLeft: '1.5vw'
                        }}>
                            <Typography fontWeight='bold' variant='h2'>{t('school.requiredFiles')}</Typography>
                            <Typography variant='h6'>{t('school.filesNaming')}</Typography>
                        </Grid>

                        {filesInfo?.map((element, index) => {
                            return (
                            
                            )
                        })}
                        */}
                        <BackAndSaveButtons saveButtonFunction={handleOpenSaveConfirmDialog} loading={loading}
                                            readOnly={readOnly}/>
                    </Grid>
                </Box>
                {Popup(popup, dispatchPopup) /*popup*/}
            </ThemeProvider>
            {/* ------------------------DIALOGI------------------------ */}
            <DialogComponent
                title={t('default.confirmSave')}
                content={t('school.confirmSave')}
                visible={isDialogSaveOpen}
                buttons={[
                    { buttonLabel: t('default.cancel'), buttonAction: () => handleCloseSaveConfirmDialog() },
                    { buttonLabel: t('yes'), buttonAction: () => handleSave() }
                ]}
            />
        </>

    )
}

export function CustomGridField({
    label,
    value,
    disabledState = false,
    dataType = 'text',
    changeHandler,
    name,
    required,
    capital,
    mask,
    year,
    errors
}) {

    const classes = useStyles();
    if (value == null) {
        value = ''
    }

    return (
        <Grid item xs className={classes.daneOsoboweGridField}>
            {mask ?
                <InputMask
                    mask={year ? "9999" : "99999999999"}
                    value={value}
                    disabled={disabledState}
                    maskChar=" "
                    onChange={changeHandler}
                >
                    {inputProps => <TextField
                        {...inputProps}
                        className={(errors && errors !== [] && errors.find(errors => errors.id === name)) ? classes.customTextFieldError : classes.customTextField}
                        InputProps={{
                            classes: {
                                input: classes.resizeDaneOsobowe,
                            },
                        }}
                        variant="outlined"
                        label={label} name={name} required={required}
                    />}
                </InputMask>

                :
                <TextField
                    className={(errors && errors !== [] && errors.find(errors => errors.id === name)) ? classes.customTextFieldError : classes.customTextField}
                    type={dataType}
                    InputProps={{
                        classes: {
                            input: capital ? classes.resizeDaneOsoboweCapital : classes.resizeDaneOsobowe,
                        },
                    }}
                    variant="outlined"
                    label={label} value={value} name={name} onChange={changeHandler} disabled={disabledState}
                    required={required}
                />}
            <ErrorInfoGrid errors={errors} fieldName={name}></ErrorInfoGrid>
        </Grid>
    )
}