import * as React from "react";
import {useTranslation} from "react-i18next";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import StatusButton from "./StatusButton";
import {FR, GB, PL} from 'country-flag-icons/react/3x2'


export default function CustomSecondaryTable({tableData, candidateType, recruitmentModeKey, fetchRecruitmentResults, setLoadingCandidateCourses}) {

  const { t } = useTranslation();

  const langs = {
    PL: PL,
    EN: GB,
    FR: FR
  }
  
    return (
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead className="blue-header">
            <TableRow>
              <TableCell>{t('recruitment.language')}</TableCell>
              <TableCell>{t('recruitment.field_of_study')}</TableCell>
              <TableCell align="center">{t('recruitment.points')}</TableCell>
              <TableCell align="center">{t('recruitment.status')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row) => {
              var Lang = langs[row.lang];
              return (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{Lang && <Lang title={row.lang}style={{width: 50, border: "1px solid #ebecf0"}}/>} </TableCell>
                <TableCell>{row.fieldOfStudy}</TableCell>
                <TableCell align="center">{row.points}</TableCell>
                <TableCell align="center">{row.statusData.name}</TableCell>
                  <TableCell align="center">{row.statusData.buttonType &&
                      <StatusButton buttonType={row.statusData.buttonType} id={row.id} candidateType={candidateType}
                                    fieldOfStudy={row.fieldOfStudy} recruitmentModeKey={recruitmentModeKey}
                                    applicationId={row.applicationId} fetchRecruitmentResults={fetchRecruitmentResults}
                                    setLoadingCandidateCourses={setLoadingCandidateCourses}/>}</TableCell>
              </TableRow>
            )}
            )}
          </TableBody>
        </Table>
      </TableContainer>
    )

}