import React, {useEffect, useState} from 'react'
import 'react-phone-input-2/lib/style.css'
import {useLocation, useParams} from 'react-router-dom';
import SchoolFirstDegree from './SchoolFirstDegree';
import SchoolSecondDegree from './SchoolSecondDegree';
import SchoolThirdDegree from './SchoolThirdDegree';
import FetchService from '../../services/FetchService';

export default function School() {
    let {id} = useParams();
    const location = useLocation();
    const candidateType = location.state.candidateType
    let abroadSelection = location.state && location.state.abroadSelection;
    const [studyDegree, setStudyDegree] = useState(0);

    async function fetchGetRecruitmentMode(recruitmentModeKey) {
        return await FetchService.getRecruitmentMode(recruitmentModeKey)
    }

    async function fetchPersonalData() {
        return await FetchService.getAddressPersonal()
    }

    useEffect(() => {
        fetchGetRecruitmentMode(id).then(response => {
            if (response.zawartosc[0].recruitmentCandidateMode.key == id) {
                setStudyDegree(response.zawartosc[0].recruitmentCandidateMode.degree);

            } else {
                setStudyDegree(0);
            }
        })

        if (!abroadSelection) {
            fetchPersonalData().then(response => {
                abroadSelection = response?.zawartosc.userPersonalData.selectionId
            })
        }

    }, [])
    switch (studyDegree) {
        case 1:
            return (<SchoolFirstDegree candidateType={candidateType} degree={id} abroadSelection={abroadSelection}/>);
        case 2:
            return (<SchoolSecondDegree candidateType={candidateType} degree={id} abroadSelection={abroadSelection}/>);
        case 3:
            return (<SchoolThirdDegree degree={id}/>);
        default:
            console.log('Tryb rekrutacji nie istnieje');
    }
}
