import React from 'react'
import {Button, Grid, IconButton, Tooltip, Typography} from '@material-ui/core';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import 'react-phone-input-2/lib/style.css'
import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import i18n, {t} from 'i18next';
import {styled} from '@mui/material/styles';
import {useTheme} from '../css/Styles';
import handleFile from '../utils/handleFile';
import downloadFile from '../utils/downloadFile';
import FetchService from "../services/FetchService";


export default function FileUploadComponent(index, element, dispatchFiles, dispatchPopup, popupID, disabled = false) {


   let isDialogSaveOpen = false;
   const theme = useTheme;
   const buttonTheme = {
      secondary: {
         //Dark blue
         main: '#30376c',
         hovered: '#3e488e',
         clicked: '#4d5ab8',
      },
      rejected: {
         //red
         main: '#ab0202',
         hovered: '#d10202',

      },
      accepted: {
         //green
         main: '#038a07',
         hovered: '#02c208',

      },
      pending: {
         //green
         main: '#d0d61c',
         hovered: '#eef518',

      }
   };
   let currentTheme, currentThemeHover, fileState;

   try {
      //    console.table(element," aaa")
      fileState = (element.schoolAttachmentMetadataList[0].fileState);
      currentTheme = buttonTheme.secondary.main;
      currentThemeHover = buttonTheme.secondary.hovered;
      if(fileState === "ZAAKCEPTOWANO") {
         currentTheme = buttonTheme.accepted.main;
         currentThemeHover = buttonTheme.accepted.hovered;
      }
      else if(fileState === "ODRZUCONO") {
         currentTheme = buttonTheme.rejected.main;
         currentThemeHover = buttonTheme.rejected.hovered;
      }
      // else if(state === "PRZETWARZANY") {
      //    currentTheme = buttonTheme.pending.main;
      //    currentThemeHover = buttonTheme.pending.hovered;
      // }
   } catch (error) {
      fileState = ""
      currentTheme = buttonTheme.secondary.main;
      currentThemeHover = buttonTheme.secondary.hovered;
   }

   const BootstrapButton = styled(Button)({
      fontSize: 14,
      color: 'white',
      padding: '8px 12px',
      borderColor: 'none',
      lineHeight: 1,
      width: '5%',
      minWidth: 200,
      backgroundColor: currentTheme,
      fontFamily: [
         '-apple-system',
         'BlinkMacSystemFont',
         '"Segoe UI"',
         'Roboto',
         '"Helvetica Neue"',
         'Arial',
         'sans-serif',
         '"Apple Color Emoji"',
         '"Segoe UI Emoji"',
         '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
         backgroundColor: currentThemeHover,
         borderColor: 'none',
         boxShadow: 'none',
      },
      '&.Mui-disabled': {
         backgroundColor: theme.palette.additional.disabled,
      }
   });


   async function changeFileState(profileID, fileId, fileState, comment) {
      const inputData = {
         profileID: profileID,
         fileID: fileId,
         fileState: fileState,
         comment: comment
      };
      return await FetchService.postChangeFileState(inputData);
   }




   return (

      <Grid style={{ width: '100%', textAlign: 'left', marginBottom: '2vh', paddingLeft: '1.5vw' }} >
         <Typography style={{ marginBottom: '0.5vh' }} variant='h6'>{ i18n.language === 'pl' ? element.attachmentInfo.namePL : element.attachmentInfo.nameEN}</Typography>

         <input
            type="file"
            accept={element.attachmentInfo.extensions}
            ref={element.inputRefs[index]}
            onChange={(e) => { handleFile(e, element.attachmentInfo, dispatchFiles) }}
            style={{ display: 'none' }}
         />
         <BootstrapButton disabled={disabled} startIcon={<FileUploadIcon />} variant="standard" onClick={() => {
            if(fileState !== "ZAAKCEPTOWANO" && fileState !== "PRZETWARZANY")
               element.inputRefs[index].current.click();
         }
         }>{fileState === "ZAAKCEPTOWANO" ? t("acceptedFile") : fileState === "ODRZUCONO" ? t("rejectedFile") : t("uploadFile")}</BootstrapButton>
         {element.attachmentMetadataList[0]?.fileName || element.schoolAttachmentMetadataList.length !== 0 ?
            <Grid container direction='row' alignItems='center' style={{ marginTop: '0.5vh' }}>
               <Typography sx={{ marginRight: '0.5vw' }} variant='h6' fontWeight='bold'>{t('school.fileName')}</Typography>
               <Typography sx={{ marginRight: '2vw', wordBreak: 'break-all' }} variant='h6'>{element.attachmentMetadataList[0]?.fileName}</Typography>
               <Typography sx={{ marginRight: '0.5vw' }} variant='h6' fontWeight='bold'>{t('school.fileSize')}</Typography>
               <Typography sx={{ marginRight: '2vw', wordBreak: 'break-all' }} variant='h6'>{((element.attachmentMetadataList[0]?.size / 1000000).toFixed(2)).toString() + 'MB'}</Typography>
               <IconButton id={'downloadOccupationalFile'} color="secondary" onClick={() => { downloadFile(element) }}>
                  <DownloadIcon />
               </IconButton>
               {dispatchPopup && popupID?
                  <Tooltip title={t('popup.photoToolTip')} arrow>
                     <IconButton color='secondary' sx={{ marginLeft: '1vw'}} variant="contained" onClick={() => { dispatchPopup({ type: 'OPEN', payload: popupID }) }} children={<HelpOutlineIcon sx={{ fontSize: 30 }} />} />
                  </Tooltip> : null}
            </Grid> : null}
         {dispatchPopup && popupID && !(element.attachmentMetadataList[0]?.fileName || element.schoolAttachmentMetadataList.length !== 0) ?
            <Tooltip title={t('popup.photoToolTip')} arrow>
               <IconButton color='secondary' sx={{ marginLeft: '1vw'}} variant="contained" onClick={() => { dispatchPopup({ type: 'OPEN', payload: popupID }) }} children={<HelpOutlineIcon sx={{ fontSize: 30 }} />} />
            </Tooltip> : null}


      </Grid>
   )
}