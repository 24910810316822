import React from 'react';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

export default function LinearWithValueLabel({progress, max, current}) {
    return (
        <Grid container sx={{
            minWidth: '50px',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Grid item sx={{minWidth: '50px', width: '80%'}}>
                <LinearProgress sx={{height: 9}} color="secondary" variant="determinate" value={progress}/>
            </Grid>
            <Grid item sx={{marginLeft: "10px", minWidth: "20px"}}>
                <Typography variant="body2" sx={{color: 'text.secondary'}}>
                    {current}/{max}
                </Typography>
            </Grid>
        </Grid>
    );
}