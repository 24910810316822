import iconv from 'iconv-lite';

export function checkEncodingCompatibility(input) {
    const incompatibleChars = [];
    const encoding = "win1250";
    if (!input && typeof input !== 'string')
        return false
    for (const char of input) {
        try {
            // Encode the character
            const encoded = iconv.encode(char, encoding);

            // Decode it back to a string
            const decoded = iconv.decode(encoded, encoding);

            // Compare the original character with the decoded character
            if (char !== decoded) {
                incompatibleChars.push(char); // Add incompatible character to the list
            }
        } catch (error) {
            incompatibleChars.push(char); // Add character if encoding throws an error
        }
    }

    return incompatibleChars.length > 0 ? incompatibleChars : false;
}


export function checkCompatibilityInObject(obj) {
    const incompatibleChars = [];
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            let value = obj[key];

            // If the value is a string, test it
            if (typeof value === 'string') {
                let tmp = checkEncodingCompatibility(value);
                if (tmp) {
                    incompatibleChars.push(tmp);
                }
            }

            // If the value is an object, recurse
            else if (typeof value === 'object' && value !== null) {
                let tmp = checkCompatibilityInObject(value);
                if (tmp) {
                    incompatibleChars.push(tmp);
                }
            }
        }
    }


    return incompatibleChars.length > 0 ? incompatibleChars : false;
}

function containsCyrillic(text) {
    return /[а-яА-ЯЁё]/.test(text);
}

function containsChinese(text) {
    return /[а-яА-ЯЁё]/.test(text);
}

function containsJapanese(text) {
    return /[а-яА-ЯЁё]/.test(text);
}


export function checkCyrillicInObject(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            let value = obj[key];

            // If the value is a string, test it
            if (typeof value === 'string' && containsCyrillic(value)) {
                return true;
            }

            // If the value is an object, recurse
            else if (typeof value === 'object' && value !== null) {
                if (checkCyrillicInObject(value)) {
                    return true;
                }
            }
        }
    }

    // If nothing was found
    return false;
}

export function checkChineseInObject(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            let value = obj[key];

            // If the value is a string, test it
            if (typeof value === 'string' && containsChinese(value)) {
                return true;
            }

            // If the value is an object, recurse
            else if (typeof value === 'object' && value !== null) {
                if (checkCyrillicInObject(value)) {
                    return true;
                }
            }
        }
    }

    // If nothing was found
    return false;
}

export function checkJapaneseInObject(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            let value = obj[key];

            // If the value is a string, test it
            if (typeof value === 'string' && containsJapanese(value)) {
                return true;
            }

            // If the value is an object, recurse
            else if (typeof value === 'object' && value !== null) {
                if (checkCyrillicInObject(value)) {
                    return true;
                }
            }
        }
    }

    // If nothing was found
    return false;
}


