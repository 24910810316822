import {Grid} from '@material-ui/core';
import {useStyles} from '../css/Styles';
import {useTranslation} from 'react-i18next';


export default function ErrorInfoGrid({errors, fieldName}){
   const classes = useStyles();
   const { t } = useTranslation();
   let extraErrors = (errors && errors.find(errors => errors.id === fieldName)) && errors.find(errors => errors.id === fieldName)?.extraValue?.join(', ') ? errors.find(errors => errors.id === fieldName)?.extraValue?.join(', ') : ""
   return (
      <Grid className={classes.errorGridField}>
         {
            (errors && errors.find(errors => errors.id === fieldName)) ?
                t(errors.find(errors => errors.id === fieldName).value) + extraErrors
            : ''
         }
      </Grid> 
   )
}