export const photoValidationRules = {
  fileMaxCount: 1, // number
  fileMaxSize: 1800000, // bytes -> ~2MB
  fileExtention: {
    // trzeba zrobić image/* ze względu na ograniczenia biblioteki, jesli każdy MIME Type był osobno to po kliknięciu pokazywało się tylko pierwsze rozszerzenie od góry
    'image/*': ['.png', '.jpg', '.jpeg'],
  },
  aspectRatio: 0.7772511848341232, // 492/633 - proporcje dla zjęcia legitymacyjnego
};

export const photoValidationRulesApi = {
  sizeMin: null, // bytes
  sizeMax: photoValidationRules.fileMaxSize, // bytes
  heightMin: 633, // pixels
  heightMax: null, // pixels
  widthMin: 492, // pixels
  widthMax: null, // pixels
  aspectRatio: photoValidationRules.aspectRatio, // 8/10
  aspectRatioAccuracy: 0.05, // dokładność proporcji | aspectRatio - aspectRatioAccuracy -> jakie proporcje przejdą walidacje
};
