import React, {useEffect} from 'react';
import {Button, Grid, ThemeProvider, useMediaQuery} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {styled} from '@mui/material/styles';
import {useStyles, useTheme} from '../../css/Styles'

import TodoStage from './TodoStage';
import FetchService from "../../services/FetchService";
import {NavLink} from "react-router-dom";
import {getCredentials} from "../../services/UserDataService";
import {PagesEnums} from "../../utils/CustomEnums";
import {Skeleton} from "@mui/material";

function TodoList(props) {
    const theme = useTheme;
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('phone'))
    const classes = useStyles();

    const [expanded, setExpanded] = React.useState(false);
    const [todoData, setTodoData] = React.useState([]);

    function relaodPage() {
        FetchService.getTodoList().then(response => {
            const updatedResponse = response.map(stage => {
                var max = stage.toDoList.length;
                var current = stage.toDoList.filter(task => task["isDone"] === true).length;
                var progress = current / max;
                return {
                    isActiveMode: stage.isActiveMode,
                    tryb: stage.tryb,
                    status: stage.status,
                    toDoList: stage.toDoList.sort((a, b) => a.order - b.order),
                    progress: progress * 100,
                    current: current,
                    max: max
                };
            });
            setTodoData(updatedResponse);
        });
    }

    useEffect(() => {
        relaodPage()
    }, []);


    const handleIsModeActiveChange = (mode) => {
        const updatedTodoData = todoData.map(stage => {
            if (stage.tryb === mode) {
                return {
                    ...stage,
                    isActiveMode: !stage.isActiveMode
                };
            }
            return stage;
        });
        setTodoData(updatedTodoData);
    };

    const handleExpanded = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const BootstrapButton = styled(Button)({
        // boxShadow: 'none',
        // textTransform: 'none',
        fontSize: 15,
        color: 'white',
        // border: '1px solid',
        borderColor: 'none',
        lineHeight: 1,
        minHeight: 60,
        backgroundColor: '#30376c',
        fontFamily: [
            "Arial",
            "sans-serif"
        ].join(','),
        '&:hover': {
            backgroundColor: theme.palette.secondary.hovered,
            borderColor: 'none',
            boxShadow: 'none',
        },
        // '&:active': {
        //   boxShadow: 'none',
        //   backgroundColor: '#f2f2f2',
        //   borderColor: 'none',
        // },
        // '&:focus': {
        //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        // },
    });

    return (
        <ThemeProvider theme={theme}>
            <div style={{ width: '100%', height: 'auto', textAlign: 'center' }}>
                <Grid
                    container
                    sx={{width: '100%', height: '100%', margin: 'auto'}}
                    alignItems='center'
                    justifyContent='center'
                    direction='column'
                >
                    <Grid item style={{minWidth: '50%', marginTop: '20px', marginBottom: '20px'}}>

                        {todoData.length > 0 ? (
                            todoData.map((stage, index) => (
                                <TodoStage
                                    reloadPage={relaodPage}
                                    key={index}
                                    expanded={expanded}
                                    handleIsModeActiveChange={handleIsModeActiveChange}
                                    stageData={stage}
                                />
                            ))
                        ) : (
                            <Skeleton height={600} variant="rectangular"/>
                        )}
                    </Grid>
                    <Grid container style={{width: '100%', height: 'auto'}}
                          justifyContent={isMobile ? "center" : "end"}>
                        <Button
                            sx={{
                                fontWeight: 'bold',
                                margin: 1,
                                color: theme.palette.primary.main,
                                backgroundColor: theme.palette.secondary.main,
                                '&:hover':
                                    {
                                        backgroundColor: theme.palette.secondary.hovered,
                                        borderColor: 'none', boxShadow: 'none',
                                    },
                            }
                            }
                            component={NavLink}
                            to={getCredentials() ? PagesEnums.MAIN.link : PagesEnums.HOME.link}
                            variant='contained'>
                            {t('backButton')}
                        </Button>
                    </Grid>
                </Grid>

            </div>
        </ThemeProvider>
    );
}

export default TodoList
