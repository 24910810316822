import { createNotificationForResponse} from '../../utils/Notifications';
import { apiGatewayUrl as ApiUrl, SIDapiGatewayUrl as SidApiUrl, HTTP_GET, HTTP_POST } from '../../utils/Consts';
import AuthHeader from '../../services/AuthHeader';
import { getIdWithoutPrefix } from '../../services/UserDataService';
const URL = ApiUrl;
const SIDURL = SidApiUrl;
class RecruitmentResultsFetchService {   
    getRecruitmentResults = async (recruitmentModeKey) => {
        const response = await fetch(URL + 'qualification/qualificationData/' + getIdWithoutPrefix() + '/' + recruitmentModeKey, {
            method: HTTP_GET,
            headers: AuthHeader()
        });
        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };

    getRecruitmentMode = async (recruitmentModeKey) => {
        const response = await fetch(URL + 'getChosenRecruitmentModes/' + getIdWithoutPrefix() + '/' + recruitmentModeKey, {
            method: HTTP_GET,
            headers: AuthHeader()
        });
        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };

    getAddressPersonal = async () => {
        const response = await fetch(URL + 'addressPersonal', {
            method: HTTP_POST,
            headers: AuthHeader(),
            body: getIdWithoutPrefix()
        });

        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };
    
    getFilesInfo = async (modeKey, formName) => {
        const response = await fetch(URL + 'files/' + formName + '/' + getIdWithoutPrefix() + '/' + modeKey, {
            method: HTTP_GET,
            headers: AuthHeader()
        });

        if (response.ok) {
            return await response.json()
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status)
        }
    }

    saveFiles = async (request) => {
        const response = await fetch(URL + 'candidateFiles/files/' + getIdWithoutPrefix(), {
            method: HTTP_POST,
            headers: AuthHeader(),
            body: JSON.stringify(request)
        });
        if (response.ok) {
            let json = await response.json();
            if (json.wiadomoscTyp !== 'success') {
                createNotificationForResponse(json, "", response.status);
            }
            return json;
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    }


    downloadApplication = async (courseId, recruitmentModeKey, lang) => {
        const response = await fetch(URL + 'qualification/downloadApplication/' + getIdWithoutPrefix() + '/' + courseId + '/' + recruitmentModeKey + '/' + lang, {
            method: HTTP_GET,
            headers: AuthHeader()
        });
        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };


    sendMessage = async (message, title) => {
        const response = await fetch(URL + 'messages/sendSingleMessages ', {
            method: HTTP_POST,
            headers: AuthHeader(),
            body: JSON.stringify({
                    "message": message,
                    "profileId": getIdWithoutPrefix(),
                    "title": title
            })
        });

        if (response.ok) {
            return await response.json();
        }
    };


    setQualificationStatus = async (applicationId, status, documentReceipt, courseId) => {
        const response = await fetch(URL + 'qualification/qualificationStatus/', {
            method: HTTP_POST,
            headers: AuthHeader(),
            body: JSON.stringify({
                "applicationId": applicationId,
                "status": status,
                "documentReceipt": documentReceipt,
                "courseId": courseId
            })
        });

        if (response.ok) {
            return await response.json();
        }
    };


    getWarunki = async (recruitmentMode) => {
        const response = await fetch(URL + 'courses/getWarunki/' + getIdWithoutPrefix() + '/' + recruitmentMode , {
            method: HTTP_GET,
            headers: AuthHeader()
        });
        
        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };
}


export default new RecruitmentResultsFetchService();