import {styled} from '@material-ui/styles';
import {Box, Button} from '@material-ui/core';

export const UploadPhotoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const UploadPhotoDropdown = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem',
  borderRadius: '2px',
  border: '2px solid #eeeeee',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',

  '&:focus': {
    borderColor: '#800000',
  },

  '&:disabled': {
    opacity: 0.6,
  },
});

export const UploadPhotoButton = styled(Button)({
  backgroundColor: '#800000',
  color: '#FFFFFF',
  textTransform: 'none',

  '&:hover': {
    backgroundColor: '#a83232',
  },
});
