import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import {useMediaQuery} from "@material-ui/core";
import {useStyles, useTheme} from "../../css/Styles";
import {useTranslation} from "react-i18next";

export default function TodoTask({taskData}) {
    const theme = useTheme;
    const isMobile = useMediaQuery(theme.breakpoints.down('phone'))
    const {i18n} = useTranslation(); // Initialize the hook
    const currentLanguage = i18n.language; // Get the current language
    const classes = useStyles();

    return (
        <>
                <Grid item container direction='column' alignItems='center'>
                    <Paper
                        sx={{
                            width: isMobile ? '100%' : '100%', minHeight: isMobile ? '80px' : '60px', display: 'flex', // Adding flex display
                            alignItems: 'center'
                        }} // Centering content vertically}}
                        square={false}>
                        <Grid container direction={isMobile ? 'column' : 'row'} justifyContent="space-evenly"
                              alignItems="center" sx={{width: '100%', height: '100%'}}>
                            <Grid sx={{width: isMobile ? '100%' : '35%', padding: isMobile ? '5px' : '0px'}} item
                                  container>
                                <Typography sx={{width: '100%', textAlign: isMobile ? 'center' : 'left'}}>
                                    {currentLanguage == 'pl' ? taskData.name : taskData.englishName}
                                </Typography>
                            </Grid>

                            <Grid sx={{width: isMobile ? '100%' : '35%', padding: isMobile ? '5px' : '0px'}} item
                                  container alignItems="center" justifyContent="space-evenly">
                                <Chip sx={{width: 'auto'}} className={classes.todoChip}
                                      label={currentLanguage == 'pl' ? taskData.status : taskData.englishStatus}
                                      color={taskData.color} variant="outlined"/>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
        </>

    )
}

