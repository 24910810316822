import React, {useEffect, useRef} from 'react';
import {Box, Grid, IconButton, ThemeProvider, Typography, useMediaQuery} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {DictionaryTypeEnums, PersonalDataAdressesEnums} from "../../utils/CustomEnums";
import 'react-phone-input-2/lib/style.css'
import MenuTile from '../../templates/MenuTile';
import {useStyles, useTheme} from '../../css/Styles';

import {getCredentials} from '../../services/UserDataService';
import CustomCheckboxField from '../../templates/CustomCheckboxField';
import CustomToggleButtonTrueFalseField from '../../templates/CustomToggleButtonTrueFalseField'
import CustomSelectField from '../../templates/CustomSelectField';
import CustomRadioGroup from '../../templates/CustomRadioGroup';
import {getDictionaries, modifyAccessStorage, stringToBoolean} from '../../utils/AppUtils';
import DialogComponent from '../../templates/DialogComponent';
import FetchService from '../../services/FetchService';
import {createNotificationForResponse, errorNotification, warningNotification} from '../../utils/Notifications';
import logLevel from '../../utils/LoggerHelper';
import {getAddressPersonalFormDataFromRequest} from '../../utils/MapperUtils';
import FileUploadComponent from '../../templates/FileUploadComponent';
import {ACTIONS} from '../../utils/Consts';
import CustomDatePicker from '../../templates/CustomDatePicker';
import BackAndSaveButtons from '../../templates/BackAndSaveButtons';
import Popup, {popupReducer} from '../popup/Popup';
import {checkSum, getDate, getParsedDate, getSex} from '../../utils/DecodePesel';
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {filesReducer} from "../../utils/Files/FilesReducer";
import {saveFilesToDatabase} from "../../utils/Files/SaveFilesToDatabase";
import {CustomGridField} from "./CustomGridField";
import {AdresComponent} from "./AdresComponent";
import {
    checkChineseInObject,
    checkCompatibilityInObject,
    checkCyrillicInObject,
    checkEncodingCompatibility,
    checkJapaneseInObject
} from "../../utils/Validation/CheckForCyrillic";


const popupArray = [1, 2, 3, 4, 5, 6, 8, 10]; //A array to tell what rodzaj of cudzoziemców 

//inicjalizacja danych
const initialPersonalDataState = {
    czId: null,
    imie: null,
    imie2: null,
    nazwisko: null,
    narodowosc: null,
    obywatelstwo: null,
    obywatelstwo2: null,
    dataUrodzenia: null,
    miejsceUrodzenia: null,
    krajUrodzenia: null,
    imieOjca: null,
    imieMatki: null,
    motherMaidenName: null,
    nazwiskoRodowe: null,
    pesel: null,
    czyPesel: false,
    czyPierwszaOsobaStudiujaca: null,
    plec: null,
    seriaNumerPaszportu: null,
    krajWydaniaPaszportu: null,
    cudzoziemiecRodzaj: null,
    czyAdresKorespondencyjny: null,
    adresy: [{
        typ: PersonalDataAdressesEnums.STALY,
        kraj: null,
        wojewodztwo: null,
        typAdministracyjny: null,
        email: getCredentials(),
        telefon: null,
        ulica: null,
        numerDomu: null,
        numerMieszkania: null,
        kodPocztowy: null,
        miejscowosc: null
    }],
    czyStopienNiepelnosprawnosci: null,
    czySpecjalnePotrzeby: null
}

//slowniki

const krajDlaKtoregoWyswietlamyWojewodztwo = "Polska"
const obywatelstwoBezKtoregoWyswietlamyCudzoziemiecRodzaj = "polskie"
const selectOptionSufix = "-daneOsobowe-selectOption"

const checkboxInputs = ["czyPesel"]
const toggleButtonInputs = ["czyPierwszaOsobaStudiujaca", "czyStopienNiepelnosprawnosci", "czySpecjalnePotrzeby"]
const selectInputs = [{ id: "plec" + selectOptionSufix, name: "plec" },
{ id: "narodowosc" + selectOptionSufix, name: "narodowosc" },
{ id: "obywatelstwo" + selectOptionSufix, name: "obywatelstwo" },
{ id: "obywatelstwo2" + selectOptionSufix, name: "obywatelstwo2" },
{ id: "miejsceUrodzenia" + selectOptionSufix, name: "miejsceUrodzenia" },
{ id: "krajUrodzenia" + selectOptionSufix, name: "krajUrodzenia" },
{ id: "krajWydaniaPaszportu" + selectOptionSufix, name: "krajWydaniaPaszportu" },
{ id: "kraj" + selectOptionSufix, name: "kraj" },
{ id: "wojewodztwo" + selectOptionSufix, name: "wojewodztwo" },
{ id: "miejscowosc" + selectOptionSufix, name: "miejscowosc" }]

const defaultDictValue = [{ id: null, label: "" }]

const requiredFieldsvalidation = ["imie", "nazwisko", "narodowosc", "obywatelstwo", "dataUrodzenia", "miejsceUrodzenia", "krajUrodzenia",
    "imieOjca", "imieMatki", "plec", "czyPierwszaOsobaStudiujaca", "czyStopienNiepelnosprawnosci", "czySpecjalnePotrzeby", "nazwiskoRodowe", "motherMaidenName"]

const requiredLenghtForFields = [
    { name: "imie", maxLength: 20 },
    { name: "imie2", maxLength: 20 },
    { name: "nazwisko", maxLength: 25 },
    { name: "miejsceUrodzenia", maxLength: 50 },
    { name: "imieOjca", maxLength: 20 },
    { name: "imieMatki", maxLength: 25 },
    { name: "motherMaidenName", maxLength: 30 },
    { name: "nazwiskoRodowe", maxLength: 30 },
    { name: "seriaNumerPaszportu", maxLength: 20 },
]


const requiredAddressStalyFieldsValidation = ["kraj", "miejscowosc", "ulica", "numerDomu", "kodPocztowy", "typAdministracyjny", "telefon"]
const requiredLenghtForAdrdressFields = [
    { name: "kraj", maxLength: 30 },
    { name: "email", maxLength: 50 },
    { name: "wojewodztwo", maxLength: 20 },
    { name: "miejscowosc", maxLength: 30 },
    { name: "ulica", maxLength: 40 },
    { name: "numerDomu", maxLength: 10 },
    { name: "numerMieszkania", maxLength: 10 },
    { name: "kodPocztowy", maxLength: 15 },
    { name: "typAdministracyjny", maxLength: 10 },
    { name: "telefon", maxLength: 30 }
]


const requiredFieldsUsingRegexValidationStreet = [
    { name: "ulica" },
    { name: "numerDomu" },
    { name: "numerMieszkania" },
]


const requiredAddressKorespondencyjnyFieldsValidation = ["kraj", "miejscowosc", "ulica", "numerDomu", "kodPocztowy"]
const peselRegex = /^[0-9]{11}$/;
const firstNameRegex = /^\p{Lu}[\p{L}'-]*$/u
const postalCodeRegex = /^\d{2}-\d{3}$/;
const unicodeRegex = /[^\p{L}]$/u;
const lettersAndNumbersRegex = /[^a-zA-Z0-9]$/;
const streetRegex = /^[\p{L}0-9 ]*$/u;
const filesType = 'PERSONAL_DATA'
export default function Personal() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useTheme;

    //radioGroup cudzoziemiec 
    const cudzoziemiecRodzajNaglowekPliku = [
        { fileCaption: t('personal.foreignerFileCaption.plCard'), id: 1 },
        { fileCaption: t('personal.foreignerFileCaption.longterm'), id: 2 },
        { fileCaption: t('personal.foreignerFileCaption.refugee'), id: 3 },
        { fileCaption: t('personal.foreignerFileCaption.euCitizen'), id: 4 },
        { fileCaption: t('personal.foreignerFileCaption.c1'), id: 5 },
        { fileCaption: t('personal.foreignerFileCaption.married'), id: 6 },
        { fileCaption: t('personal.foreignerFileCaption.science'), id: 8 },
        { fileCaption: t('personal.foreignerFileCaption.nawa'), id: 10 }]

    const plcie = [{ id: "1", label: t('personal.female') }, { id: "0", label: t('personal.male') }]

    const [popup, dispatchPopup] = React.useReducer(popupReducer, null);

    const [inputPersonalData, setInputPersonalData] = React.useState(initialPersonalDataState)
    const [personalDataDisabled, setPersonalDataDisabled] = React.useState(true)
    const [isDialogSaveOpen, setIsDialogSaveOpen] = React.useState(false)
    const [saveButtonDisabled, setSaveButtonDisabled] = React.useState(false)
    const [formErrors, setFormErrors] = React.useState([])


    const [obywatelstwoKey] = React.useState(1)
    const [obywatelstwo2Key] = React.useState(2)
    const [narodowoscKey] = React.useState(3)
    const [plecKey] = React.useState(4)
    const [miejsceUrodzeniaKey] = React.useState(5)
    const [krajUrodzeniaKey] = React.useState(6)
    const [krajWydaniaPaszportuKey] = React.useState(7)

    const [wojewodztwa, setWojewodztwa] = React.useState(defaultDictValue)
    const [kraje, setKraje] = React.useState(defaultDictValue)
    const [miasta, setMiasta] = React.useState(defaultDictValue)
    const [narodowosci, setNarodowosci] = React.useState(defaultDictValue)
    const [obywatelstwa, setObywatelstwa] = React.useState(defaultDictValue)
    const [cudzoziemiecRodzaje, setCudzoziemiecRodzaje] = React.useState(defaultDictValue)

    //files info reducer
    const [filesInfo, dispatchFilesInfo] = React.useReducer(filesReducer, [])

    //input references for better file upload styling
    const inputRefs = useRef([])

    const isMobile = useMediaQuery(theme.breakpoints.down('tablet'))

    async function prepareDictionaries() {
        return getDictionaries(DictionaryTypeEnums.ADDRESS_PERSONAL).then(addressPersonalDict => {
            if (addressPersonalDict) {
                let addressPersonalDictValues = addressPersonalDict.value

                let wojewodztwaTemp = [];
                let krajeTemp = [];
                let miastaTemp = [];
                let narodowosciTemp = [];
                let obywatelstwaTemp = [].concat(defaultDictValue);
                let cudzoziemiecRodzajTemp = [];

                let wojewodztwaDict = addressPersonalDictValues.wojewodztwa;
                let krajeDict = addressPersonalDictValues.kraje;
                let miastaDict = addressPersonalDictValues.miasta;
                let narodowosciDict = addressPersonalDictValues.narodowosci;
                let obywatelstwaDict = addressPersonalDictValues.obywatelstwa;
                let cudzoziemiecRodzajDict = addressPersonalDictValues.selection;


                if (wojewodztwaDict) {
                    wojewodztwaDict.forEach(wojewodztwo => {
                        wojewodztwaTemp.push({ id: wojewodztwo.valuePL, label: wojewodztwo.valuePL })
                    });
                    setWojewodztwa(wojewodztwaTemp)
                }


                if (krajeDict) {
                    krajeDict.forEach(kraj => {
                        krajeTemp.push({id: kraj.valuePL, label: (i18n.language == 'pl' ? kraj.valuePL : kraj.valuePL)})
                    });
                    krajeTemp.sort((current, next) => (current.id > next.id) ? 1 : -1)
                    setKraje(krajeTemp)
                }

                if (miastaDict) {
                    miastaDict.forEach(miasto => {
                        miastaTemp.push({ id: miasto.valuePL, label: miasto.valuePL })
                    });
                    setMiasta(miastaTemp)
                }

                if (narodowosciDict) {
                    narodowosciDict.forEach(narodowosc => {
                        narodowosciTemp.push({
                            id: narodowosc.valuePL,
                            label: (i18n.language == 'pl' ? narodowosc.valuePL : narodowosc.valuePL)
                        })
                    });
                    narodowosciTemp.sort((current, next) => (current.id > next.id) ? 1 : -1)
                    setNarodowosci(narodowosciTemp)
                }

                if (obywatelstwaDict) {
                    obywatelstwaDict.forEach(obywatelstwo => {
                        obywatelstwaTemp.push({
                            id: obywatelstwo.valuePL,
                            label: (i18n.language == 'pl' ? obywatelstwo.valuePL : obywatelstwo.valuePL)
                        })
                    });
                    obywatelstwaTemp.sort((current, next) => (current.id > next.id) ? 1 : -1)
                    setObywatelstwa(obywatelstwaTemp)
                }

                if (cudzoziemiecRodzajDict) {
                    cudzoziemiecRodzajDict.forEach(rodzaj => {
                        cudzoziemiecRodzajTemp.push({
                            label: (i18n.language == 'pl' ? rodzaj.valuePL : rodzaj.valuePL),
                            value: rodzaj.key
                        })

                    });
                    setCudzoziemiecRodzaje(cudzoziemiecRodzajTemp)
                }

                return {
                    wojewodztwa: wojewodztwaTemp,
                    kraje: krajeTemp,
                    miasta: miastaTemp,
                    narodowosci: narodowosciTemp,
                    obywatelstwa: obywatelstwaTemp,
                    plcie: plcie
                }
            }
        });
    }

    useEffect(() => {

        FetchService.getAccessToPersonalData().then(response => {
            if (response && response.zawartosc !== undefined) {
                setPersonalDataDisabled(response.zawartosc)
            }
        })

        prepareDictionaries().then(responseDict => {
            FetchService.getAddressPersonal().then(response => {
                if (response && response.zawartosc) {
                    setInputPersonalData(getAddressPersonalFormDataFromRequest(response.zawartosc, responseDict))
                } else {
                    createNotificationForResponse(response);
                }
            }).catch(error => {
                logLevel.debug("Błąd podczas odczytu danych osobowych, Error " + error);
                errorNotification('default.errorOnGet', ' ');
            })
        })

        FetchService.getFilesInfo('etap_1', filesType).then(response => {
            let tempInputRefs = response.zawartosc?.map((element, index) => {
                return inputRefs.current[index] = React.createRef();
            })
            dispatchFilesInfo({ type: ACTIONS.SET_INITIAL_FILES, payload: response.zawartosc, inputRefs: tempInputRefs })
        })

    }, [])

    useEffect(() => {
    }, [inputPersonalData])

    const changeDataHandler = (event, newValue, ref) => {
        let fields = { ...inputPersonalData };
        if (checkboxInputs.includes(event.target.name)) {
            fields[event.target.name] = event.target.checked;

            //czyszczenie danych, które są ukryte po zmianie checboxa czPesel
            if (event.target.name === "czyPesel") {
                if (event.target.checked) {
                    fields.pesel = null
                } else {
                    fields.seriaNumerPaszportu = null
                    fields.krajWydaniaPaszportu = null
                }
            }
        } else if (toggleButtonInputs.includes(event.target.name)) {
            if (fields[event.target.name] !== null && fields[event.target.name] === stringToBoolean(event.target.value)) {
                fields[event.target.name] = null
            } else {
                fields[event.target.name] = stringToBoolean(event.target.value);
            }
        } else if (ref && (ref === 'selectOption' || ref === 'blur' || ref === 'clear')) {
            let inputSelectField = selectInputs.find(selectInput => event.target.id.startsWith(selectInput.id))
            fields[inputSelectField.name] = newValue

            //czyszczenie danych, które są ukryte po zmianie obywatelstwa
            if (inputSelectField.name === "obywatelstwo" || inputSelectField.name === "obywatelstwo2") {
                if (hasPolishCitizenship(fields)) {
                    fields.seriaNumerPaszportu = null
                    fields.krajWydaniaPaszportu = null
                    fields.czyPesel = false
                }
            }
        } else {
            let pre_split = event.target.value;
            let split_string = pre_split.split(" ");
            for (let i = 0; i < split_string.length; i++) {
                split_string[i] = split_string[i].charAt(0).toUpperCase() + split_string[i].slice(1);
            }
            fields[event.target.name] = split_string.join(" ");
        }
        let pesel = event.target.value
        if (event.target.name === 'pesel' && peselRegex.test(pesel)) {
            let data = getDate(event.target.value)
            fields.dataUrodzenia = getParsedDate(data.toISOString())
            fields.plec = plcie.find(plec => plec.id === getSex(fields.pesel))
        }

        setInputPersonalData(fields)
    }

    const changeAdressDataHandler = (event, newValue, ref) => {
        let fieledNameId
        if (ref && (ref === 'selectOption' || ref === 'blur')) {
            fieledNameId = event.target.id
        } else {
            fieledNameId = event.target.name
        }

        let adres = inputPersonalData.adresy.find(adres => fieledNameId.startsWith(adres.typ))


        let tempAdresTyp = adres.typ
        let tempFieldName
        let fields = { ...adres };
        // event.target.name = event.target.name.replace(adres.typ+"-", "");
        tempFieldName = fieledNameId.substring(fieledNameId.indexOf("-") + 1);

        if (checkboxInputs.includes(tempFieldName)) {
            fields[tempFieldName] = event.target.checked;
        } else if (ref && (ref === 'selectOption' || ref === 'blur')) {
            let inputSelectField = selectInputs.find(selectInput => tempFieldName.startsWith(selectInput.id))
            fields[inputSelectField.name] = newValue

            //czyszczenie danych, które są ukryte po zmianie kraju w adresie
            if (inputSelectField.name === "kraj") {
                if (newValue.id !== krajDlaKtoregoWyswietlamyWojewodztwo) {
                    fields.wojewodztwo = null
                }
            }
        } else {
            //fields[tempFieldName] = event.target.value.toLowerCase();
            let pre_split = event.target.value;
            let split_string = pre_split.split(" ");
            for (let i = 0; i < split_string.length; i++) {
                split_string[i] = split_string[i].charAt(0).toUpperCase() + split_string[i].slice(1);
            }
            fields[tempFieldName] = split_string.join(" ");
        }

        var newAdresy = inputPersonalData.adresy.filter(adres => adres.typ !== tempAdresTyp)
        newAdresy.push(fields)

        if (newAdresy.length) {
            if (newAdresy[0].typAdministracyjny) {
                newAdresy[0].typAdministracyjny = newAdresy[0].typAdministracyjny.toLowerCase(); // I am not sure how I changed the input to database by adding translations but this is quick fix.
            }
        }

        let fieldsPersonalData = { ...inputPersonalData };
        fieldsPersonalData.adresy = newAdresy.sort((x, y) => x.typ > y.typ ? -1 : 1);
        //adres.typAdministracyjny = adres.typAdministracyjny.toLowerCase();
        setInputPersonalData(fieldsPersonalData)
    }

    const changeTelefonAdresStalyHandler = (formattedValue) => {
        let adres = inputPersonalData.adresy.find(adres => adres.typ === PersonalDataAdressesEnums.STALY)
        let fields = { ...adres };
        fields["telefon"] = formattedValue;

        var newAdresy = inputPersonalData.adresy.filter(adres => adres.typ !== PersonalDataAdressesEnums.STALY)
        newAdresy.push(fields)

        let fieldsPersonalData = { ...inputPersonalData };
        fieldsPersonalData.adresy = newAdresy.sort((x, y) => x.typ > y.typ ? -1 : 1);

        setInputPersonalData(fieldsPersonalData)
    }

    const adresKorespondencyjnyHandler = (event) => {
        var newAdresy
        if (event.target.checked) {
            newAdresy = inputPersonalData.adresy.slice();
            newAdresy.push(
                {
                    typ: PersonalDataAdressesEnums.KORESPONDENCYJNY,
                    kraj: null,
                    wojewodztwo: null,
                    ulica: null,
                    numerDomu: null,
                    numerMieszkania: null,
                    kodPocztowy: null,
                    miejscowosc: null
                })
            inputPersonalData.adresy = newAdresy;
        } else {
            newAdresy = inputPersonalData.adresy.filter(adres => adres.typ === PersonalDataAdressesEnums.STALY)
            inputPersonalData.adresy = newAdresy;
        }
        let inputPersonalDataFields = { ...inputPersonalData };
        inputPersonalDataFields.czyAdresKorespondencyjny = event.target.checked
        setInputPersonalData(inputPersonalDataFields)
    }

    const datePickerHandler = (value) => {
        let fields = { ...inputPersonalData };
        fields.dataUrodzenia = value
        setInputPersonalData(fields)
    }

    const isShowCudzoziemiecRodzajPart = (personalData) => {
        if (personalData) {
            if ((personalData.obywatelstwo && personalData.obywatelstwo.id === obywatelstwoBezKtoregoWyswietlamyCudzoziemiecRodzaj) ||
                (personalData.obywatelstwo2 && personalData.obywatelstwo2.id === obywatelstwoBezKtoregoWyswietlamyCudzoziemiecRodzaj)) {
                return false;
            } else {
                if (personalData.obywatelstwo || personalData.obywatelstwo2) {
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            return false;
        }
    }

    const hasPolishCitizenship = (personalData) => {
        if (personalData) {
            if ((personalData.obywatelstwo && personalData.obywatelstwo.id === obywatelstwoBezKtoregoWyswietlamyCudzoziemiecRodzaj) ||
                (personalData.obywatelstwo2 && personalData.obywatelstwo2.id === obywatelstwoBezKtoregoWyswietlamyCudzoziemiecRodzaj)) {
                return true;
            }
        }

        return false;
    }


    const handleOpenSaveConfirmDialog = () => {
        let errors = handleValidation()
        if (errors.length == 0) {
            setIsDialogSaveOpen(true)
        } else {
            let extraValue = errors[0]?.extraValue?.join(', ') ? errors[0]?.extraValue?.join(', ') : ""
            warningNotification("personal.formValidationError", t(errors[0].value) + extraValue)
        }
    }

    const handleCloseSaveConfirmDialog = () => {
        setIsDialogSaveOpen(false)
    }

    function getFileWithCaptionForForeigner(element) {
        let tempName = cudzoziemiecRodzajNaglowekPliku.find(naglowek => naglowek.id == inputPersonalData.cudzoziemiecRodzaj)

        let data = { ...element }
        if (tempName) {
            data.attachmentInfo.namePL = tempName.fileCaption;
            data.attachmentInfo.nameEN = tempName.fileCaption;
        } else {
            data.attachmentInfo.namePL = t('personal.foreignerFileCaption.default')
            data.attachmentInfo.nameEN = t('personal.foreignerFileCaption.default')
        }
        return data;
    }


    const handleSave = () => {
        setSaveButtonDisabled(true)
        saveFilesToDatabase(filesInfo)
        handleCloseSaveConfirmDialog()
        let errors = handleValidation();

        if (errors.length == 0) {

            FetchService.saveAddressPersonal(clearHiddenFieldsBeforeSaveAndGetPersonalData())
                .then(response => {
                    if (response) {
                        createNotificationForResponse(response);
                    }
                    modifyAccessStorage('personalData')
                    setSaveButtonDisabled(false)
                }).catch(error => {
                    logLevel.debug("Błąd podczas zapisu danych personalnych.", error);
                    errorNotification('default.errorOnSave', ' ');
                    setSaveButtonDisabled(false)
                })
        } else {
            warningNotification("personal.formValidationError", errors[0].value);
            setSaveButtonDisabled(false)
        }
    }

    //metoda na potrzeby zachowania wartości w quizie po zmianie obywatelstwa - https://pomocnik.p.lodz.pl/view.php?id=79841
    const clearHiddenFieldsBeforeSaveAndGetPersonalData = () => {
        let fields = { ...inputPersonalData };
        if (!isShowCudzoziemiecRodzajPart(fields)) {
            fields.cudzoziemiecRodzaj = null
        }
        return fields
    }

    const handleValidation = () => {
        let errors = [];
        let formIsValid = true;

        let fields = { ...inputPersonalData };
        requiredFieldsvalidation.filter(reqField => {
            let selectInput = selectInputs.find(selectInput => selectInput.name === reqField)

            if (fields[reqField] === undefined ||
                fields[reqField] === null ||
                (typeof fields[reqField] === 'string' && fields[reqField].trim().length === 0) ||
                (selectInput && fields[reqField].id === null)) {
                return true;
            } else {
                return false;
            }
        })
            .forEach(reqField => {
                errors = errors.concat({ id: reqField, value: "default.fieldRequired" });
                formIsValid = false;
            })

        let notCompatibleLetters = checkEncodingCompatibility(fields["imie"]);
        if (notCompatibleLetters) {
            errors = errors.concat({ id: 'imie', value: "personal.notCompatiable", extraValue: notCompatibleLetters });
            formIsValid = false;
        }
        if (fields["imie2"]) {
            notCompatibleLetters = checkEncodingCompatibility(fields["imie2"]);
            if (notCompatibleLetters) {
                errors = errors.concat({ id: 'imie2', value: "personal.notCompatiable", extraValue: notCompatibleLetters });
                formIsValid = false;
            }
        }
        notCompatibleLetters = checkEncodingCompatibility(fields["nazwisko"]);
        if (notCompatibleLetters) {
            errors = errors.concat({
                id: 'nazwisko',
                value: "personal.notCompatiable",
                extraValue: notCompatibleLetters
            });
            formIsValid = false;
        }

        notCompatibleLetters = checkEncodingCompatibility(fields["imieMatki"]);
        if (notCompatibleLetters) {
            errors = errors.concat({
                id: 'imieMatki',
                value: "personal.notCompatiable",
                extraValue: notCompatibleLetters
            });
            formIsValid = false;
        }

        notCompatibleLetters = checkEncodingCompatibility(fields["motherMaidenName"]);
        if (notCompatibleLetters) {
            errors = errors.concat({
                id: 'motherMaidenName',
                value: "personal.notCompatiable",
                extraValue: notCompatibleLetters
            });
            formIsValid = false;
        }

        notCompatibleLetters = checkEncodingCompatibility(fields["imieOjca"]);
        if (notCompatibleLetters) {
            errors = errors.concat({
                id: 'imieOjca',
                value: "personal.notCompatiable",
                extraValue: notCompatibleLetters
            });
            formIsValid = false;
        }

        notCompatibleLetters = checkEncodingCompatibility(fields["nazwiskoRodowe"]);
        if (notCompatibleLetters) {
            errors = errors.concat({
                id: 'nazwiskoRodowe',
                value: "personal.notCompatiable",
                extraValue: notCompatibleLetters
            });
            formIsValid = false;
        }

        const checkAresUsingRegex = (field, regex) => {
            if (field) {
                // Check if the string contains any special symbols or numbers
                if (!regex.test(field)) {
                    return true;
                }
            }
            return false;
        }


        if (fields["imie"]) {
            // Check if the string contains any special symbols or numbers
            if (!firstNameRegex.test(fields["imie"])) {
                errors = errors.concat({ id: 'imie', value: "personal.imieRegex" });
                formIsValid = false;
            }
        }




        if (isShowCudzoziemiecRodzajPart(inputPersonalData) && !fields.cudzoziemiecRodzaj) {
            errors = errors.concat({ id: 'cudzoziemiecRodzaj', value: "default.fieldRequired" });
            formIsValid = false;
        }


        const validatePesel = (pesel) => {
            if (!peselRegex.test(pesel)) {
                errors = errors.concat({ id: 'pesel', value: "personal.peselInvalid" });
                formIsValid = false;
                return;
            }

            let dateFromPesel = getDate(pesel);

            let dateString = fields.dataUrodzenia
            let [year, month, day] = dateString.split("-");
            const dateFromField = new Date(Date.UTC(year, month - 1, day));
            //This nifty trick will ensure that component passes UTC date


            if (!checkSum(pesel)) {
                errors = errors.concat({ id: 'pesel', value: "personal.peselInvalid" });
                formIsValid = false;
                return;
            }

            if (dateFromPesel.toUTCString() != dateFromField.toUTCString()) {
                errors = errors.concat({ id: 'pesel', value: "personal.peselAndBirthDateDontMatch" });
                formIsValid = false;
                return;
            }

            if (getSex(pesel) !== fields.plec?.id) {
                errors = errors.concat({ id: 'pesel', value: "personal.peselAndSexDontMatch" });
                formIsValid = false;

            }

        }

        if (!fields.czyPesel) {
            if (!fields.pesel) {
                if (hasPolishCitizenship(inputPersonalData)) {
                    errors = errors.concat({ id: 'pesel', value: "personal.peselRequiredForPolishCitizenship" });
                } else {
                    errors = errors.concat({ id: 'pesel', value: "default.fieldRequired" });
                }
                formIsValid = false;
            } else {
                validatePesel(fields.pesel)
            }
        } else {
            if (!fields.seriaNumerPaszportu) {
                errors = errors.concat({ id: 'seriaNumerPaszportu', value: "default.fieldRequired" });
                formIsValid = false;
            }
            if (!fields.krajWydaniaPaszportu) {
                errors = errors.concat({ id: 'krajWydaniaPaszportu', value: "default.fieldRequired" });
                formIsValid = false;
            }
        }


        let adresStaly = inputPersonalData.adresy.find(adres => adres.typ === PersonalDataAdressesEnums.STALY)
        requiredAddressStalyFieldsValidation.filter(reqField => !adresStaly[reqField] || (typeof adresStaly[reqField] === 'string' && adresStaly[reqField].trim().length === 0))
            .forEach(reqField => {
                errors = errors.concat({
                    id: PersonalDataAdressesEnums.STALY + ("-") + reqField,
                    value: "default.fieldRequired"
                });
                formIsValid = false;
            })


        requiredLenghtForAdrdressFields.forEach(field => {
            if (adresStaly[field.name]) {
                if (adresStaly[field.name].length > field.maxLength) {
                    errors = errors.concat({
                        id: PersonalDataAdressesEnums.STALY + ("-") + field.name,
                        value: "personal.tooLong"
                    });
                    formIsValid = false;
                }
            }

        })


        requiredFieldsUsingRegexValidationStreet.forEach(field => {
            if (checkAresUsingRegex(adresStaly[field.name], streetRegex)) {
                errors = errors.concat({
                    id: PersonalDataAdressesEnums.STALY + ("-") + field.name,
                    value: "personal.addressRegex"
                });
                formIsValid = false;
            }
        }
        )


        requiredLenghtForFields.forEach(field => {
            if (inputPersonalData[field.name]) {
                if (inputPersonalData[field.name].length > field.maxLength) {
                    errors = errors.concat({ id: field.name, value: "personal.tooLong" });
                    formIsValid = false;
                }
            }
        })

        if (adresStaly.kraj && adresStaly.kraj.id === krajDlaKtoregoWyswietlamyWojewodztwo && !adresStaly.wojewodztwo) {
            errors = errors.concat({
                id: PersonalDataAdressesEnums.STALY + '-wojewodztwo',
                value: "default.fieldRequired"
            });
            formIsValid = false;
        }

        if (adresStaly.kraj && adresStaly.kraj.id === krajDlaKtoregoWyswietlamyWojewodztwo && !postalCodeRegex.test(adresStaly.kodPocztowy)) {
            errors = errors.concat({
                id: PersonalDataAdressesEnums.STALY + '-kodPocztowy',
                value: "personal.postalCodeInvalid"
            });
            formIsValid = false;
        }

        if (lettersAndNumbersRegex.test(adresStaly["numerDomu"])) {
            errors = errors.concat({
                id: PersonalDataAdressesEnums.STALY + '-numerDomu',
                value: "personal.numerDomuInvalid"
            });
            formIsValid = false;

        }

        if (fields.czyAdresKorespondencyjny) {
            let adresKorespondencyjny = inputPersonalData.adresy.find(adres => adres.typ === PersonalDataAdressesEnums.KORESPONDENCYJNY)
            requiredAddressKorespondencyjnyFieldsValidation.filter(reqField => !adresKorespondencyjny[reqField] || (typeof adresKorespondencyjny[reqField] === 'string' && adresKorespondencyjny[reqField].trim().length === 0))
                .forEach(reqField => {
                    errors = errors.concat({
                        id: PersonalDataAdressesEnums.KORESPONDENCYJNY + ("-") + reqField,
                        value: "default.fieldRequired"
                    });
                    formIsValid = false;
                })

            requiredLenghtForAdrdressFields.forEach(field => {
                if (adresKorespondencyjny[field.name]) {
                    if (adresKorespondencyjny[field.name].length > field.maxLength) {
                        errors = errors.concat({
                            id: PersonalDataAdressesEnums.KORESPONDENCYJNY + ("-") + field.name,
                            value: "personal.tooLong"
                        });
                        formIsValid = false;
                    }
                }
            })

            requiredFieldsUsingRegexValidationStreet.forEach(field => {

                if (checkAresUsingRegex(adresKorespondencyjny[field.name], streetRegex)) {
                    errors = errors.concat({
                        id: PersonalDataAdressesEnums.KORESPONDENCYJNY + ("-") + field.name,
                        value: "personal.addressRegex"
                    });
                    formIsValid = false;
                }
            }
            )


            if (adresKorespondencyjny.kraj && adresKorespondencyjny.kraj.id === krajDlaKtoregoWyswietlamyWojewodztwo && !adresKorespondencyjny.wojewodztwo) {
                errors = errors.concat({
                    id: PersonalDataAdressesEnums.KORESPONDENCYJNY + '-wojewodztwo',
                    value: "default.fieldRequired"
                });
                formIsValid = false;
            }

            if (adresKorespondencyjny.kraj && adresKorespondencyjny.kraj.id === krajDlaKtoregoWyswietlamyWojewodztwo && !postalCodeRegex.test(adresKorespondencyjny.kodPocztowy)) {
                errors = errors.concat({
                    id: PersonalDataAdressesEnums.KORESPONDENCYJNY + '-kodPocztowy',
                    value: "personal.postalCodeInvalid"
                });
                formIsValid = false;
            }

            if (lettersAndNumbersRegex.test(adresStaly["numerDomu"])) {
                errors = errors.concat({
                    id: PersonalDataAdressesEnums.STALY + '-numerDomu',
                    value: "personal.numerDomuInvalid"
                });
                formIsValid = false;

            }
        }

        if (checkCyrillicInObject(inputPersonalData))
            errors = errors.concat({
                id: "cyrillicError",
                value: "cyrillicError"
            })
        if (checkChineseInObject(inputPersonalData))
            errors = errors.concat({
                id: "chineseError",
                value: "chineseError"
            })
        if (checkJapaneseInObject(inputPersonalData))
            errors = errors.concat({
                id: "japaneseError",
                value: "japaneseError"
            })
        let incompatibleChars = checkCompatibilityInObject(inputPersonalData);
        if (incompatibleChars)
            errors = errors.concat({
                id: "compatibilityError",
                value: "personal.notCompatiable",
                extraValue: incompatibleChars
            })
        setFormErrors(errors)
        return errors;
    }

    return (
        <div>
            {/* ------------------------DIALOGI------------------------ */}
            <DialogComponent
                title={t('default.confirmSave')}
                content={t('personal.confirmSave')}
                visible={isDialogSaveOpen}
                buttons={[
                    { buttonLabel: t('default.cancel'), buttonAction: () => handleCloseSaveConfirmDialog() },
                    { buttonLabel: t('yes'), buttonAction: () => handleSave() }
                ]}
            />
            {/* ------------------------DIALOGI------------------------ */}
            <ThemeProvider theme={theme}>

                {Popup(popup, dispatchPopup) /*popup*/}

                <Box sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: isMobile ? 'center' : 'flex-start',
                    flexDirection: isMobile ? 'column' : 'row'
                }}>
                    <Grid sx={{ padding: '2.5vh' }} display="flex" justifyItems="end">
                        <MenuTile title={t('main.personalDataTile')} locked={false} />
                    </Grid>
                    {/* Padding grida poniżej jest zawsze o 1vh większy, bo padding komponentu MenuTile to 1vh */}
                    <Grid sx={{ padding: isMobile ? '1vh' : '3vh' }} container direction={'row'}
                        justifyContent="space-around" alignItems="flex-start">
                        <Grid style={{ width: '100%' }} container direction={isMobile ? 'column' : 'row'}
                            justifyContent="center">
                            <Grid style={{ width: isMobile ? '100%' : '35%' }}>
                                <CustomGridField name="imie" title={t('personal.imie')} label={t('personal.imie')}
                                    value={inputPersonalData.imie} disabledState={personalDataDisabled}
                                    changeHandler={changeDataHandler} required={true} capital={true}
                                    errors={formErrors} />
                                <CustomGridField name="imie2" title={t('personal.imie2')} label={t('personal.imie2')}
                                    value={inputPersonalData.imie2} disabledState={personalDataDisabled}
                                    changeHandler={changeDataHandler} errors={formErrors} capital={true} />
                                <CustomGridField name="nazwisko" title={t('personal.nazwisko')}
                                    label={t('personal.nazwisko')} value={inputPersonalData.nazwisko}
                                    disabledState={personalDataDisabled} changeHandler={changeDataHandler}
                                    required={true} capital={true} errors={formErrors} />
                                <Box style={{
                                    display: 'flex',
                                    width: isMobile ? '97%' : '90%',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center'
                                }}>
                                    <CustomSelectField name="narodowosc" id="narodowosc"
                                        title={t('personal.narodowosc')}
                                        options={narodowosci} value={inputPersonalData.narodowosc}
                                                       key={-narodowoscKey} editState={personalDataDisabled}
                                        changeHandler={changeDataHandler} required={true}
                                        selectOptionSufix={selectOptionSufix} errors={formErrors} />
                                    <IconButton color='secondary' variant="contained" onClick={() => {
                                        dispatchPopup({
                                            type: 'OPEN',
                                            payload: "personal.narodowosc"
                                        })
                                    }} children={<HelpOutlineIcon sx={{ fontSize: 30 }} />} />

                                </Box>

                                <Box style={{
                                    display: 'flex',
                                    width: isMobile ? '97%' : '90%',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center'
                                }}>
                                    <CustomSelectField name="obywatelstwo" id="obywatelstwo"
                                        title={t('personal.obywatelstwo')} options={obywatelstwa}
                                        value={inputPersonalData.obywatelstwo} key={-obywatelstwoKey}
                                                       editState={personalDataDisabled}
                                        changeHandler={changeDataHandler} required={true}
                                        selectOptionSufix={selectOptionSufix} errors={formErrors} />

                                    <IconButton color='secondary' variant="contained" onClick={() => {
                                        dispatchPopup({
                                            type: 'OPEN',
                                            payload: "personal.obywatelstwo"
                                        })
                                    }} children={<HelpOutlineIcon sx={{ fontSize: 30 }} />} />
                                </Box>


                                <CustomSelectField name="obywatelstwo2" id="obywatelstwo2"
                                    title={t('personal.obywatelstwo2')} options={obywatelstwa}
                                    value={inputPersonalData.obywatelstwo2} key={-obywatelstwo2Key}
                                                   editState={personalDataDisabled}
                                    changeHandler={changeDataHandler} required={false}
                                    selectOptionSufix={selectOptionSufix} />
                                <CustomCheckboxField name="czyPesel" title={t('personal.czyPesel')}
                                    label={t('personal.czyPesel')} value={inputPersonalData.czyPesel}
                                                     disabledState={personalDataDisabled || hasPolishCitizenship(inputPersonalData)}
                                    changeHandler={changeDataHandler}
                                    className={classes.daneOsoboweGridField} />
                                {(inputPersonalData && !inputPersonalData.czyPesel) ?


                                    <CustomGridField name="pesel" title={t('personal.pesel')}
                                        label={t('personal.pesel')} value={inputPersonalData.pesel}
                                        disabledState={personalDataDisabled}
                                        changeHandler={changeDataHandler} dataType={'number'} pesel={true}
                                        errors={formErrors} /> :
                                    <>
                                        <CustomGridField name="seriaNumerPaszportu"
                                            title={t('personal.seriaNumerPaszportu')}
                                            label={t('personal.seriaNumerPaszportu')}
                                            value={inputPersonalData.seriaNumerPaszportu}
                                            disabledState={personalDataDisabled}
                                            changeHandler={changeDataHandler} required={true}
                                            errors={formErrors} />
                                        <CustomSelectField name="krajWydaniaPaszportu" id="krajWydaniaPaszportu"
                                            title={t('personal.krajWydaniaPaszportu')} options={kraje}
                                            value={inputPersonalData.krajWydaniaPaszportu}
                                                           key={krajWydaniaPaszportuKey}
                                                           editState={personalDataDisabled}
                                            changeHandler={changeDataHandler} required={true}
                                            selectOptionSufix={selectOptionSufix} errors={formErrors} />
                                    </>
                                }
                            </Grid>
                            <Grid style={{ width: isMobile ? '100%' : '65%' }}>
                                <Grid container style={{ width: '100%' }} display={'flex'}>
                                    <Grid style={{ width: isMobile ? '100%' : '50%' }}>
                                        <CustomDatePicker name="dataUrodzenia" title={t('personal.dataUrodzenia')}
                                            label={t('personal.dataUrodzenia')}
                                            value={inputPersonalData.dataUrodzenia}
                                                          editState={personalDataDisabled}
                                            changeHandler={datePickerHandler} required={true}
                                            errors={formErrors} disableFuture={true} />

                                        <CustomSelectField name="krajUrodzenia" id="krajUrodzenia"
                                            title={t('personal.krajUrodzenia')} options={kraje}
                                            value={inputPersonalData.krajUrodzenia}
                                                           key={-krajUrodzeniaKey} editState={personalDataDisabled}
                                            changeHandler={changeDataHandler} required={true}
                                            selectOptionSufix={selectOptionSufix} errors={formErrors} />
                                        <CustomSelectField name="miejsceUrodzenia" id="miejsceUrodzenia"
                                            title={t('personal.miejsceUrodzenia')} options={miasta}
                                            value={inputPersonalData.miejsceUrodzenia}
                                            key={-miejsceUrodzeniaKey}
                                                           editState={personalDataDisabled}
                                            changeHandler={changeDataHandler} required={true}
                                            selectOptionSufix={selectOptionSufix} freeSolo={true}
                                            errors={formErrors} />
                                    </Grid>
                                    <Grid style={{ width: isMobile ? '100%' : '50%' }}>
                                        <CustomGridField name="imieOjca" title={t('personal.imieOjca')}
                                            label={t('personal.imieOjca')}
                                            value={inputPersonalData.imieOjca}
                                            disabledState={personalDataDisabled}
                                            changeHandler={changeDataHandler} required={true}
                                            capital={true} errors={formErrors} />
                                        <CustomGridField name="imieMatki" title={t('personal.imieMatki')}
                                            label={t('personal.imieMatki')}
                                            value={inputPersonalData.imieMatki}
                                            disabledState={personalDataDisabled}
                                            changeHandler={changeDataHandler} required={true}
                                            capital={true} errors={formErrors} />
                                        <CustomGridField name="motherMaidenName" title={t('personal.motherMaidenName')}
                                            label={t('personal.motherMaidenName')}
                                            value={inputPersonalData.motherMaidenName}
                                            disabledState={personalDataDisabled}
                                            changeHandler={changeDataHandler} required={true}
                                            capital={true} errors={formErrors} />
                                        <CustomGridField name="nazwiskoRodowe" title={t('personal.nazwiskoRodowe')}
                                            label={t('personal.nazwiskoRodowe')}
                                            value={inputPersonalData.nazwiskoRodowe}
                                            disabledState={personalDataDisabled} required={true}
                                            changeHandler={changeDataHandler} errors={formErrors}
                                            capital={true} />
                                        <CustomSelectField name="plec" id="plec" title={t('personal.plec')}
                                            options={plcie} value={inputPersonalData.plec} key={-plecKey}
                                                           editState={personalDataDisabled}
                                            changeHandler={changeDataHandler} required={true}
                                            selectOptionSufix={selectOptionSufix} errors={formErrors} />
                                    </Grid>
                                </Grid>
                                <Grid style={{ width: '100%' }} marginTop={5}>
                                    <CustomToggleButtonTrueFalseField name="czyPierwszaOsobaStudiujaca"
                                        title={t('personal.czyPierwszaOsobaStudiujaca')}
                                        label={t('personal.czyPierwszaOsobaStudiujaca')}
                                        value={inputPersonalData.czyPierwszaOsobaStudiujaca}
                                        disabledState={personalDataDisabled}
                                        changeHandler={changeDataHandler}
                                        errors={formErrors} />
                                </Grid>
                            </Grid>
                            {(isShowCudzoziemiecRodzajPart(inputPersonalData)) ?
                                <CustomRadioGroup name="cudzoziemiecRodzaj" title={t('personal.cudzoziemiecRodzaj')}
                                    value={inputPersonalData.cudzoziemiecRodzaj}
                                    radioData={cudzoziemiecRodzaje} disabledState={personalDataDisabled}
                                    changeHandler={changeDataHandler} errors={formErrors} /> :
                                null
                            }
                        </Grid>
                        {(inputPersonalData && inputPersonalData.adresy && inputPersonalData.adresy.length) ?
                            inputPersonalData.adresy.map((adres, i) => {
                                return (<AdresComponent key={inputPersonalData.adresy.indexOf(adres)} value={adres}
                                    disabledState={personalDataDisabled}
                                    changeHandler={changeAdressDataHandler}
                                    telefonHandler={changeTelefonAdresStalyHandler}
                                    adresKorespondencyjnyHandler={adresKorespondencyjnyHandler}
                                    czyAdresKorespondencyjny={inputPersonalData.czyAdresKorespondencyjny}
                                    isMobile={isMobile} type={adres.typ}
                                    wojewodztwaDict={wojewodztwa}
                                    krajeDict={kraje}
                                    miastaDict={miasta}
                                    formErrors={formErrors}
                                    krajDlaKtoregoWyswietlamyWojewodztwo={krajDlaKtoregoWyswietlamyWojewodztwo}
                                    selectOptionSufix={selectOptionSufix}
                                />)
                            }) : null
                        }
                        <Grid container style={{ width: '100%' }} justifyContent="center">
                            <Grid style={{ width: '100%' }} container marginTop={'3vh'} justifyContent="center">
                                <Grid
                                    style={{ width: '100%', textAlign: 'left', marginBottom: 25, paddingLeft: '1.5vw' }}>
                                    <Typography fontWeight='bold' variant='h2'>{t('personal.infoZdrowie')}</Typography>
                                </Grid>
                                <Grid style={{ width: '100%', textAlign: 'left' }}>
                                    <CustomToggleButtonTrueFalseField name="czyStopienNiepelnosprawnosci"
                                        title={t('personal.czyStopienNiepelnosprawnosci')}
                                        label={t('personal.czyStopienNiepelnosprawnosci')}
                                        value={inputPersonalData.czyStopienNiepelnosprawnosci}
                                        disabledState={personalDataDisabled}
                                        changeHandler={changeDataHandler}
                                        errors={formErrors} />
                                    <CustomToggleButtonTrueFalseField name="czySpecjalnePotrzeby"
                                        title={t('personal.czySpecjalnePotrzeby')}
                                        label={t('personal.czySpecjalnePotrzeby')}
                                        value={inputPersonalData.czySpecjalnePotrzeby}
                                        disabledState={personalDataDisabled}
                                        changeHandler={changeDataHandler}
                                        errors={formErrors} />
                                </Grid>
                            </Grid>
                        </Grid>

                        {isShowCudzoziemiecRodzajPart(inputPersonalData) ?
                            <>
                                <Grid style={{
                                    width: '100%',
                                    textAlign: 'left',
                                    marginBottom: '2vh',
                                    marginTop: '2vh',
                                    paddingLeft: '1.5vw'
                                }}>
                                    <Typography fontWeight='bold' variant='h2'>{t('school.requiredFiles')}</Typography>
                                    <Typography variant='h6'>{t('school.filesNaming')}</Typography>
                                </Grid>
                                {filesInfo?.map((element, index) => {
                                    return (
                                        <React.Fragment key={element.attachmentInfo.typeId}>
                                            {(element.attachmentInfo.typeId === 40) ?
                                                (inputPersonalData.cudzoziemiecRodzaj && inputPersonalData.cudzoziemiecRodzaj != 9) ?
                                                    (popupArray.find(id => id == inputPersonalData.cudzoziemiecRodzaj)) ?
                                                        FileUploadComponent(index, getFileWithCaptionForForeigner(element), dispatchFilesInfo, dispatchPopup, inputPersonalData.cudzoziemiecRodzaj, personalDataDisabled) :
                                                        FileUploadComponent(index, getFileWithCaptionForForeigner(element), dispatchFilesInfo, undefined, undefined, personalDataDisabled) :
                                                    null :
                                                (index === 0 ? FileUploadComponent(index, element, dispatchFilesInfo, dispatchPopup, "paszport", personalDataDisabled) : FileUploadComponent(index, element, dispatchFilesInfo, dispatchPopup, "kartaPobytu", personalDataDisabled))
                                            }
                                        </React.Fragment>
                                    )
                                })}
                            </> : null}
                        <BackAndSaveButtons saveButtonFunction={handleOpenSaveConfirmDialog}
                                            readOnly={personalDataDisabled}/>
                    </Grid>
                </Box>

            </ThemeProvider>
        </div>
    );
}

