import React from 'react';
import {Button, Grid, TextField, ThemeProvider, Typography, useMediaQuery} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useStyles, useTheme} from '../css/Styles'
import FetchService from '../services/FetchService';
import {createNotificationForResponse, warningNotification} from '../utils/Notifications';
import {PagesEnums} from '../utils/CustomEnums';
import {NavLink} from 'react-router-dom';
import {getCredentials} from '../services/UserDataService';
import Paper from "@mui/material/Paper";


function StudentZone(props) {
   const theme = useTheme;
   const { t } = useTranslation();
   const isMobile = useMediaQuery(theme.breakpoints.down('phone'))
   const classes = useStyles();

   const [open, setOpen] = React.useState(false)
   const [codeValue, setCodeValue] = React.useState("")
   const [filesId, setFilesId] = React.useState(0)

   const BootstrapButton = styled(Button)({
      // boxShadow: 'none',
      // textTransform: 'none',
      fontSize: 15,
      color: 'white',
      // border: '1px solid',
      borderColor: 'none',
      lineHeight: 1,
      minHeight: 60,
      backgroundColor: '#30376c',
      fontFamily: [
         "Arial",
         "sans-serif"
      ].join(','),
      '&:hover': {
         backgroundColor: theme.palette.secondary.hovered,
         borderColor: 'none',
         boxShadow: 'none',
      },
      // '&:active': {
      //   boxShadow: 'none',
      //   backgroundColor: '#f2f2f2',
      //   borderColor: 'none',
      // },
      // '&:focus': {
      //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      // },f
   });
   async function fetchCreateVerificationCode(){
      return await FetchService.sendVerificationCode()
   }

   
   async function fetchSetDecisionDate(){
      return await FetchService.setDecisionDate()
   }
   
   async function fetchIsVerificationCodeValid(code){
      return await FetchService.isVerificationCodeValid(code)
   }


   async function fetchDownloadFile(fileId) {
      return await FetchService.downloadFile(fileId);
   }
   
   async function fetchGetFilesInfo(modeKey,formName){
      return await FetchService.getFilesInfo(modeKey,formName);
   }
   

   function getFileIdByType(){
      fetchGetFilesInfo(null,'DECISIONS').then(response => {
         if(response){
            response.zawartosc?.forEach(element => {
               if(element.attachmentMetadataList[0]){
                  if(element.attachmentMetadataList[0].type === 59){
                     setFilesId(element.attachmentMetadataList[0].fileId)
                  }
               }
            })
         }
      })
   }
   
  
   function handleClick(){
      getFileIdByType();
      setOpen(true);
      fetchCreateVerificationCode().then(response => {
         if(response.zawartosc === "OK"){
            createNotificationForResponse(response)
         }else {
            warningNotification("",response.zawartosc)
         }});
   }
   
   function handleSendAgain(){
      fetchCreateVerificationCode().then(response => {
         if(response.zawartosc === "OK"){
            createNotificationForResponse(response)
         }else {
            warningNotification("",response.zawartosc)
         }});
   }
   const handleChange = (event) => {
      setCodeValue(event.target.value);
   };
   
   function handleConfirm(){
      fetchIsVerificationCodeValid(codeValue).then(response => {
         if(response.zawartosc){
            fetchDownloadFile(filesId).then(response => {
               if(response.zawartosc){
                  //TODO ustawwić typ pobieranego pliku na ten z request - błąd pobierania na firefox, gdy np. dodano plik jpg 
                  const linkSource = `data:application/pdf;base64,${response.zawartosc?.data}`;
                  const element = document.createElement('a');
                  document.body.appendChild(element);
                  element.href = linkSource;
                  element.target = '_self';
                  element.download = response.zawartosc?.fileName;
                  element.click();
                  fetchSetDecisionDate()
                  createNotificationForResponse(response)
                  setOpen(false)
               }else if(response.wiadomosc==='school.missing_file'){
                  warningNotification("",t('school.missing_file'))
               } else{
                  warningNotification("",response.wiadomosc)
               }

            })
         }});
   }

   function handleClose() {
      setOpen(false)
   }

   /*
   * W strefie studenta są 3 sekcje.
   W pierwszej sekcji mamy numer albumu, który pokazuje się jak konto zostało aktywowane. Jeżeli konto nie jest aktyowawne to pojawia się link do aktywacja.p.lodz.pl

   Pobieranie plików - na ten moment jest wpis na listę, który powinien być aktywny jeżeli jest plik. W przyszłości będzie do pobrania skierowanie na badanie lekarskie.
   * */

   return (
      <ThemeProvider theme={theme}>
         <div style={{ width: '100%', height: 'auto', textAlign: 'center'}}>
            <Grid container sx={{ width: '80%', height: '100%', margin: 'auto' }} alignItems='center' justifyContent='center' direction={'column'}>
               <Grid container item direction='column' spacing={"10px"} style={{width: "100%"}}>
                  <Grid item sx={{marginTop: "10px"}}>
                     <Paper>
                        <Typography sx={{padding: "20px", fontWeight: "bold"}}>
                           {t("studentZone.downloadCOnfirmationFile")}
                        </Typography>

                        <Grid container direction='column' alignItems='center'>
                           <BootstrapButton onClick={handleClick} sx={{
                              width: isMobile ? '80%' : '40%',
                              marginTop: '3vh',
                              marginBottom: '2vh'
                           }} variant='standard'>{t("studentZone.download")}</BootstrapButton>
                        </Grid>
                     </Paper>
                  </Grid>
                  <Grid item>

                     <Paper>

                        <Typography sx={{padding: "20px", fontWeight: "bold"}}>
                           {t("activationStudentAccount.activation")}</Typography>

                        <Grid container direction='column' alignItems='center'>
                           <BootstrapButton onClick={() => {
                              window.location.href = "http://aktywacja.p.lodz.pl/";
                           }} sx={{width: isMobile ? '80%' : '40%', marginTop: '3vh', marginBottom: '2vh'}}
                                            variant='standard'>{t("activationStudentAccount.goTo")}</BootstrapButton>
                        </Grid>
                     </Paper>
                  </Grid>

               </Grid>
               <Grid container style={{ width: '100%', height: 'auto' }} justifyContent={isMobile ? "center": "end"}>
                  <Button
                     sx={{
                        fontWeight: 'bold',
                        margin: 1,
                        color: theme.palette.primary.main,
                        backgroundColor: theme.palette.secondary.main,
                        '&:hover':
                        {
                           backgroundColor: theme.palette.secondary.hovered,
                           borderColor: 'none', boxShadow: 'none',
                        },
                     }
                     }
                     component={NavLink}
                     to={getCredentials() ? PagesEnums.MAIN.link : PagesEnums.HOME.link}
                     variant='contained'>
                     {t('backButton')}
                  </Button>
               </Grid>
               <Dialog open={open} onClose={handleClose}>
                  <DialogTitle style={{ fontWeight: 'bold', fontSize: 20, color: theme.palette.secondary.main }}>{t('studentZone.dialogTitle')}</DialogTitle>
                  <DialogContent>
                     <Grid container sx={{ width: '100%' }} alignItems='center' justifyContent='center' direction={'column'}>
                        <DialogContentText>
                           {t('studentZone.dialogText')}
                        </DialogContentText>
                        <TextField
                           autoFocus
                           className={classes.customTextField}
                           sx={{ width: isMobile ? '90%' : '40%', marginTop: '2vh' }}
                           variant='outlined'
                           onChange={handleChange}
                        />
                     </Grid>
                  </DialogContent>
                  <DialogActions>
                     <Button
                        sx={{
                           fontWeight: 'bold',
                           margin: 1,
                           height: isMobile ? '5.5vh' : '4vh',
                           color: theme.palette.primary.main,
                           backgroundColor: theme.palette.secondary.main,
                           '&:hover':
                           {
                              backgroundColor: theme.palette.secondary.hovered,
                              borderColor: 'none', boxShadow: 'none',
                           },
                        }
                        }
                        onClick={handleSendAgain}
                        variant='contained'>
                        {t('studentZone.sendAgain')}
                     </Button>
                     <Button
                        sx={{
                           fontWeight: 'bold',
                           margin: 1,
                           height: isMobile ? '5.5vh' : '4vh',
                           color: theme.palette.primary.main,
                           backgroundColor: theme.palette.secondary.main,
                           '&:hover':
                           {
                              backgroundColor: theme.palette.secondary.hovered,
                              borderColor: 'none', boxShadow: 'none',
                           },
                        }
                        }
                        onClick={handleConfirm}
                        variant='contained'>
                        {t('studentZone.confirm')}
                     </Button>
                  </DialogActions>
               </Dialog>
            </Grid>
         </div>
      </ThemeProvider>
   )
}

export default StudentZone
