import React, {useContext, useEffect} from 'react';
import {useDropzone} from 'react-dropzone';
import {isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import PhotoManagementContext from '../../PhotoManagementContext';

import {handleErrorCodes} from './errorCodesHandler';

import {photoValidationRules, photoValidationRulesApi} from '../../photoValidationRules';

import {UploadPhotoButton, UploadPhotoContainer, UploadPhotoDropdown} from './style';

import FetchService from '../../../../services/FetchService';
import {Box, Link, Typography, useMediaQuery} from '@material-ui/core';
import {useTheme} from '../../../../css/Styles';
import CircularProgress from '@mui/material/CircularProgress';


const componentNameDropzoneDisabled = "link"

const UploadPhoto = () => {
  const { t } = useTranslation();
  const theme = useTheme;
  const { handleNextStep, setPhotoFile } = useContext(PhotoManagementContext);
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    maxFiles: photoValidationRules.fileMaxCount,
    maxSize: photoValidationRules.fileMaxSize,
    accept: photoValidationRules.fileExtention,
    useFsAccessApi: false, //bez tego ustawienia na linuxie w chromie nie działa guzik przesyłania zdjęcia
  });

  const [photo, setPhoto] = React.useState(null);
  const [isPhotoSaved, setIsPhotoSaved] = React.useState(false);
  const [isPhotoAccepted, setIsPhotoAccepted] = React.useState(false)
  const [loadingPhoto, setLoadingPhoto] = React.useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'))

  async function checkIfPhotoExists() {
    return await FetchService.checkIfPhotoExists()
  }

  async function getSavedPhoto() {
    return await FetchService.getSavedPhoto()
  }

  
  async function getPhotoStatus() {
    return await FetchService.getPhotoStatus()
  }

  useEffect(() => {
    checkIfPhotoExists().then(response => {
      if (response && response.zawartosc) {
        setIsPhotoSaved(true)
        setLoadingPhoto(true)
        getSavedPhoto().then(response => {
          if (response && response.zawartosc) {
            setPhoto(response.zawartosc)  
            setLoadingPhoto(false)
            getPhotoStatus().then(response => {
              if(response && response.zawartosc){
                setIsPhotoAccepted(response.zawartosc)
              }
            })
          }
        })
      }
    })

  }, []);

  useEffect(() => {
    if (!isEmpty(acceptedFiles) && isEmpty(fileRejections)) {
      setPhotoFile(acceptedFiles[0]); // pobierany jest pierwszy element, bo zawsze tylko jeden plik jest w `acceptedFiles`
      handleNextStep();
    }

    handleErrorCodes(fileRejections);
  }, [fileRejections, acceptedFiles, handleNextStep, setPhotoFile]);

  return (
    <>
      {isPhotoSaved ?
        <>
          <Typography variant='h5'>{t('cropPhoto.currentSavedPhoto')}</Typography>
          <Typography variant='subtitle2' style={{display: 'block', color: 'red'}}>{isPhotoAccepted? t('main.photo.acceptedPhoto') : t('cropPhoto.newSaveAlert')}</Typography>

          <Box style={{padding: '10px'}}>
            {loadingPhoto ?
              <CircularProgress /> :
                <img style={{width: isMobile ? '100%' : '20%'}} src={`data:image/jpeg;base64,${photo}`}
                     alt='idCardPhoto'/>
            }
          </Box>
        </> :
        null
      }
      {!isPhotoAccepted? <UploadPhotoContainer  disabled={isPhotoAccepted} component='section'>
        <UploadPhotoDropdown    {...getRootProps({
          onClick: event => (event && event.target.name === componentNameDropzoneDisabled) ? event.stopPropagation() : null
        })}>
          <input {...getInputProps()} />
          <UploadPhotoButton variant='contained' startIcon={<CloudUploadIcon />} >
            {t('cropPhoto.photoManagement.uploadAreaTextButton')}
          </UploadPhotoButton>
          <p>{t('cropPhoto.photoManagement.uploadAreaTextDrop')}</p>
          <em>
            {t('cropPhoto.photoManagement.uploadAreaTextRules1', {width: photoValidationRulesApi.widthMin, height: photoValidationRulesApi.heightMin})}
            <Typography component={Link} name={componentNameDropzoneDisabled}
                        href="https://rekrutacja.p.lodz.pl/sites/default/files/2024-05/Wymagania_dotyczace_zdjecia_do_wgrania_w_Portalu_Kandydata.pdf"
                        target="_blank">
                {t('cropPhoto.photoManagement.uploadAreaTextRules2')}
              </Typography>
            {t('cropPhoto.photoManagement.uploadAreaTextRules3')}
          </em>
        </UploadPhotoDropdown>
      </UploadPhotoContainer> :
      null}
    </>
  );
};

export default UploadPhoto;
