import React, {Fragment, useContext, useEffect, useState} from 'react';
import {useStyles, useTheme} from '../css/Styles';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    ThemeProvider,
    Tooltip,
    Typography
} from '@material-ui/core';
import MenuTile from '../templates/MenuTile';
import {useTranslation} from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import {PagesEnums} from "../utils/CustomEnums";
import {NavLink} from 'react-router-dom'
import FetchService from '../services/FetchService';
import MessagesFetchService from './messages/FetchServiceMessages';
import LinearProgress from '@mui/material/LinearProgress';
import {getIdWithoutPrefix} from '../services/UserDataService';
import DialogComponent from '../templates/DialogComponent';
import Popup, {popupReducer} from './popup/Popup';
import {DatesContext} from './GlobalDatesContext';
import {getAccessStorageValue, modifyAccessStorage} from '../utils/AppUtils';
import {createNotificationForResponse} from "../utils/Notifications";


const initialLockState = {
    personalData: {
        data: false, photo: false, announcements: false, activation: false
    }, etap_1: {
        school: false, field: false, qualification: false, studentZone: false
    }, etap_2: {
        school: false, field: false, qualification: false, studentZone: false
    }, konkursD: {
        school: false, field: false, qualification: false, studentZone: false
    }, konkurs_D_L: {
        school: false, field: false, qualification: false, studentZone: false
    }, zagranica: {
        school: false, field: false, qualification: false, studentZone: false
    }, zagranica_st_1: {
        school: false, field: false, qualification: false, studentZone: false
    }, zagranica_st_2: {
        school: false, field: false, qualification: false, studentZone: false
    }, zagranica_st_I_II_tura: {
        school: false, field: false, qualification: false, studentZone: false
    }, zagranica_st_II_II_tura: {
        school: false, field: false, qualification: false, studentZone: false
    },
    zagranica_st_I_III_tura: {
        school: false, field: false, qualification: false, studentZone: false
    },
    zagranica_st_II_III_tura: {
        school: false, field: false, qualification: false, studentZone: false
    }
    , zagranica_st_I_I_tura: {
        school: false, field: false, qualification: false, studentZone: false
    }, zagranica_st_II_I_tura: {
        school: false, field: false, qualification: false, studentZone: false
    }, secondDegreeForeign: {
        school: false, field: false, qualification: false, studentZone: false
    }, studia_dr: {
        school: false, field: false, qualification: false, studentZone: false
    }
}

function checkDate(startDate, endDate) {
    let today = new Date()
    let sDate = new Date(startDate)
    let eDate = new Date(endDate)
    return !((sDate < today) && (today < eDate))
}

export default function Main() {

    const theme = useTheme;
    const classes = useStyles();
    const {t} = useTranslation();


    const [popup, dispatchPopup] = React.useReducer(popupReducer, null)
    const [chosenRecruitmentModes, setChosenRecruitmentModes] = React.useState()


    const [renderKey, setRenderKey] = React.useState(false)
    const [anchorElement, setAnchorElement] = React.useState(null);
    const open = Boolean(anchorElement);
    const [loading, setLoading] = React.useState(false)
    const [openDialog, setOpenDialog] = React.useState(false)
    const [photoStatus, setPhotoStatus] = React.useState(false)

    //delete
    const [deleteDialog, setDeleteDialog] = React.useState(false)
    const [currentDeleteId, setCurrentDeleteId] = React.useState(0)

    const [availableDegrees, setAvailableDegrees] = React.useState([])
    const [chosenDegrees, setChosenDegrees] = React.useState([])
    const [lockState, setLockState] = React.useState(initialLockState)
    const [hasPhoto, setHasPhoto] = React.useState(false)
    const [hasPersonalData, setHasPersonalData] = React.useState(false)
    const [tileLockDialog, setTileLockDialog] = React.useState(null)

    const [abroadSelection, setAbroadSelection] = React.useState(0)

    const [addDegreeButtonDisabled, setAddDegreeButtonDisabled] = React.useState(false)

    const [messagesCount, setMessagesCount] = React.useState(0);

    const datesContext = useContext(DatesContext)
    const [dates, setDates] = React.useState()

    const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(true);

    async function fetchPersonalData() {
        return await FetchService.getAddressPersonal()
    }

    async function fetchAddRecruitmentMode(modeKey) {
        return await FetchService.addRecruitmentMode(modeKey)
    }

    async function fetchDeleteRecruitmentMode(modeKey) {
        return await FetchService.deleteRecruitmentMode(modeKey)
    }

    async function fetchAvailableRecruitmentModes() {
        return await FetchService.getAvailableRecruitmentModes()
    }

    async function fetchChosenRecruitmentModes() {
        return await FetchService.getChosenRecruitmentModes()
    }

    async function fetchCountUnreadMessages() {
        return await MessagesFetchService.countUnreadMessages(getIdWithoutPrefix())
    }

    async function fetchGetDates(trybSemester) {
        return await FetchService.getDates(trybSemester)
    }

    useEffect(() => {
        // setLoading(true)

        fetchCountUnreadMessages().then(response => {
            if (response && response.zawartosc) {
                setMessagesCount(response.zawartosc)
            } else {
                setMessagesCount(0)
            }
        })

        fetchPersonalData().then(response => {
            let abroadSelection = response?.zawartosc.userPersonalData.selectionId
            setAbroadSelection(abroadSelection)
            if (response && response.zawartosc) {
                setAddDegreeButtonDisabled(false)
            } else {
                setAddDegreeButtonDisabled(true)
            }
            reloadRecruitmentModes(abroadSelection)
        })

        setDataAccess()

    }, [])

    useEffect(() => {
        setDatesAccess()
    }, [dates])

    useEffect(() => {
        chosenRecruitmentModes?.forEach(element => {
            if (availableDegrees?.filter(a => a.key === element.key).length !== 0) {
                appendChosenDegree(availableDegrees.filter(a => a.key === element.key)[0], element.candidateType, abroadSelection)
            }
        })
        setLoading(false)

    }, [lockState])


    function setDatesAccess() {

        let tempLockState = {...lockState}
        let activationDates = []

        for (let key in dates) {
            if (dates.hasOwnProperty(key) && tempLockState[key]) {
                //wyniki kwalifikacji
                if (new Date(dates?.currentDateTime) >= new Date(dates[key]?.examResultsAnnouncment)) {
                    tempLockState[key].qualification = false
                } else {
                    tempLockState[key].qualification = true
                }

                //strefa studenta
                if (new Date(dates?.currentDateTime) >= new Date(dates[key]?.decisionsPublication)) {
                    tempLockState[key].studentZone = false
                } else {
                    tempLockState[key].studentZone = true
                }

                //aktywacja konta uczelnianego
                activationDates.push(new Date(dates[key]?.accountActivation))

            }
        }

        setLockState(tempLockState)
    }

    function setDataAccess() {
        const accessObject = getAccessStorageValue()
        let tempLockState = {...lockState}

        let schoolAccess = true
        if (accessObject?.personalData === true) {
            tempLockState.personalData.photo = false
        } else {
            tempLockState.personalData.photo = true
        }

        setHasPhoto(accessObject?.photo === true)
        setHasPersonalData(accessObject?.personalData === true)
        if (accessObject?.personalData === true && accessObject?.photo === true) {
            schoolAccess = false

        }

        for (var key in tempLockState) {
            tempLockState[key].school = schoolAccess
            if (accessObject?.hasOwnProperty(key) && accessObject[key] === true) {
                tempLockState[key].field = false
            } else {
                tempLockState[key].field = true
            }

        }
        setLockState(tempLockState)
    }

    async function reloadRecruitmentModes(abroadSelection) {
        const availableModes = await fetchAvailableRecruitmentModes()
        if (availableModes && availableModes.zawartosc) {
            setAvailableDegrees(availableModes.zawartosc)
        }

        const chosenRecruitmentModes = await fetchChosenRecruitmentModes()
        let tmpDegrees = []
        let tmpDates
        for (const element of chosenRecruitmentModes.zawartosc) {
            let tryb = element.recruitmentCandidateMode.key;
            tmpDegrees.push(element.recruitmentCandidateMode)
            const data = await datesContext.fetchGetMoreDates(tryb, datesContext.currentSemester);
            tmpDates = data;
        }
        setChosenRecruitmentModes(tmpDegrees)
        setDates(tmpDates)
    }

    function appendChosenDegree(degree, candidateType, abroadSelection) {
        var tempDegrees = chosenDegrees
        let lock = null
        lock = lockState[degree.key]
        let thirdTitle = (degree.key === 'studia_dr') ? t('main.scientificFieldSelection') : t('main.courseSelection');

        if (tempDegrees.filter(e => e.key === degree.key).length === 0) {
            if (degree.key != "studia_dr") { //TODO remove this egdecase in next recrutation
                tempDegrees.push(<DegreeTilesRow id={degree.key} key={degree.key} title1={"main.degree." + degree.key}
                                                 candidateType={candidateType} abroadSelection={abroadSelection}
                                                 title2={t('main.finishedSchool')} title3={thirdTitle}
                                                 title4={t('main.qualification')} title5={t('main.studentZone')}
                                                 lockRow={lock}/>)
            }
        }

        setChosenDegrees(tempDegrees)
        setRenderKey(!renderKey)
    }

    const handleDegreesMenu = (event) => {
        setAnchorElement(event.target);
    };

    const handleAddDegree = (degree) => {
        fetchAddRecruitmentMode(degree.key).then(response => {
            if (response) {
                if (response.status === 200) {
                    appendChosenDegree(degree, degree.candidateType, abroadSelection)
                    window.location.reload();
                } else {
                    createNotificationForResponse(response)
                }
            }
        })
        handleCloseMenu();
        reloadRecruitmentModes()
    };

    const handleDeleteDegree = () => {
        setChosenDegrees(current => current.filter(degree => {
            return degree.key !== currentDeleteId;
        }),);
        setDeleteDialog(false)
        modifyAccessStorage(currentDeleteId, false)
        fetchDeleteRecruitmentMode(currentDeleteId)
    };

    const handleDeleteDialog = (id) => {
        setDeleteDialog(true)
        setCurrentDeleteId(id)
    }

    //Dialog for disabled title clicked

    const handleDisabledTitleClickedDialog = (tileLockDialog) => {
        setTileLockDialog(tileLockDialog)
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    const handleCloseMenu = () => {
        setAnchorElement(null)
    }

    const [isOpen, setIsOpen] = useState(undefined);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    function DegreeTilesRow({
                                id,
                                title1,
                                title2,
                                title3,
                                title4,
                                title5,
                                firstTile,
                                lockRow,
                                candidateType,
                                abroadSelection
                            }) {
        return (<ThemeProvider theme={theme}>
            <Grid key={renderKey} className={classes.degreeRow} container alignItems='center'
                  justifyContent='space-evenly' direction='row'>
                {firstTile ? <Button sx={{textTransform: 'none'}}
                                     onClick={() => dispatchPopup({type: 'OPEN', payload: 'MAIN.RULES'})}>
                    <Paper
                        sx={{backgroundColor: theme.palette.primary.main, color: theme.palette.secondary.main}}
                        className={classes.informationTile} elevation={5}>
                        <Typography width={'80%'} color={theme.palette.secondary.main} fontWeight='bold'
                                    variant='h6'>{title1}</Typography>
                    </Paper>
                </Button> : <Box>
                    <Box sx={{padding: 1}}>
                        <Paper sx={{
                            backgroundColor: theme.palette.primary.main, color: theme.palette.secondary.main
                        }} className={classes.informationTile} elevation={5}>
                            <Grid sx={{height: '100%'}} container direction='column' alignItems='center'
                                  justifyContent='space-between'>
                                <Box sx={{
                                    display: 'flex',
                                    width: '100%',
                                    alignItems: 'flex-end',
                                    justifyContent: 'flex-end',
                                    visibility: isDeleteButtonDisabled ? 'hidden' : 'visible'
                                }}>
                                    <IconButton id={id} color="delete" onClick={() => handleDeleteDialog(id)}>
                                        <ClearIcon/>
                                    </IconButton>
                                </Box>
                                <Typography width={'80%'} color={theme.palette.secondary.main} fontWeight='bold'
                                            variant='h6'>{t(title1)}</Typography>
                                <Box sx={{
                                    display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center'
                                }}>
                                    <ClearIcon color="primary"/>
                                </Box>
                            </Grid>
                        </Paper>
                    </Box>
                </Box>}
                <NavLink
                    style={{textDecoration: 'none'}}
                    to={(firstTile ? lockRow.data : lockRow.school) ? '' : ((firstTile ? lockRow.data : lockRow.school) === lockRow.data ? PagesEnums.PERSONAL.link : PagesEnums.SCHOOL.link + '/' + id)}
                    state={{degree: id, candidateType: candidateType, abroadSelection: abroadSelection}}
                    onClick={(firstTile ? lockRow.data : lockRow.school) ? () => handleDisabledTitleClickedDialog(firstTile ? 'personalData' : 'school') : null}
                >
                    <MenuTile title={title2} locked={firstTile ? lockRow.data : lockRow.school}/>
                </NavLink>
                <NavLink
                    style={{textDecoration: 'none'}}
                    to={(firstTile ? lockRow.photo : lockRow.field) ? '' : ((firstTile ? lockRow.photo : lockRow.field) === lockRow.photo ? PagesEnums.CROP_PHOTO.link : PagesEnums.COURSE_SELECTION.link + '/' + id)}
                    state={{degree: id}}
                    onClick={(firstTile ? lockRow.photo : lockRow.field) ? () => handleDisabledTitleClickedDialog(firstTile ? 'photo' : 'field') : null}

                >
                    <MenuTile title={title3} locked={firstTile ? lockRow.photo : lockRow.field}/>
                </NavLink>
                <NavLink
                    style={{textDecoration: 'none'}}
                    to={(firstTile ? lockRow.announcements : lockRow.qualification) ? '' : ((firstTile ? lockRow.announcements : lockRow.qualification) === lockRow.announcements ? PagesEnums.MESSAGES.link : PagesEnums.RECRUITMENT_RESULTS.link + '/' + id + '/' + candidateType)}
                    state={{degree: id, candidateType: candidateType}}
                    onClick={(firstTile ? lockRow.announcements : lockRow.qualification) ? () => handleDisabledTitleClickedDialog((firstTile ? 'other' : 'qualification')) : null}
                >
                    <MenuTile title={title4} locked={firstTile ? lockRow.announcements : lockRow.qualification}
                              messagesCount={firstTile ? messagesCount : 0}/>
                </NavLink>
                <NavLink
                    style={{textDecoration: 'none'}}
                    to={(firstTile ? lockRow.activation : lockRow.studentZone) ? '' : ((firstTile ? lockRow.activation : lockRow.studentZone) === lockRow.activation ? PagesEnums.TODO_LIST.link : PagesEnums.STUDENT_ZONE.link)}
                    state={{degree: id}}
                    onClick={(firstTile ? lockRow.activation : lockRow.studentZone) ? () => handleDisabledTitleClickedDialog('other') : null}
                >
                    <MenuTile title={title5} locked={firstTile ? lockRow.activation : lockRow.studentZone}/>
                </NavLink>
            </Grid>
            {Popup(popup, dispatchPopup) /*popup*/}
        </ThemeProvider>);
    }

    return (<Fragment>
        {lockState ?

            <ThemeProvider theme={theme}>
                {loading ? <Grid container style={{width: '100%'}} alignItems='center' justifyContent='center'>
                    <LinearProgress sx={{width: '80%'}} color="secondary"/>
                </Grid> : <div style={{flex: 'auto'}}>
                    <Box key={renderKey} sx={{
                        display: 'flex',
                        width: '100%',
                        padding: 2,
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                        flexDirection: 'column'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            flexDirection: 'column'
                        }}>
                            <DegreeTilesRow title1={t('main.recrutationRulesTile')}
                                            title2={t('main.personalDataTile')} title3={t('main.idPhotoTile')}
                                            title4={t('main.announcementsTile')}
                                            title5={t('main.todoTitle')} firstTile={true}
                                            lockRow={lockState.personalData}/>
                            {chosenDegrees}
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            marginBottom: '25px'
                        }}>
                            <Tooltip
                                title={addDegreeButtonDisabled ? t('main.noPersonalDataButtonDisabled') : ''}>
                                <span>
                                    <Button
                                        sx={{
                                            color: theme.palette.primary.main,
                                            backgroundColor: theme.palette.secondary.main,
                                            '&:hover': {
                                                backgroundColor: theme.palette.secondary.hovered,
                                                borderColor: 'none',
                                                boxShadow: 'none',
                                            },
                                        }}
                                        variant='contained' disabled={addDegreeButtonDisabled}
                                        onClick={handleDegreesMenu}
                                        startIcon={<AddIcon/>}>{t('main.addDegreeButton')}
                                    </Button>
                                </span>
                            </Tooltip>
                        </Box>
                    </Box>


                    <Menu
                        id="basic-menu"
                        anchorEl={anchorElement}
                        open={open}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                            vertical: 'bottom', horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top', horizontal: 'center',
                        }}
                    >

                        {availableDegrees?.map((element, i) => {
                            if (element.key != "studia_dr" && (element.recruitmentEndDate > datesContext.dates.currentDateTime)) { //TODO remove this egdecase (element.key != "studia_dr") when we add support for it.
                                return (<MenuItem
                                        disabled={lockState[element.key]?.school || checkDate(element.recruitmentStartDate, element.recruitmentEndDate)}
                                        key={i} sx={{fontWeight: 'bold', justifyContent: 'center'}} onClick={() => {
                                        handleAddDegree(element)
                                    }}>
                                        {(hasPhoto ? t('main.degree.' + element.key) : t('main.addPhoto'))} || {element.recruitmentStartDate.slice(0, 10)} - {element.recruitmentEndDate.slice(0, 10)}
                                    </MenuItem>


                                )
                            }
                        })}
                    </Menu>
                    <Dialog
                        open={openDialog}
                        onClose={handleCloseDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {(tileLockDialog && tileLockDialog === 'school') && t('main.school.lockedTileInfo')}
                                {(tileLockDialog && tileLockDialog === 'field') && t('main.field.lockedTileInfo')}
                                {(tileLockDialog && tileLockDialog === 'other') && t('main.lockedTileInfo')}
                                {(tileLockDialog && tileLockDialog === 'qualification') && t('main.lockedQualificationInfo')}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                sx={{
                                    fontWeight: 'bold',
                                    color: theme.palette.primary.main,
                                    backgroundColor: theme.palette.secondary.main,
                                    '&:hover': {
                                        backgroundColor: theme.palette.secondary.hovered,
                                        borderColor: 'none',
                                        boxShadow: 'none',
                                    },
                                }}
                                variant='contained' onClick={handleCloseDialog} autoFocus>OK</Button>
                        </DialogActions>
                    </Dialog>
                </div>}

            </ThemeProvider> : null}

        {/* ------------------------DIALOGI------------------------ */}
        <DialogComponent
            title={t('school.confirmDelete')}
            content={t('school.deleteMessage')}
            visible={deleteDialog}
            buttons={[{
                buttonLabel: t('default.cancel'), buttonAction: () => setDeleteDialog(false)
            }, {buttonLabel: t('yes'), buttonAction: () => handleDeleteDegree()}]}
        />
        {/* ------------------------DIALOGI------------------------ */}
    </Fragment>);
}