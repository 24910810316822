import React from "react";
import {Box, Button, Grid, IconButton, useMediaQuery} from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import {useTranslation} from 'react-i18next';
import 'react-phone-input-2/lib/style.css'
import {useTheme} from '../../css/Styles'
import FetchService from '../../services/FetchService';
import CircularProgress from '@mui/material/CircularProgress';
import DialogComponent from "../../templates/DialogComponent";

export default function PaymentComponent({
                                             course,
                                             handleDeleteSelectedCourse,
                                             handleGetCandidateCourses,
                                             recruitmentModeKey,
                                             index,
                                             disabled
                                         }) {

    const { t } = useTranslation();
    const theme = useTheme;
    const isMobile = useMediaQuery(theme.breakpoints.down('tablet'))
    const [loading, setLoading] = React.useState(false)
    const [isOpen, setOpen] = React.useState(false)

    const recruitmentCourseStatuses = [
        { id: 'PAY_BUTTON', buttonName: 'courseSelection.btnPay', disabled: false},
        { id: 'PAYMENT_STATUS', buttonName: 'courseSelection.btnPaymentInProgress', disabled: false},
        { id: 'RECRUITMENT_NOT_PAID', buttonName: 'courseSelection.btnNotPayment', disabled: true},
        { id: 'PAYMENT_END', buttonName: 'courseSelection.btnPaid', disabled: true}]


    async function pay(recruitmentModeKey, courseId) {
        return await FetchService.createPayment(recruitmentModeKey, courseId)
    }

    function redirectToPayment(course) {
        let paymentUrl = course.recruitment?.paymentStatus?.redirectUri
        if(paymentUrl) {
            window.open(paymentUrl, '_blank', 'noopener,noreferrer');
        }
    }

    const handleDeleteCourse = (course) => {
        if (course.recruitment.status === 'PAYMENT_END'){
            setOpen(true)
        }else{
            setLoading(true);
            handleDeleteSelectedCourse(course);
            setLoading(false);
        }
    }
    
    const buttonClickHandler = (course) => {
        switch (resolveButtonConfig(course)?.id) {
            case 'PAY_BUTTON':
                setLoading(true)
                pay(recruitmentModeKey, course.courseId)
                    .then(response => {
                        setLoading(false);
                        if(response.zawartosc && response.zawartosc.redirectUri) {
                            window.open(response.zawartosc.redirectUri, '_self', 'noopener,noreferrer');
                        }
                        handleGetCandidateCourses();
                    })
                break;
            case 'PAYMENT_STATUS':
                redirectToPayment(course)
                break;
            default:
        }
    }

    const resolveButtonConfig = (course) => {
        let recruitmentButton = recruitmentCourseStatuses.find(status => status.id === course.recruitment?.status)
        let recruitmentButtonDetails = { ...recruitmentButton };
        if(recruitmentButtonDetails.id === 'PAYMENT_END') {
            //TODO zmienić numer transakcji na ten z backendu
            recruitmentButtonDetails.buttonName = t(recruitmentButtonDetails.buttonName, {transactionNumber: course.recruitment?.paymentStatus?.orderId})
        }
        recruitmentButtonDetails.buttonName = t(recruitmentButtonDetails.buttonName)
        if (!disabled && (recruitmentButtonDetails.id === 'PAY_BUTTON' || (recruitmentButtonDetails.id === 'PAYMENT_END' && course.recruitment.isParent === 0 && course.qualificationStatus === 0))) {
            recruitmentButtonDetails.deteleIcon = true;
        } else{
            recruitmentButtonDetails.deteleIcon = false;
        }
        return recruitmentButtonDetails;
    }

    return (
        <>
            <Box sx={{ display: 'flex', width: isMobile ? '100%' : '50%', alignItems: 'center', justifyContent: isMobile ? 'center' : 'flex-start', flexDirection: 'row' }}>
                <Grid style={{ width: '100%' }} container direction={isMobile ? 'column' : 'row'} justifyContent="space-between" >
                    {loading ?
                        <Grid container style={{ width: '100%' }} alignItems='center' justifyContent='center'>
                            <CircularProgress sx={{ width: '80%' }} color="secondary" />
                        </Grid> :
                        <Grid style={{ width: isMobile ? '100%' : '80%' }}>
                            <Grid container direction='row' justifyContent='space-between' alignItems='left'
                                  style={{width: '100%', textAlign: 'left'}}>
                                <Box style={{
                                    display: 'flex',
                                    width: isMobile ? '50%' : '20%',
                                    justifyContent: 'flex-end'
                                }}>
                                    {resolveButtonConfig(course)?.deteleIcon ?
                                        <IconButton sx={{ marginRight: '0.5vw', marginLeft: isMobile ? 0 : '0.5vw', padding: '0' }}
                                                    id={'button' + index}
                                                    color="secondary"
                                                    onClick={() => { handleDeleteCourse(course) }}>
                                            <DeleteIcon sx={{ fontSize: 35 }} />
                                        </IconButton>
                                        : null }
                                </Box>
                                <Box style={{
                                    display: 'flex',
                                    width: isMobile ? (resolveButtonConfig(course)?.deteleIcon ? '50%' : "100%") : '80%',
                                    justifyContent: 'flex-start',
                                    alignItems: 'left'
                                }}>
                                    <Button
                                        sx={{
                                            fontWeight: 'bold',
                                            marginRight: '0.5vw',
                                            color: theme.palette.primary.main,
                                            backgroundColor: theme.palette.secondary.main,
                                            '&:hover':
                                                {
                                                    backgroundColor: theme.palette.secondary.hovered,
                                                    borderColor: 'none', boxShadow: 'none',
                                                },
                                            '&.Mui-disabled': {
                                                backgroundColor: theme.palette.additional.disabled,
                                                color: "#3c3c3c",
                                                
                                            }
                                        }}
                                        variant='contained'
                                        onClick={() => { buttonClickHandler(course) }}
                                        disabled={disabled || resolveButtonConfig(course)?.disabled}
                                    >
                                        {resolveButtonConfig(course)?.buttonName}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Box>         
            <DialogComponent
                title={t('courseSelection.conformDelete')}
                content={t('courseSelection.sureToDelete') + course.courseName + "." + t('courseSelection.moneyReturn')}
                visible={isOpen}
                buttons={[
                    {buttonLabel: t('courseSelection.delete'),red:'secondary' , buttonAction: () => handleDeleteSelectedCourse(course)},
                    {buttonLabel: t('default.cancel'),red:'secondary' , buttonAction: () => setOpen(false)}
                ]}
            />
        </>
    )
}