import {makeStyles} from '@material-ui/styles';
import {createTheme} from '@mui/material/styles';

export const useTheme = createTheme({
    palette: {
        primary: {
            //White
            main: '#ffffff',
            error: '#FF0000'
        },
        secondary: {
            //Dark blue
            main: '#30376c',
            hovered: '#3e488e',
            clicked: '#4d5ab8',
            error: '#FF0000'
        },
        additional: {
            //Light grey
            main: '#EBEBEB',
            disabled: '#a4a6a5'
        },
        delete: {
            //red
            main: '#d10202'
        },
        allow: {
            //green
            main: '#02c208'
        },
    },
    typography: {
        fontFamily: ['-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',].join(','),
        fontSize: 14,
        h1: {
            fontFamily: ['-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',].join(','),
            fontSize: 34
        },
        h2: {
            fontFamily: ['Arial', 'sans-serif'].join(','),
            fontSize: 20
        },
        h3: {
            fontFamily: ['Arial', 'sans-serif'].join(','),
            fontSize: 15
        },
        h4: {
            fontFamily: ['Arial', 'sans-serif'].join(','),
            fontSize: 13
        },
        h5: {
            fontFamily: ['Arial', 'sans-serif'].join(','),
            fontSize: 11
        },
        h6: {
            fontFamily: ['Arial', 'sans-serif'].join(','),
            fontSize: 17
        },
        h7: {
            fontFamily: ['Arial', 'sans-serif'].join(','),
            fontSize: 28
        },
        body1: {
            fontFamily: [
                "Arial",
                "sans-serif",
            ].join(','),
        },
        popupTitle: {
            fontFamily: ['Arial', 'sans-serif'].join(','),
            fontSize: '1rem',
            '@media (min-width:600px)': {
                fontSize: '1.2rem',
            },
        },
        popupContent: {
            fontFamily: ['Arial', 'sans-serif'].join(','),
            fontSize: '1rem',
            '@media (min-width:600px)': {
                fontSize: '1.2rem',
            },
        },
    },
    breakpoints: {
        values: {
            mobile: 0,
            phone: 700,
            tablet: 900
        },
    },
    overrides: {
        MuiPickersBasePicker: {
            pickerView: {
                backgroundColor: "red"
            }
        },
        MuiPickersDay: {
            day: {
                color: "light-gray",
                fontFamily: "\"Do Hyeon\", sans-serif",
                backgroundColor: "white",
                borderRadius: "0px",
            },
            container: {
                backgroundColor: "red"
            },
            daySelected: {
                backgroundColor: "",
                color: "light-gray"
            },
            dayDisabled: {
                color: "black",
            },
            current: {
                color: "",
            },
        },
    },


});

export const materialTable = createTheme({
    palette: {
        primary: {
            //White
            main: '#30376c',
            error: '#FF0000'
        },
        secondary: {
            //Dark blue
            main: '#30376c',
            hovered: '#3e488e',
            clicked: '#4d5ab8',
            error: '#FF0000',
            accepted: '#069628'
        },
        additional: {
            //Light grey
            main: '#EBEBEB',
            disabled: '#a4a6a5'
        },
        delete: {
            //red
            main: '#d10202'
        },
        allow: {
            //green
            main: '#02c208'
        }
    },
    typography: {
        fontFamily: ['-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',].join(','),
        fontSize: 14,
        h1: {
            fontFamily: ['-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',].join(','),
            fontSize: 34
        },
        h2: {
            fontFamily: ['Arial', 'sans-serif'].join(','),
            fontSize: 20
        },
        h3: {
            fontFamily: ['Arial', 'sans-serif'].join(','),
            fontSize: 15
        },
        h4: {
            fontFamily: ['Arial', 'sans-serif'].join(','),
            fontSize: 13
        },
        h5: {
            fontFamily: ['Arial', 'sans-serif'].join(','),
            fontSize: 11
        },
        h6: {
            fontFamily: ['Arial', 'sans-serif'].join(','),
            fontSize: 17
        },
        h7: {
            fontFamily: ['Arial', 'sans-serif'].join(','),
            fontSize: 28
        },
        body1: {
            fontFamily: [
                "Arial",
                "sans-serif",
            ].join(','),
        },
        popupTitle: {
            fontFamily: ['Arial', 'sans-serif'].join(','),
            fontSize: '1rem',
            '@media (min-width:600px)': {
                fontSize: '1.2rem',
            },
        },
        popupContent: {
            fontFamily: ['Arial', 'sans-serif'].join(','),
            fontSize: '1rem',
            '@media (min-width:600px)': {
                fontSize: '1.2rem',
            },
        },
    },
    breakpoints: {
        values: {
            mobile: 0,
            phone: 700,
            tablet: 900
        },
    },
    overrides: {
        MuiPickersBasePicker: {
            pickerView: {
                backgroundColor: "red"
            }
        },
        MuiPickersDay: {
            day: {
                color: "light-gray",
                fontFamily: "\"Do Hyeon\", sans-serif",
                backgroundColor: "white",
                borderRadius: "0px",
            },
            container: {
                backgroundColor: "red"
            },
            daySelected: {
                backgroundColor: "",
                color: "light-gray"
            },
            dayDisabled: {
                color: "black",
            },
            current: {
                color: "",
            },
        },
    },


});


export const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: "center",
        width: '100%',
        marginLeft: '5%',
        marginRight: '5%',
        overflow: 'auto'
    },
    headerWhite: {
        backgroundColor: useTheme.palette.additional.main,
    },
    headerLogo: {
        height: 90,
    },
    headerTypo: {
        color: useTheme.palette.secondary.main,
        padding: 5,
    },
    headerBlue: {
        backgroundColor: useTheme.palette.secondary.main,
    },
    subHeaderTypo: {
        padding: 5,
        color: useTheme.palette.primary.main
    },
    iconButtonWhite: {
        color: useTheme.palette.primary.main
    },
    textButton: {
        padding: 10
    },
    toggleButton: {
        '&.MuiToggleButton-root': {
            color: useTheme.palette.primary.main,
        }
    },
    footerTypo: {
        textAlign: 'left',
        textJustify: 'flex-start',
        width: '100%',
        color: useTheme.palette.primary.main,
        letterSpacing: 0.6,
        wordSpacing: 1,
    },
    footerBlue: {
        backgroundColor: useTheme.palette.secondary.main,
        display: 'flex',
        overflow: 'hidden'
    },
    footerText: {
        padding: 1.5,
        top: 0,
    },
    homeText: {
        color: useTheme.palette.secondary.main,
    },
    customTextField: {
        width: '100%',
        backgroundColor: useTheme.palette.primary.main,
        '& label.Mui-focused': {
            color: useTheme.palette.secondary.main,
        },
        '& label.Mui-focused.Mui-error': {
            color: useTheme.palette.delete.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: useTheme.palette.secondary.clicked,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'solid 2px',
                borderColor: useTheme.palette.secondary.main,
            },
            '&:hover fieldset': {
                borderColor: useTheme.palette.secondary.hovered,
            },
            '&.Mui-focused fieldset': {
                border: 'solid 2px',
                borderColor: useTheme.palette.secondary.clicked,
            },
        },
        '& .MuiOutlinedInput-root.Mui-error': {
            '& fieldset': {
                border: 'solid 2px',
                borderColor: useTheme.palette.delete.main,
            },
            '&:hover fieldset': {
                borderColor: useTheme.palette.delete.main,
            },
            '&.Mui-focused fieldset': {
                border: 'solid 2px',
                borderColor: useTheme.palette.delete.main,
            },
        },
        '& .MuiOutlinedInput-root.Mui-disabled': {
            '& fieldset': {
                border: 'solid 2px',
                borderColor: useTheme.palette.secondary.main,
            },
        },
        '& .MuiOutlinedInput-root.MuiOutlinedInput-input': {
            border: 'solid 2px',
            borderColor: useTheme.palette.secondary.main,
        }
    }, customSelect: {
        color: '#787878',
        width: '100%',
        backgroundColor: useTheme.palette.primary.main,
        height: '100%',
        ".MuiSelect-outlined": {
            borderColor: useTheme.palette.secondary.main,
            border: 'solid 2px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: useTheme.palette.secondary.main,
            border: 'solid 2px',
        },

        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
                "& fieldset": {
                    color: '#787878'
                },
                borderColor: useTheme.palette.secondary.main,
                border: 'solid 2px',
            },

    }, customInputLabel: {
        color: '#787878',
        "& .MuiFormLabel-root": {
            color: "green",   // Change this to any color you prefer.
        },
        '&.MuiSelect-root': {
            color: '#787878'
        },
        '&.MuiFormLabel-filled': {
            color: '#787878'
        }
    },
    customTextFieldError: {
        width: '100%',
        backgroundColor: useTheme.palette.primary.main,
        '& label.Mui-focused': {
            color: useTheme.palette.secondary.main,
        },
        '& label.Mui-focused.Mui-error': {
            color: useTheme.palette.delete.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: useTheme.palette.secondary.error,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'solid 2px',
                borderColor: useTheme.palette.secondary.error,
            },
            '&:hover fieldset': {
                borderColor: useTheme.palette.secondary.error,
            },
            '&.Mui-focused fieldset': {
                border: 'solid 2px',
                borderColor: useTheme.palette.secondary.error,
            },
        },
        '& .MuiOutlinedInput-root.Mui-error': {
            '& fieldset': {
                border: 'solid 2px',
                borderColor: useTheme.palette.delete.error,
            },
            '&:hover fieldset': {
                borderColor: useTheme.palette.delete.error,
            },
            '&.Mui-focused fieldset': {
                border: 'solid 2px',
                borderColor: useTheme.palette.delete.error,
            },
        },
        '& .MuiOutlinedInput-root.Mui-disabled': {
            '& fieldset': {
                border: 'solid 2px',
                borderColor: useTheme.palette.secondary.main,
            },
        },
        '& .MuiOutlinedInput-root.MuiOutlinedInput-input': {
            border: 'solid 2px',
            borderColor: useTheme.palette.secondary.main,
        }
    },
    paperButton: {
        textTransform: 'none'
    },
    tile: {
        minWidth: '200px',
        minHeight: '200px',
        width: '11vw',
        height: '11vw',
        alignItems: 'flex-end',
        textJustify: 'flex-start',
        justifyContent: 'flex-start',
        padding: '1vh',
        margin: '1vh'

    },
    informationTile: {
        display: 'flex',
        minWidth: '200px',
        minHeight: '200px',
        width: '11vw',
        height: '11vw',
        alignItems: 'center',
        textJustify: 'center',
        justifyContent: 'center',
        padding: '1vh',
    },
    addDegreeButton: {
        padding: 30
    },
    degreeRow: {
        marginBottom: '2vh'
    },
    resizeDaneOsobowe: {
        fontSize: 18,
        textAlign: 'left',
    },
    resizeDaneOsoboweCapital: {
        fontSize: 18,
        textAlign: 'left',
        textTransform: 'capitalize'
    },
    phoneInputField: {
        '& .form-control': {
            border: '2px solid',
            borderColor: useTheme.palette.secondary.main,
            backgroundColor: useTheme.palette.primary.main
        },
        '& .flag-dropdown': {
            border: '2px solid',
            borderColor: useTheme.palette.secondary.main,
            backgroundColor: useTheme.palette.primary.main
        },
    },
    phoneInputFieldError: {
        '& .form-control': {
            border: '2px solid',
            borderColor: useTheme.palette.secondary.error,
            backgroundColor: useTheme.palette.primary.main
        },
        '& .flag-dropdown': {
            border: '2px solid',
            borderColor: useTheme.palette.secondary.error,
            backgroundColor: useTheme.palette.primary.main
        },
    },
    daneOsoboweCheckboxField: {
        textAlign: 'left',
        display: 'flex',
        "& .MuiInputBase-root.Mui-disabled": {
            color: "rgba(0, 0, 0, 1)"
        },
        "& .Mui-disabled": {
            color: "rgba(0, 0, 0, 1)"
        }
    },
    daneOsoboweToogleButtonField: {
        '& .MuiToggleButton-root': {
            border: '2px solid',
            borderColor: useTheme.palette.secondary.main,
            color: useTheme.palette.secondary.main
            // backgroundColor: useTheme.palette.secondary.hovered
        },
        '& .MuiToggleButton-root.Mui-selected': {
            backgroundColor: useTheme.palette.secondary.main,
            color: useTheme.palette.primary.main
        }
    },
    daneOsoboweToogleButtonFieldError: {
        '& .MuiToggleButton-root': {
            border: '2px solid',
            borderColor: useTheme.palette.secondary.error,
            color: useTheme.palette.secondary.main
        },
        '& .MuiToggleButton-root.Mui-selected': {
            backgroundColor: useTheme.palette.secondary.main,
            color: useTheme.palette.primary.main
        }
    },
    daneOsoboweGridField: {
        position: 'relative',
        display: 'inline-block',
        paddingBottom: '0.5vh',
        paddingTop: '0.5vh',
        paddingLeft: '1.5vw',
        paddingRight: '1.5vw',
        textAlign: 'left',
        width: '100%'
    },
    autoComplete: {
        fontSize: 18,
        textAlign: 'left',
    },
    customWhiteDisabledButton: {
        "&:disabled": {
            backgroundColor: useTheme.palette.allow.main
        }
    },
    registrationCheckbox: {
        position: 'relative',
        display: 'flex',
        textAlign: 'left',
    },
    captchaImage: {
        width: '150px',
    },
    datePicker: {
        "& .MuiPaper-root": {
            backgroundColor: "rgba(120, 120, 120, 0.2)"
        },
        "& .MuiCalendarPicker-root": {
            backgroundColor: "rgba(45, 85, 255, 0.4)"
        },
        "& .MuiPickersDay-dayWithMargin": {
            color: "rgb(229,228,226)",
            backgroundColor: "rgba(50, 136, 153)"
        },
        "& .MuiTabs-root": {backgroundColor: "rgba(120, 120, 120, 0.4)"}
    },
    errorGridField: {
        fontSize: 10,
        color: useTheme.palette.secondary.error
    },
    customCardField: {
        '& .MuiCardContent-root': {
            border: 'solid 2px',
            borderColor: useTheme.palette.secondary.main,
        },
        backgroundColor: useTheme.palette.additional.main,
    },
    customCardFieldError: {
        '& .MuiCardContent-root': {
            border: 'solid 2px',
            borderColor: useTheme.palette.secondary.error,
        },
        backgroundColor: useTheme.palette.additional.main,
    },
    courseDisplayPaper: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        textAlign: "flex-start",
        padding: '0.8vh',
        minHeight: '3vh',
        backgroundColor: useTheme.palette.primary.main,
    },
    courseBlueDisplayPaper: {
        display: "grid",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        padding: '0.8vh',
        minHeight: '3vh',
        backgroundColor: useTheme.palette.secondary.main,
    }, customMatertialTable: {
        '& .Mui-checked': {
            color: '#30376c !important', /* Change this to your desired color */
            hovered: '#3e488e',
            clicked: '#4d5ab8',
            error: '#FF0000'
        }
    }, todoChip: {
        '& .MuiChip-label': {fontSize: '1rem'}, // sub-selector
    }

}), {index: 1});
